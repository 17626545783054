import { Rockets, IGameData } from '../@types/game'
import {
  APOLLO_MODEL,
  APOLLO_TEXTURE,
  SKYLAB_MODEL,
  SKYLAB_TEXTURE,
  ROCKET_APOLLO,
  ROCKET_SKYLAB,
} from '../constants/resources'

export const rocketsData: { [key in Rockets]: IGameData } = {
  [Rockets.ROCKET_APOLLO]: {
    id: Rockets.ROCKET_APOLLO,
    name: 'Apollo',
    price: 30,
    image: ROCKET_APOLLO,
    description: '',
    textureUrl: APOLLO_TEXTURE,
    modelUrl: APOLLO_MODEL,
  },
  [Rockets.ROCKET_SKYLAB]: {
    id: Rockets.ROCKET_SKYLAB,
    name: 'Skylab',
    price: 35,
    image: ROCKET_SKYLAB,
    description: '',
    textureUrl: SKYLAB_TEXTURE,
    modelUrl: SKYLAB_MODEL,
  },
}
