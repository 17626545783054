import React, { useState, useRef } from 'react'
import cx from 'classnames'
import { ChevronDown16, ChevronUp16 } from '@carbon/icons-react'
import useOnClickOutside from '../../../core/hooks/useOnClickOutside'
import styles from './dropdown.module.scss'

export interface IOption {
  value: string
  title: string
}

interface IProps {
  className?: string
  value?: string
  placeholder?: string
  items: IOption[]
  onChange(url: string): void
}

/**
 * @function SelectImage
 * @param items
 * @param className
 * @param value
 * @param placeholder
 * @param onSelect
 */
export function Dropdown({
  value,
  items,
  placeholder,
  onChange,
  className,
}: IProps): JSX.Element {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const currentItem: IOption | undefined | null | '' =
    value && items.find((item: IOption) => item.value === value)

  const toggleDropdown = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    setShowDropdown(!showDropdown)
  }

  const handleClickOutside = () => {
    setShowDropdown(false)
  }

  useOnClickOutside(dropdownRef, handleClickOutside)

  return (
    <div className={cx(styles.dropdownWrapper, className)} ref={dropdownRef}>
      <div
        className={`${styles.currentItem} d-flex align-items-center justify-content-between`}
        onClick={toggleDropdown}
      >
        {currentItem ? (
          <span>{currentItem.title}</span>
        ) : (
          <span className={styles.placeholder}>{placeholder}</span>
        )}
        {showDropdown ? <ChevronUp16 /> : <ChevronDown16 />}
      </div>
      {showDropdown && (
        <div className={styles.dropdown}>
          {items?.map((item: IOption, index: number) => (
            <div
              key={index}
              className={cx(styles.item, {
                [styles.selected]:
                  currentItem && item.value === currentItem?.value,
              })}
              onClick={(ev) => {
                onChange(item.value)
                toggleDropdown(ev)
              }}
            >
              <span className={styles.text}>{item?.title}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
