import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { IOptionPlanet } from '../../../../../core/@types/game'

const objectLoader = new GLTFLoader()

export class Planet {
  options: IOptionPlanet | null
  mesh: THREE.Mesh | null
  scene: THREE.Scene | null
  isEnabled: boolean

  constructor(scene: any, options: IOptionPlanet | null, isEnabled: boolean) {
    this.options = options
    this.scene = scene
    this.isEnabled = isEnabled
    this.mesh = null
  }

  unload() {
    if (this.mesh && this.scene) {
      this.scene.remove(this.mesh)
      this.mesh.geometry.dispose()
      ;(this.mesh.material as THREE.Material).dispose()
      this.mesh = null
      this.scene = null
    }
  }

  load(): Promise<Planet> {
    return new Promise((resolve, reject) => {
      if (this.options) {
        objectLoader.load(this.options.modelUrl, (gltf) => {
          const image = new Image() // or document.createElement('img' );
          image.crossOrigin = 'Anonymous'
          // Create texture
          const texture = new THREE.Texture(image)
          // On image load, update texture
          image.onload = () => {
            texture.needsUpdate = true
          }
          // Set image source
          image.src =
            (this.isEnabled
              ? this.options?.textureUrl
              : this.options?.disabledTextureUrl) || ''
          texture.flipY = false
          const material = new THREE.MeshPhongMaterial({
            map: texture,
            flatShading: false,
          })
          const planetScene = gltf.scene
          planetScene.traverse((node: any) => {
            if (node.isMesh) {
              // for a multi-material mesh, `o.material` may be an array,
              // in which case you'd need to set `.map` on each value.
              if (node.material.isGLTFSpecularGlossinessMaterial) {
                node.onBeforeRender = function () {}
              }
              node.material = material
              this.mesh = node
            }
          })
          if (this.mesh) {
            const scale = this.options?.scale || 1
            this.mesh.userData = {
              id: this.options?.id,
            }
            this.mesh.scale.set(scale, scale, scale)
            const x = this.options?.x || 0
            const y = this.options?.y || 0
            this.mesh.position.set(x, y, 0)
            this.mesh.renderOrder = 1
          }
          if (this.scene && this.mesh) {
            this.scene.add(this.mesh)
          }
          resolve(this)
        })
      } else {
        reject(new Error('Planet is missing options configurations'))
      }
    })
  }

  update() {
    if (this.mesh && this.options) {
      this.mesh.rotation.x -= this.options.speed * 2
      this.mesh.rotation.y -= this.options.speed * 3
    }
  }
}
