import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  requiredName: string
  name: string
  namePlaceholder: string
  errorSetupUpdate: string
  nameTitle: string
  costumeTitle: string
  rocketTitle: string
  startButton: string
  loadingButton: string
  requiredCostume: string
  requiredRocket: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    requiredName: 'Your character must have a name',
    name: 'Name',
    namePlaceholder: 'Character Name',
    errorSetupUpdate:
      'There was an error while saving the game setup. Please try again',
    nameTitle: 'Hero Name',
    costumeTitle: 'Hero Costume',
    rocketTitle: 'Rocket Model',
    startButton: 'Start Game',
    loadingButton: 'Loading game',
    requiredCostume: 'Character costume is required',
    requiredRocket: 'Rocket is required for missions',
  },
  [languageCodes.ro_RO]: {
    requiredName: 'Caracterul trebuie să aibă un nume',
    name: 'Nume',
    namePlaceholder: 'Nume Caracter',
    errorSetupUpdate:
      'A apărut o eroare în timpul salvării configurării jocului. Încearcă din nou',
    nameTitle: 'Nume Erou',
    costumeTitle: 'Costum Erou',
    rocketTitle: 'Model Rachetă',
    startButton: 'Începe Joc',
    loadingButton: 'Încărcare joc',
    requiredCostume: 'Costumul caracterului este obligatoriu',
    requiredRocket: 'Racheta este obligatorie pentru misiuni',
  },
}

export default localeData
