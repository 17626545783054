import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { GeneralContext } from '../../../core/context/generalContext'
import { languageCodes, MenuItem } from '../../../core/@types/general'
import { HOME } from '../../../core/constants/routes'
import { LOGO_IMAGE } from '../../../core/constants/resources'
import { Image } from '../image'
import locales, { ILocaleProps } from './lib/locales'
import styles from './logout-header.module.scss'

interface IProps {
  items: MenuItem[]
}

/**
 * @function LogoutHeader
 * @param items
 */
export function LogoutHeader({ items }: IProps): JSX.Element {
  const { languageCode, setLanguageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]

  function onChangeLanguage(ev: any) {
    setLanguageCode(ev.currentTarget.value)
  }

  return (
    <div
      className={`${styles.menu} d-flex align-items-center justify-content-between p-3`}
    >
      <Link to={HOME}>
        <Image className={styles.logo} src={LOGO_IMAGE} alt="logo" loadingSize={30} />
      </Link>
      <div>
        {items?.map((item) => (
          <Link key={item.name} to={item.url} className={styles.item}>
            {item.name}
          </Link>
        ))}
        <span className={styles.language}>
          {locale?.language}:
          <select value={languageCode} onChange={onChangeLanguage}>
            <option value={languageCodes.ro_RO}>{locale?.romanian}</option>
            <option value={languageCodes.en_US}>{locale?.english}</option>
          </select>
        </span>
      </div>
    </div>
  )
}
