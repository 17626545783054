import React, { useState } from 'react'
import { ArrowDown16, ArrowUp16 } from '@carbon/icons-react'
import cx from 'classnames'
import styles from './accordion.module.scss'

interface AccordionProps {
  header: JSX.Element
  body: JSX.Element
  disabled?: boolean
}

export const Accordion: React.FC<AccordionProps> = ({ header, body, disabled }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(
          `${styles.accordion} d-flex flex-row justify-content-between align-items-center`,
          {
            [styles.open]: isOpen && !disabled,
            [styles.disabled]: disabled,
          }
        )}
        onClick={toggle}
      >
        <div>{header}</div>
        {isOpen && !disabled ? <ArrowUp16 /> : <ArrowDown16 />}
      </div>
      {isOpen && !disabled && <div className={styles.container}>{body}</div>}
    </div>
  )
}
