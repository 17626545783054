import React from 'react'
import {
  Exercises as ExercisesEnum,
  ExerciseType
} from '../../../../core/@types/game'
import { EnableDropdown } from '../enableDropdown'
import styles from './exercises.module.scss'

interface ExercisesProps {
  name: string
}

export const Exercises: React.FC<ExercisesProps> = ({ name }) => {
  const renderExercise = (exerciseName: ExerciseType) => {
    return (
      <div className={`${styles.exercise} d-flex align-items-center`}>
        <h4 style={{ marginRight: 16 }}>{exerciseName}</h4>
        <EnableDropdown name={`${name}.${exerciseName}.show`} />
      </div>
    )
  }

  return (
    <>
      {renderExercise(ExercisesEnum.EXERCISE1)}
      {renderExercise(ExercisesEnum.EXERCISE2)}
      {renderExercise(ExercisesEnum.EXERCISE3)}
      {renderExercise(ExercisesEnum.EXERCISE4)}
      {renderExercise(ExercisesEnum.EXERCISE5)}
      {renderExercise(ExercisesEnum.EXERCISE6)}
    </>
  )
}
