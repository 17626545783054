import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  text: string
  images: string
  placeholderValueType: string
  requiredTitle: string
  titlePlaceholder: string
  updateButton: string
  exerciseTitle: string
  labelSolution: string
  labelOption: string
  labelName: string
  labelTitle: string
  labelExercise: string
  errorUpdateExercise: string
  errorFetchMedia: string
  errorFetchExercise: string
  helperOne: string
  helperTwo: string
  addImageButton: string
  audioPlaceholder: string
  loadingExercise: string
  viewExercise: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    text: 'Text',
    images: 'Images',
    placeholderValueType: 'Select the value type',
    requiredTitle: 'Title is required',
    titlePlaceholder: 'Enter the exercise title',
    updateButton: 'Update Exercise',
    exerciseTitle: 'Edit Exercise',
    labelSolution: 'Solution',
    labelOption: 'Option',
    labelName: 'Name',
    labelTitle: 'Title',
    labelExercise: 'Exercise',
    errorUpdateExercise: 'There was an error while updating the exercise',
    errorFetchMedia: 'There was an error while fetching the media files',
    errorFetchExercise: 'There was an error while fetching the exercise',
    helperOne: 'Helper 1',
    helperTwo: 'Helper 2',
    addImageButton: 'Add Image',
    audioPlaceholder: 'Select an audio',
    loadingExercise: 'Loading Exercise',
    viewExercise: 'View Exercise',
  },
  [languageCodes.ro_RO]: {
    text: 'Text',
    images: 'Imagini',
    placeholderValueType: 'Selectează tipul valorilor',
    requiredTitle: 'Titlul este necesar',
    titlePlaceholder: 'Adaugă titlu pentru exercițiu',
    updateButton: 'Modificați Exercițiul',
    exerciseTitle: 'Editare Exercițiu',
    labelSolution: 'Soluție',
    labelOption: 'Opțiune',
    labelName: 'Nume',
    labelTitle: 'Titlu',
    labelExercise: 'Exercițiu',
    errorUpdateExercise: 'A apărut o eroare în timpul modificării exercițiului',
    errorFetchMedia: 'A apărut o eroare în timpul primirii fișierelor media',
    errorFetchExercise: 'A apărut o eroare în timpul primirii exercițiilor',
    helperOne: 'Ajutor 1',
    helperTwo: 'Ajutor 2',
    addImageButton: 'Adaugă Imagine',
    audioPlaceholder: 'Selectează audio',
    loadingExercise: 'Încărcare Exercițiu',
    viewExercise: 'Vizualizare Exercițiu',
  },
}

export default localeData
