import cx from 'classnames'
import styles from './loading-rocket.module.scss'

interface IProps {
  className?: string
  message?: string
  size?: number
}

/**
 * @function Loading
 */
export function LoadingRocket({
  className,
  message,
  size,
}: IProps): JSX.Element {
  const iconSize = size || 60
  return (
    <div
      className={`${cx(
        styles.loading,
        className
      )} d-flex justify-content-center align-items-center`}
    >
      <div className={styles.rocket}>
        <div className={styles['rocket-body']}>
          <div className={styles.body}></div>
          <div className={`${styles.fin} ${styles['fin-left']}`}></div>
          <div className={`${styles.fin} ${styles['fin-right']}`}></div>
          <div className={styles.window}></div>
        </div>
        <div className={styles['exhaust-flame']}></div>
        <ul className={styles['exhaust-fumes']}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul className={styles.star}>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className={styles.message}>{message && <span>{message}</span>}</div>
    </div>
  )
}
