import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Alarm32 } from '@carbon/icons-react'
import { useAnimationFrame } from '../../../core/hooks/useAnimationFrame'
import styles from './timer.module.scss'

interface IProps {
  className?: string
  isVisible: boolean
  updateTime(arg0: number): void
}

export function Timer({
  isVisible,
  updateTime,
  className,
}: IProps): JSX.Element | null {
  const [duration, setDuration] = useState<number>(0)
  useAnimationFrame(
    () => {
      setDuration((prevDuration) => {
        return prevDuration + 1
      })
    },
    1, // trigger every 1 second
    true
  )

  useEffect(() => {
    updateTime(duration)
  }, [duration, updateTime])

  function pad(number: number, length: number): string {
    if (`${number}`.length < length) {
      return `0${number}`
    }
    return `${number}`
  }

  function getElapsedTime(totalSeconds: number): string {
    let hours = 0
    let minutes = 0
    let seconds = totalSeconds

    // check if the elapsed time in seconds is more than a minute
    // and convert the raw seconds to minutes & seconds
    if (seconds > 60) {
      minutes = Math.floor(totalSeconds / 60)
      seconds = seconds - minutes * 60
    }

    // check if the elapsed time in minutes is more than an hour
    // and convert the raw seconds to hours, minutes & seconds
    if (minutes > 60) {
      hours = Math.floor(seconds / 3600)
      minutes = seconds - hours * 60
      seconds = seconds - minutes * 3600
    }

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`
  }

  const elapsedTime = getElapsedTime(duration)
  return isVisible ? (
    <div className={cx(styles.timer, className, 'd-flex')}>
      <Alarm32 />
      <div className={`${styles.passedTime} ml-2`}>{elapsedTime}</div>
    </div>
  ) : null
}
