import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  loading: string
  error: string
  minute: string
  second: string
  minutes: string
  seconds: string
  correctYes: string
  correctNo: string
  notAvailable: string
  exportButton: string
  exportTitle: string
  clearButton: string
  user: string
  exercise: string
  passed: string
  value: string
  time: string
  attempts: string
  created: string
  userId: string
  exerciseId: string
  deleting: string
  noHistories: string
  selectUserToClean: string
  selectUser: string
  deletingUserHistory: string
  records: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    loading: 'Please wait... Loading data',
    error: 'There was an error while fetching the data',
    minute: 'Minute',
    second: 'Second',
    minutes: 'Minutes',
    seconds: 'Seconds',
    correctYes: 'Yes',
    correctNo: 'No',
    notAvailable: 'Not Available',
    exportButton: 'Export reports to CSV',
    exportTitle: 'Game reports',
    clearButton: 'Clear reports',
    user: 'User',
    exercise: 'Exercise',
    passed: 'Passed',
    value: 'Value',
    time: 'Time',
    attempts: 'Attempts',
    created: 'Created',
    userId: 'User Id',
    exerciseId: 'Exercise Id',
    deleting: 'Deleting history records',
    noHistories: 'There are no histories available',
    selectUserToClean: 'Clean history for user',
    selectUser: 'Select User',
    deletingUserHistory: 'Deleting history records for the user',
    records: 'records'
  },
  [languageCodes.ro_RO]: {
    loading: 'Vă rugăm așteptați... Încărcare date',
    error: 'A avut loc o eroare pe durata primirii datelor',
    minute: 'Minut',
    second: 'Secundă',
    minutes: 'Minute',
    seconds: 'Secunde',
    correctYes: 'Da',
    correctNo: 'Nu',
    notAvailable: 'Indisponibil',
    exportButton: 'Export rapoarte CSV',
    exportTitle: 'Rapoarte Joc',
    clearButton: 'Șterge rapoartele',
    user: 'Utilizator',
    exercise: 'Exercițiu',
    passed: 'Corect',
    value: 'Valoare',
    time: 'Timp',
    attempts: 'Încercări',
    created: 'Creat',
    userId: 'Id Utilizator',
    exerciseId: 'Id Exercițiu',
    deleting: 'Ștergere înregistrări istoric',
    noHistories: 'Nu există istoric disponibil',
    selectUserToClean: 'Ștergere istoricul utilizatorului',
    selectUser: 'Selectează utilizator',
    deletingUserHistory: 'Ștergere înregistrări istoric pentru utilizator',
    records: 'înregistrări'
  },
}

export default localeData
