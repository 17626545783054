import { useContext } from 'react'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { Title } from '../../../../../../components/atoms/title'
import { Image } from '../../../../../../components/atoms/image'
import { heroData } from '../../../../../../core/game-data/heroes'
import { rocketsData } from '../../../../../../core/game-data/rockets'
import locales from './lib/locales'
import styles from './welcome-message.module.scss'

export function WelcomeMessage() {
  const { gameInfo, languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const game = gameInfo?.user?.extrauser?.game
  const currentHero = game?.hero
  const currentRocket = game?.rocket
  return (
    <div className="container-fluid">
      <div className="row p-3">
        <div
          className={`col-12 p-5 d-flex flex-column align-items-center justify-content-center ${styles.wrapper}`}
        >
          <Title className="mb-3" headingLevel={1} title={locale.welcome} />
          <div className="d-flex">
            {currentHero && (
              <div className="my-3 mr-3">
                <Image
                  className={styles.image}
                  src={heroData[currentHero].image}
                  alt={heroData[currentHero].name}
                  loadingSize={100}
                />
              </div>
            )}
            {currentRocket && (
              <div className="my-3">
                <Image
                  className={styles.image}
                  src={rocketsData[currentRocket].image}
                  alt={rocketsData[currentRocket].name}
                  loadingSize={100}
                />
              </div>
            )}
          </div>
          <Title className="mb-3" headingLevel={3} title={locale.underAttack} />
          <Title className="mb-3" headingLevel={2} title={locale.navigate} />
        </div>
      </div>
    </div>
  )
}
