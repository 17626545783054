import {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import {
  Exercises as ExercisesEnum,
  ExerciseType,
  IExercise,
  IPackResponse,
  PackType,
  ZoneType,
} from '../../../../../../core/@types/game'
import { HelperTypes, Helper } from '../../../../../../core/@types/exercise'
import { getPack } from '../../../../../../core/api/graphql/queries/game'
import { useQuery } from '@apollo/react-hooks'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { MissionContext } from '../../../../../../core/context/missionContext'
import { Timer } from '../../../../../../components/atoms/timer'
import { WarningModal } from '../../../../../../components/atoms/warning-modal'
import { Exercise } from '../exercise'
import { Button } from '../../../../../../components/atoms/button'
import { Title } from '../../../../../../components/atoms/title'
import { ExerciseProgress } from '../../../../../../components/atoms/exercise-progress'
import { Close16, ArrowRight16, Redo16, Exit16 } from '@carbon/icons-react'
import { MissionSkeleton } from '../mission-skeleton'
import locales from './lib/locales'
import styles from './exercises.module.scss'
import { FETCH_POLICY } from '../../../../../../core/constants/general'

interface IProps {
  packId: string
  onNextZone(): void
  currentZone: ZoneType | null
  currentPack: PackType | null
}

export function Exercises({
  packId,
  onNextZone,
  currentPack,
  currentZone,
}: IProps) {
  const { gameInfo, languageCode, currentMission } = useContext(GeneralContext)
  const locale = locales[languageCode]
  // const [modalVisible, setModalVisible] = useState<boolean>(false)
  const extrauser = gameInfo?.user?.extrauser
  const configuration = extrauser?.group?.configuration
  const { data, error, loading } = useQuery(getPack, {
    variables: { id: packId },
    fetchPolicy: FETCH_POLICY,
  })
  const exercises = useMemo<
    | {
        [key in ExercisesEnum]: string
      }
    | null
  >(() => {
    const response: IPackResponse = data
    return response && response.pack && response.pack.exercises
      ? response.pack.exercises.reduce((map: any, exercise: IExercise) => {
          if (
            currentMission &&
            currentZone &&
            currentPack &&
            configuration?.enabled_data.visibility[currentMission].zones[
              currentZone
            ].packs[currentPack].exercises[exercise.type].show
          ) {
            return {
              ...map,
              [exercise.type]: exercise.id,
            }
          }
          return map
        }, {})
      : null
  }, [data, configuration, currentMission, currentPack, currentZone])
  const exerciseList = useMemo<ExerciseType[]>(() => {
    if (exercises) {
      return Object.keys(exercises).sort() as ExerciseType[]
    }
    return []
  }, [exercises])
  const { onMissionFail, updateHistory } = useContext(MissionContext)
  const [progress, setProgress] = useState<
    | {
        [key in ExercisesEnum]: boolean | null
      }
    | null
  >(null)
  const hasFeedback = configuration?.feedback || false
  const hasHelper = configuration?.sem || false
  const hasTime = configuration?.time || false
  const timeSpent = useRef<number>(0)
  const [exerciseState, setExerciseState] = useState<{
    attempts: number
    showHelper: boolean
    helper: HelperTypes
    showContinueToZone: boolean
    showFailedMission: boolean
    currentExercise: ExerciseType | null
  }>({
    attempts: 0,
    showHelper: false,
    helper: Helper.HELPER_ONE,
    showContinueToZone: false,
    showFailedMission: false,
    currentExercise: null,
  })
  const exerciseId =
    exercises && exerciseState.currentExercise
      ? exercises[exerciseState.currentExercise]
      : null

  useEffect(() => {
    const newProgress = exerciseList.reduce(
      (map, exercise: ExerciseType) => ({
        ...map,
        [exercise]: null,
      }),
      {}
    )
    setProgress(newProgress as any)
    setExerciseState({
      attempts: 0,
      showHelper: false,
      helper: Helper.HELPER_ONE,
      showContinueToZone: false,
      showFailedMission: false,
      currentExercise: exerciseList[0] || null,
    })
  }, [packId, exerciseList])

  const onNextExercise = useCallback(() => {
    if (!exerciseState.currentExercise) {
      return false
    }
    const exerciseIndex = exerciseList.indexOf(exerciseState.currentExercise)

    if (exerciseIndex < exerciseList.length - 1) {
      setExerciseState({
        attempts: 0,
        showHelper: false,
        helper: Helper.HELPER_ONE,
        showContinueToZone: false,
        showFailedMission: false,
        currentExercise: exerciseList[exerciseIndex + 1],
      })
    } else {
      onNextZone()
    }
  }, [exerciseState.currentExercise, onNextZone, exerciseList])

  const updateExerciseHistory = (
    isCorrect: boolean,
    pickedValue: string,
    attempts: number
  ) => {
    if (exerciseId) {
      updateHistory({
        attempts,
        isCorrect,
        pickedValue,
        timeSpent: timeSpent.current,
        exercise: exerciseId,
        extrauser: extrauser?.id || '',
      })
    }
  }

  // const onCloseModal = () => {
  //   setModalVisible(false)
  // }

  // const onSubmitModal = () => {
  //   onMissionFail()
  // }

  // const onOpenModal = () => {
  //   setModalVisible(true)
  // }

  const onSelect = (checked: boolean, value: string) => {
    if (!exerciseState.currentExercise) {
      return false
    }
    const newProgress = {
      ...progress,
      [exerciseState.currentExercise]: checked,
    }
    let attempts = exerciseState.attempts
    if (checked) {
      const passedExercises = Object.values(newProgress).filter(
        (value) => value === true
      )
      const continueToNextZone = passedExercises.length === 4
      updateExerciseHistory(checked, value, attempts)
      if (continueToNextZone) {
        setExerciseState({
          ...exerciseState,
          showContinueToZone: true,
        })
      } else {
        onNextExercise()
      }
    } else {
      attempts = attempts + 1
      // const failedExercises = Object.values(newProgress).filter(
      //   (value) => value === false
      // )
      // const showFailedMission = failedExercises.length > 2

      if (hasHelper && attempts === 1) {
        setExerciseState({
          ...exerciseState,
          showHelper: true,
          helper: Helper.HELPER_ONE,
          attempts,
        })
      } else if (hasHelper && attempts === 2) {
        setExerciseState({
          ...exerciseState,
          showHelper: true,
          helper: Helper.HELPER_TWO,
          attempts,
        })
      }
      // else if (showFailedMission) {
      //   updateExerciseHistory(checked, value, attempts)
      //   setExerciseState({
      //     ...exerciseState,
      //     showFailedMission: true,
      //   })
      // }
      else {
        updateExerciseHistory(checked, value, attempts)
        onNextExercise()
      }
    }
    setProgress(newProgress as any)
  }

  const updateTimeSpent = (time: number) => {
    timeSpent.current = time
  }

  const tryAgain = () => {
    setExerciseState({
      ...exerciseState,
      showHelper: false,
    })
  }

  if (error) {
    return <MissionSkeleton hideZones errorMessage={locale.errorExercises} />
  }

  if (loading) {
    return (
      <MissionSkeleton hideZones loadingMessage={locale.loadingExercises} />
    )
  }

  return (
    <>
      {/* <WarningModal
        className={styles.modal}
        isVisible={modalVisible}
        title={locale.warning}
        submitText={locale.cancelMission}
        onSubmit={onSubmitModal}
        onCancel={onCloseModal}
      >
        <Title headingLevel={2} title={locale.lostProgress} />
      </WarningModal> */}
      <div className="d-flex p-3">
        <div className="col-12 px-3 d-flex justify-content-between align-items-end">
          {hasTime && <Timer updateTime={updateTimeSpent} isVisible />}
          {/* {!exerciseState.showFailedMission && (
            <Button
              type="button"
              className={`${styles.exerciseButton} ${styles.cancelButton}`}
              onClick={onOpenModal}
              disabled={modalVisible}
              title={
                <>
                  <Close16 />
                  <span>{locale.cancelMissionTwo}</span>
                </>
              }
            />
          )} */}
        </div>
      </div>
      <ExerciseProgress
        hasFeedback={hasFeedback}
        exercises={exerciseList}
        progress={progress}
        currentExercise={exerciseState.currentExercise}
      />
      {exerciseState.showContinueToZone ? (
        <div className="px-3">
          <div className="col-12 px-3">
            <div
              className={`${styles.exerciseBoard} py-5 d-flex flex-column align-items-center`}
            >
              <Title headingLevel={2} title={locale.continueZone} />
              <div className="d-flex py-3 justify-content-center">
                <Button
                  className={styles.exerciseButton}
                  type="button"
                  title={
                    <>
                      <Redo16 />
                      <span>{locale.skipZone}</span>
                    </>
                  }
                  onClick={onNextZone}
                />
                <Button
                  className={`${styles.exerciseButton} ml-3`}
                  type="button"
                  title={
                    <>
                      <ArrowRight16 />
                      <span>{locale.continue}</span>
                    </>
                  }
                  onClick={onNextExercise}
                />
              </div>
            </div>
          </div>
        </div>
      ) : // : exerciseState.showFailedMission ? (
      //   <div className="px-3">
      //     <div className="col-12 px-3">
      //       <div
      //         className={`${styles.exerciseBoard} py-5 d-flex flex-column align-items-center`}
      //       >
      //         <Title headingLevel={2} title={locale.missionFailed} />
      //         <Button
      //           type="button"
      //           className={`${styles.exerciseButton} ${styles.cancelButton} mt-3`}
      //           title={
      //             <>
      //               <Exit16 />
      //               <span>{locale.exitMission}</span>
      //             </>
      //           }
      //           onClick={onMissionFail}
      //         />
      //       </div>
      //     </div>
      //   </div>
      // )
      exerciseId ? (
        <Exercise
          exerciseId={exerciseId}
          showHelper={hasHelper && exerciseState.showHelper}
          onSelect={onSelect}
          helper={exerciseState.helper}
          tryAgain={tryAgain}
        />
      ) : (
        <div className="px-3">
          <div className="col-12 px-3">
            <div
              className={`${styles.exerciseBoard} py-5 d-flex flex-column align-items-center`}
            >
              <Title headingLevel={2} title={locale.missingExercise} />
              <Button
                type="button"
                className={`${styles.exerciseButton} ${styles.cancelButton} mt-3`}
                title={
                  <>
                    <Exit16 />
                    <span>{locale.exitMissionTwo}</span>
                  </>
                }
                onClick={onMissionFail}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
