import { SyntheticEvent, forwardRef } from 'react'
import cx from 'classnames'
import styles from './checkbox.module.scss'

interface IProps {
  name: string
  checked: boolean
  onChange(ev: SyntheticEvent<HTMLInputElement>): void
  valid: boolean
  invalid: boolean
  disabled: boolean
  hasValidation: boolean
  children: JSX.Element[] | JSX.Element | string
  isSelected: boolean
}

/**
 * @function Checkbox
 * @param name
 * @param checked
 * @param onChange
 * @param valid
 * @param invalid
 * @param disabled
 * @param hasValidation
 * @param children
 * @param isSelected
 * @param isText
 */
export const CheckBox = forwardRef<HTMLInputElement, IProps>(function (
  {
    disabled,
    hasValidation,
    children,
    isSelected,
    valid,
    invalid,
    checked,
    name,
    onChange,
  },
  ref
): JSX.Element {
  const isText = typeof children === 'string' && !/^[0-9]*$/.test(children)
  return (
    <label
      className={cx(styles.checkbox, {
        [styles.valid]: valid && hasValidation,
        [styles.invalid]: invalid && hasValidation,
        [styles.disabled]: disabled,
        [styles.checked]: checked,
        [styles.selected]: isSelected,
      })}
    >
      <div
        className={cx(styles.checkboxContainer, {
          [styles.text]: isText,
        })}
      >
        {children}
      </div>
      <input
        name={name}
        onChange={onChange}
        type="checkbox"
        disabled={disabled}
        className={styles.input}
        checked={checked}
        ref={ref}
      />
    </label>
  )
})
