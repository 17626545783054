import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  profile: string
  missions: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    profile: 'Profile',
    missions: 'Missions',
  },
  [languageCodes.ro_RO]: {
    profile: 'Profil',
    missions: 'Misiuni',
  },
}

export default localeData
