import { useState, useContext, useMemo, useEffect } from 'react'
import {
  Zones as ZonesEnum,
  ZoneType,
  IZone,
  IMissionResponse,
} from '../../../../../../core/@types/game'
import { getMission } from '../../../../../../core/api/graphql/queries/game'
import { useQuery } from '@apollo/react-hooks'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { MissionContext } from '../../../../../../core/context/missionContext'
import { MissionComplete } from '../mission-complete'
import { MissionSkeleton } from '../mission-skeleton'
import { Hourglass32, EarthSoutheastAsia32, Wikis32 } from '@carbon/icons-react'
import { Packs } from '../packs'
import locales from './lib/locales'
import cx from 'classnames'
import styles from './zones.module.scss'
import { FETCH_POLICY } from '../../../../../../core/constants/general'

interface IProps {
  missionId: string
  cleanupCanvas(): void
}

export function Zones({ missionId, cleanupCanvas }: IProps) {
  const { onMissionSuccess } = useContext(MissionContext)
  const { languageCode, currentMission, gameInfo } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const configuration = gameInfo?.user?.extrauser?.group?.configuration
  const { data, error, loading } = useQuery(getMission, {
    variables: { id: missionId },
    fetchPolicy: FETCH_POLICY,
  })
  const zones = useMemo<
    | {
        [key in ZonesEnum]: string
      }
    | null
  >(() => {
    const response: IMissionResponse = data
    return response && response.mission && response.mission.zones
      ? response.mission.zones.reduce((map: any, zone: IZone) => {
          if (
            currentMission &&
            configuration?.enabled_data.visibility[currentMission].zones[
              zone.type
            ].show
          ) {
            return {
              ...map,
              [zone.type]: zone.id,
            }
          }
          return map
        }, {})
      : null
  }, [data, configuration, currentMission])
  const zoneList = useMemo<ZonesEnum[]>(() => {
    if (zones) {
      return Object.keys(zones).sort() as ZonesEnum[]
    }
    return []
  }, [zones])
  const [zoneState, setZoneState] = useState<{
    passedZones: ZoneType[]
    currentZone: ZoneType | null
  }>({
    passedZones: [],
    currentZone: null,
  })
  const zoneId =
    zones && zoneState.currentZone ? zones[zoneState.currentZone] : null
  const [hasCompletedMission, setHasCompletedMission] = useState<boolean>(false)

  useEffect(() => {
    if (zoneList && zoneList.length > 0) {
      setZoneState({
        passedZones: [],
        currentZone: zoneList[0],
      })
    }
  }, [zoneList])

  function onNextZone() {
    if (zoneState.currentZone) {
      const zoneIndex = zoneList.indexOf(zoneState.currentZone)
      if (zoneIndex < zoneList.length - 1) {
        const nextZone = zoneList[zoneIndex + 1]
        setZoneState({
          passedZones: [...zoneState.passedZones, zoneState.currentZone],
          currentZone: nextZone,
        })
      } else {
        setZoneState({
          passedZones: [...zoneState.passedZones, zoneState.currentZone],
          currentZone: null,
        })
        setHasCompletedMission(() => {
          onMissionSuccess()
          return true
        })
      }
    }
  }

  function renderPacks() {
    return zoneId ? (
      <Packs
        zoneId={zoneId || ''}
        currentZone={zoneState.currentZone}
        onNextZone={onNextZone}
      />
    ) : (
      <MissionSkeleton hideZones errorMessage={locale.missingZone} />
    )
  }

  if (error) {
    return <MissionSkeleton errorMessage={locale.errorZone} />
  }

  if (loading) {
    return <MissionSkeleton loadingMessage={locale.loadingZone} />
  }

  return (
    <>
      {hasCompletedMission ? (
        <MissionComplete
          cleanupCanvas={cleanupCanvas}
          showGame={configuration?.enabled_data.showGame}
        />
      ) : (
        renderPacks()
      )}
      <div className="d-flex p-3">
        <div className="col-lg-12 d-flex justify-content-between align-items-center">
          {zoneList.map((zone: ZoneType, index: number) => {
            const hasPassed = zoneState.passedZones.indexOf(zone) !== -1
            const isCurrent = zoneState.currentZone === zone
            return (
              <div className={styles.zone} key={`${zone}-${index}`}>
                <div className={styles.zoneName}>{zone}</div>
                <div
                  className={`${cx(styles.zoneBlock, {
                    [styles.zonePassed]: hasPassed,
                    [styles.zoneCurrent]: isCurrent,
                  })} d-flex justify-content-center align-items-center`}
                >
                  {isCurrent && <Hourglass32 />}
                  {hasPassed && <EarthSoutheastAsia32 />}
                  {!hasPassed && !isCurrent && <Wikis32 />}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
