import React from 'react'
import { Accordion } from '../../../../components/atoms/accordion'
import { Packs as PacksEnum, PackType } from '../../../../core/@types/game'
import { EnableDropdown } from '../enableDropdown'
import { useFormContext } from 'react-hook-form'
import { Exercises } from '../exercises'

interface PacksProps {
  name: string
}

export const Packs: React.FC<PacksProps> = ({ name }) => {
  const { watch } = useFormContext()
  const zone = watch(name)

  const renderHeader = (packName: PackType) => {
    return (
      <div className="d-flex align-items-center">
        <h4 style={{ marginRight: 16 }}>{packName}</h4>
        <EnableDropdown name={`${name}.${packName}.show`} />
      </div>
    )
  }

  const renderBody = (packName: PackType) => {
    return <Exercises name={`${name}.${packName}.exercises`} />
  }

  const renderAccordion = (packName: PackType) => {
    const disabled = !zone[packName].show
    return (
      <Accordion
        disabled={disabled}
        header={renderHeader(packName)}
        body={renderBody(packName)}
      />
    )
  }

  return (
    <>
      {renderAccordion(PacksEnum.PACK1)}
      {renderAccordion(PacksEnum.PACK2)}
      {renderAccordion(PacksEnum.PACK3)}
      {renderAccordion(PacksEnum.PACK4)}
      {renderAccordion(PacksEnum.PACK5)}
      {renderAccordion(PacksEnum.PACK6)}
      {renderAccordion(PacksEnum.PACK7)}
    </>
  )
}
