import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  playerControls: string
  movement: string
  sprint: string
  pickShards: string
  jump: string
  fire: string
  exit: string
  objectives: string
  killEnemies: string
  collectShards: string
  tips: string
  toggleInstructions: string
  orText: string
  buttons: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    playerControls: "Player Controls",
    movement: "Movement",
    sprint: "Sprint",
    pickShards: "Pick Shards",
    jump: "Jump",
    fire: "Fire",
    exit: "Exit play-mode",
    objectives: "Mission Objectives",
    killEnemies: "Destroy all enemies",
    collectShards: "Collect shards",
    tips: "Tips",
    toggleInstructions: "Toggle the instructions by pressing",
    orText: "or",
    buttons: "buttons"
  },
  [languageCodes.ro_RO]: {
    playerControls: "Control Jucător",
    movement: "Mișcare",
    sprint: "Sprint",
    pickShards: "Culege Diamante",
    jump: "Salt",
    fire: "Foc",
    exit: "Ieșire mod-joc",
    objectives: "Obiectivele Misiunii",
    killEnemies: "Distruge toți inamicii",
    collectShards: "Colecționează diamante",
    tips: "Sfaturi",
    toggleInstructions: "Închide / Deschide instrucțiunile apăsând",
    orText: "sau",
    buttons: "butoane"
  },
}

export default localeData
