import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  createButton: string
  noDataMessage: string
  zoneFilter: string
  groupFilter: string
  exerciseFilter: string
  missionFilter: string
  loadButton: string
  edit: string
  view: string
  id: string
  title: string
  actions: string
  titleFilters: string
  titleExercises: string
  orderCreated: string
  orderUpdated: string
  exercise: string
  zone: string
  pack: string
  placeholderMission: string
  placeholderZone: string
  placeholderPack: string
  placeholderExercise: string
  placeholderOrder: string
  errorFetchingExercises: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    createButton: 'Add New Exercise',
    noDataMessage: 'There are no exercises',
    zoneFilter: 'Zone filter',
    groupFilter: 'Zone group',
    exerciseFilter: 'Exercise filter',
    missionFilter: 'Mission filter',
    loadButton: 'Load More',
    edit: 'Edit',
    view: 'View',
    id: 'Id',
    title: 'Title',
    actions: 'Actions',
    titleFilters: 'Filters',
    titleExercises: 'Exercises',
    orderCreated: 'Order By Created At',
    orderUpdated: 'Order By Last Updated',
    exercise: 'Exercise',
    zone: 'Zone',
    pack: 'Pack',
    placeholderMission: 'Filter by mission',
    placeholderZone: 'Filter by zone',
    placeholderPack: 'Filter by pack',
    placeholderExercise: 'Filter by exercise',
    placeholderOrder: 'Filter by date',
    errorFetchingExercises: 'There was an error while fetching exercises',
  },
  [languageCodes.ro_RO]: {
    createButton: 'Exercițiu Nou',
    noDataMessage: 'Nu există exerciții',
    zoneFilter: 'Filtru zonă',
    groupFilter: 'Filtru set',
    exerciseFilter: 'Filtru exercițiu',
    missionFilter: 'Filtru misiune',
    loadButton: 'Încarcă urmatoarele',
    edit: 'Editează',
    view: 'Vizualizează',
    id: 'Id',
    title: 'Titlu',
    actions: 'Acțiuni',
    titleFilters: 'Filtre',
    titleExercises: 'Exerciții',
    orderCreated: 'Ordonează după Created At',
    orderUpdated: 'Ordonează după Last Updated',
    exercise: 'Exercițiu',
    zone: 'Zona',
    pack: 'Pachet',
    placeholderMission: 'Filtrează după misiune',
    placeholderZone: 'Filtrează după zonă',
    placeholderPack: 'Filtrează după pachet',
    placeholderExercise: 'Filtrează după exercițiu',
    placeholderOrder: 'Filtrează după dată',
    errorFetchingExercises: 'A apărut o eroare în timpul primirii exercițiilor',
  },
}

export default localeData
