import {
  ADENDUS_MISSION_MODEL,
  DIMINUS_MISSION_MODEL,
  DIVISIA_MISSION_MODEL,
  FRACTA_MISSION_MODEL,
  MULTIPLIS_MISSION_MODEL,
  QUESTIA_MISSION_MODEL,
  HYBRIDIA_MISSION_MODEL,
  TERRA_MISSION_MODEL,
  HERO_MODEL,
  HERO_TEXTURE_OXY,
  HERO_TEXTURE_NANO,
  APOLLO_TEXTURE,
  SKYLAB_TEXTURE,
  MATEMATROL_MODEL,
  MATEMATROL_TEXTURE,
  ITEM_MODEL,
  ITEM_TEXTURE,
} from '../../core/constants/resources'
import {
  Missions,
  MissionType,
  HeroType,
  Heroes,
  RocketType,
  Rockets,
  IWorldOptions,
} from '../../core/@types/game'

const missionModels: { [key in MissionType]: string } = {
  [Missions.ADENDUS]: ADENDUS_MISSION_MODEL,
  [Missions.DIMINUS]: DIMINUS_MISSION_MODEL,
  [Missions.FRACTA]: FRACTA_MISSION_MODEL,
  [Missions.DIVISIA]: DIVISIA_MISSION_MODEL,
  [Missions.MULTIPLIS]: MULTIPLIS_MISSION_MODEL,
  [Missions.QUESTIA]: QUESTIA_MISSION_MODEL,
  [Missions.HYBRIDIA]: HYBRIDIA_MISSION_MODEL,
  [Missions.TERRA]: TERRA_MISSION_MODEL,
}

const heroModels: { [key in HeroType]: string } = {
  [Heroes.HERO_NANO]: HERO_MODEL,
  [Heroes.HERO_OXY]: HERO_MODEL,
}

const heroTextures: { [key in HeroType]: string } = {
  [Heroes.HERO_NANO]: HERO_TEXTURE_NANO,
  [Heroes.HERO_OXY]: HERO_TEXTURE_OXY,
}

const rocketTextures: { [key in RocketType]: string } = {
  [Rockets.ROCKET_APOLLO]: APOLLO_TEXTURE,
  [Rockets.ROCKET_SKYLAB]: SKYLAB_TEXTURE,
}

export function getMissionData(
  mission: MissionType,
  hero: HeroType,
  rocket: RocketType
): IWorldOptions {
  const missionValue = mission.toUpperCase()
  return {
    playerModel: heroModels[hero],
    playerTexture: heroTextures[hero],
    enemyModel: MATEMATROL_MODEL,
    enemyTexture: MATEMATROL_TEXTURE,
    itemModel: ITEM_MODEL,
    itemTexture: ITEM_TEXTURE,
    rocketTexture: rocketTextures[rocket],
    scenario: mission.toLowerCase(),
    world: missionModels[missionValue as MissionType],
  }
}
