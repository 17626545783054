import { useContext } from 'react'
import { Helper } from '../../molecule/helper'
import { Problem } from '../../molecule/problem'
import { Title } from '../../atoms/title'
import { IExercise } from '../../../core/@types/exercise'
import { ErrorBox } from '../../atoms/error-box'
import { GeneralContext } from '../../../core/context/generalContext'
import locales from './lib/locales'
import styles from './admin-exercise.module.scss'

interface IProps {
  exercise: IExercise | undefined
}

export function AdminExercise({ exercise }: IProps): JSX.Element {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]

  return exercise ? (
    <div className={`${styles.exercise} row py-5`}>
      <div className="col-12 mb-3">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <Title
            className="mb-3"
            headingLevel={3}
            title={locale?.helperProblemTitle}
          />
        </div>
        <Problem
          id={exercise.id}
          onSelect={() => {}}
          editor={exercise.properties.editor}
          optionOne={exercise.properties.optionOne}
          imageOptionOne={exercise.properties.imageOptionOne}
          optionTwo={exercise.properties.optionTwo}
          imageOptionTwo={exercise.properties.imageOptionTwo}
          optionThree={exercise.properties.optionThree}
          imageOptionThree={exercise.properties.imageOptionThree}
          optionFour={exercise.properties.optionFour}
          imageOptionFour={exercise.properties.imageOptionFour}
          solution={exercise.properties.solution}
          imageSolution={exercise.properties.imageSolution}
        />
      </div>
      <div className="col-12 col-md-6">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <Title
            className="mb-3"
            headingLevel={3}
            title={locale?.helperOneTitle}
          />
        </div>
        <Helper helper={exercise.properties.helperOne} />
      </div>
      <div className="col-12 col-md-6">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <Title
            className="mb-3"
            headingLevel={3}
            title={locale?.helperTwoTitle}
          />
        </div>
        <Helper helper={exercise.properties.helperTwo} />
      </div>
    </div>
  ) : (
    <ErrorBox>{locale?.errorRenderExercise}</ErrorBox>
  )
}
