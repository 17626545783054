import { useMemo, useContext } from 'react'
import { Problem } from '../../../../../../components/molecule/problem'
import { Helper } from '../../../../../../components/molecule/helper'
import { Title } from '../../../../../../components/atoms/title'
import { Button } from '../../../../../../components/atoms/button'
import { getExercise } from '../../../../../../core/api/graphql/queries/game'
import { IExerciseResponse } from '../../../../../../core/@types/game'
import { HelperTypes } from '../../../../../../core/@types/exercise'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { MissionSkeleton } from '../mission-skeleton'
import { useQuery } from '@apollo/react-hooks'
import { Insert16 } from '@carbon/icons-react'
import locales from './lib/locales'
import styles from './exercise.module.scss'
import { FETCH_POLICY } from '../../../../../../core/constants/general'

interface IProps {
  exerciseId: string
  showHelper: boolean
  helper: HelperTypes
  onSelect(checked: boolean, value: string): void
  tryAgain(): void
}

export function Exercise({
  showHelper,
  onSelect,
  tryAgain,
  helper,
  exerciseId,
}: IProps) {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const { data, error, loading } = useQuery(getExercise, {
    variables: { id: exerciseId },
    fetchPolicy: FETCH_POLICY,
  })
  const properties = useMemo(() => {
    const response: IExerciseResponse = data
    return response && response.exercise && response.exercise.properties
      ? response.exercise.properties
      : null
  }, [data])

  if (error) {
    return (
      <MissionSkeleton
        hideZones
        hideProgress
        hideTime
        errorMessage={locale.errorExercise}
      />
    )
  }

  if (loading) {
    return (
      <MissionSkeleton
        hideTime
        hideProgress
        hideZones
        loadingMessage={locale.loadingExercise}
      />
    )
  }

  return properties ? (
    <div className="d-flex px-3">
      {showHelper ? (
        <div className={`${styles.helperPanel} col-12 px-0`}>
          <Button
            className={styles.tryAgainButton}
            type="button"
            title={
              <>
                <Insert16 />
                <span>{locale.tryAgain}</span>
              </>
            }
            onClick={tryAgain}
          />
          <Helper helper={properties[helper]} />
        </div>
      ) : (
        <div className="col-12">
          <Problem
            className={styles.exerciseProblem}
            id={exerciseId}
            onSelect={onSelect}
            editor={properties.editor}
            optionOne={properties.optionOne}
            imageOptionOne={properties.imageOptionOne}
            optionTwo={properties.optionTwo}
            imageOptionTwo={properties.imageOptionTwo}
            optionThree={properties.optionThree}
            imageOptionThree={properties.imageOptionThree}
            optionFour={properties.optionFour}
            imageOptionFour={properties.imageOptionFour}
            solution={properties.solution}
            imageSolution={properties.imageSolution}
          />
        </div>
      )}
    </div>
  ) : (
    <div className="d-flex px-3">
      <div className="col-12 px-3">
        <div className={`${styles.noExerciseMessage} p-5`}>
          <Title headingLevel={2} title={locale.noExercise} />
        </div>
      </div>
    </div>
  )
}
