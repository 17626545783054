import React from 'react'
import { Accordion } from '../../../../components/atoms/accordion'
import {
  Missions as MissionsEnum,
  MissionType,
} from '../../../../core/@types/game'
import { EnableDropdown } from '../enableDropdown'
import { useFormContext } from 'react-hook-form'
import { Zones } from '../zones'
interface MissionsProps {
  name: string
}

export const Missions: React.FC<MissionsProps> = ({ name }) => {
  const { watch } = useFormContext()
  const configuration = watch(name)

  const renderHeader = (missionName: MissionType) => {
    return (
      <div className="d-flex align-items-center">
        <h4 style={{ marginRight: 16 }}>{missionName}</h4>
        <EnableDropdown name={`${name}.${missionName}.show`} />
      </div>
    )
  }

  const renderBody = (missionName: MissionType) => {
    return <Zones name={`${name}.${missionName}.zones`} />
  }

  const renderAccordion = (missionName: MissionType) => {
    const disabled = !configuration[missionName].show
    return (
      <Accordion
        disabled={disabled}
        header={renderHeader(missionName)}
        body={renderBody(missionName)}
      />
    )
  }

  return (
    <>
      {renderAccordion(MissionsEnum.ADENDUS)}
      {renderAccordion(MissionsEnum.DIMINUS)}
      {renderAccordion(MissionsEnum.MULTIPLIS)}
      {renderAccordion(MissionsEnum.FRACTA)}
      {renderAccordion(MissionsEnum.DIVISIA)}
      {renderAccordion(MissionsEnum.QUESTIA)}
      {renderAccordion(MissionsEnum.HYBRIDIA)}
    </>
  )
}
