import React from 'react'
import cx from 'classnames'
import styles from './admin-button.module.scss'

interface IProps {
  type: 'submit' | 'button'
  children: JSX.Element[] | JSX.Element | string
  disabled?: boolean
  isLoading?: boolean
  className?: string
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void
  tooltip?: string
}

/**
 * @function AdminButton
 * @param type
 * @param children
 * @param disabled
 * @param isLoading
 * @param className
 * @param onClick
 */
export function AdminButton(props: IProps): JSX.Element {
  const { disabled, children, type, onClick, isLoading, className, tooltip } =
    props
  const isDisabled = disabled || isLoading
  const buttonClass = cx(styles.button, className, {
    [styles.disabled]: disabled,
    [styles.loading]: isLoading,
  })

  const handleClick = type === 'button' ? onClick : undefined
  return (
    <button
      type={type}
      className={buttonClass}
      disabled={isDisabled}
      onClick={!isDisabled ? handleClick : undefined}
      {...{ tooltip }}
    >
      {children}
    </button>
  )
}
