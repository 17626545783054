import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  title: string
  description: string
  button: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    title: '404 File not found',
    description: 'Sorry, the page you visited does not exist',
    button: 'Back to home',
  },
  [languageCodes.ro_RO]: {
    title: '404 Fișier negăsit',
    description: 'Scuze, pagina vizitată nu există',
    button: 'Înapoi acasă',
  },
}

export default localeData
