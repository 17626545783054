import { Missions } from '../@types/game'
import { IOptionPlanet } from '../@types/game'
import {
  PLANET_ADENDUS_DISABLED_ICON,
  PLANET_ADENDUS_ICON,
  PLANET_DIMINUS_DISABLED_ICON,
  PLANET_DIMINUS_ICON,
  PLANET_DIVISIA_DISABLED_ICON,
  PLANET_DIVISIA_ICON,
  PLANET_FRACTA_DISABLED_ICON,
  PLANET_FRACTA_ICON,
  PLANET_HYBRIDIA_DISABLED_ICON,
  PLANET_HYBRIDIA_ICON,
  PLANET_MULTIPLIS_DISABLED_ICON,
  PLANET_MULTIPLIS_ICON,
  PLANET_QUESTIA_DISABLED_ICON,
  PLANET_QUESTIA_ICON,
  PLANET_TERRA_DISABLED_ICON,
  PLANET_TERRA_ICON,
} from '../constants/resources'
import {
  TERRA_MODEL,
  TERRA_TEXTURE,
  TERRA_TEXTURE_DISABLED,
  ADENDUS_MODEL,
  ADENDUS_TEXTURE,
  ADENDUS_TEXTURE_DISABLED,
  DIMINUS_MODEL,
  DIMINUS_TEXTURE,
  DIMINUS_TEXTURE_DISABLED,
  MULTIPLIS_MODEL,
  MULTIPLIS_TEXTURE,
  MULTIPLIS_TEXTURE_DISABLED,
  FRACTA_MODEL,
  FRACTA_TEXTURE,
  FRACTA_TEXTURE_DISABLED,
  DIVISIA_MODEL,
  DIVISIA_TEXTURE,
  DIVISIA_TEXTURE_DISABLED,
  HYBRIDIA_MODEL,
  HYBRIDIA_TEXTURE,
  HYBRIDIA_TEXTURE_DISABLED,
  QUESTIA_MODEL,
  QUESTIA_TEXTURE,
  QUESTIA_TEXTURE_DISABLED,
} from '../constants/resources'

export const missionsData: { [key in Missions]: IOptionPlanet } = {
  [Missions.TERRA]: {
    id: Missions.TERRA,
    x: 0,
    y: 0,
    scale: 7,
    speed: 0.0005,
    modelUrl: TERRA_MODEL,
    textureUrl: TERRA_TEXTURE,
    disabledTextureUrl: TERRA_TEXTURE_DISABLED,
  },
  [Missions.ADENDUS]: {
    id: Missions.ADENDUS,
    x: -100,
    y: 150,
    scale: 1.5,
    speed: 0.0006,
    modelUrl: ADENDUS_MODEL,
    textureUrl: ADENDUS_TEXTURE,
    disabledTextureUrl: ADENDUS_TEXTURE_DISABLED,
  },
  [Missions.DIMINUS]: {
    id: Missions.DIMINUS,
    x: 200,
    y: 230,
    scale: 1.5,
    speed: 0.0007,
    modelUrl: DIMINUS_MODEL,
    textureUrl: DIMINUS_TEXTURE,
    disabledTextureUrl: DIMINUS_TEXTURE_DISABLED,
  },
  [Missions.MULTIPLIS]: {
    id: Missions.MULTIPLIS,
    x: -300,
    y: 400,
    scale: 1.5,
    speed: 0.0008,
    modelUrl: MULTIPLIS_MODEL,
    textureUrl: MULTIPLIS_TEXTURE,
    disabledTextureUrl: MULTIPLIS_TEXTURE_DISABLED,
  },
  [Missions.FRACTA]: {
    id: Missions.FRACTA,
    x: 0,
    y: 550,
    scale: 1.5,
    speed: 0.0009,
    modelUrl: FRACTA_MODEL,
    textureUrl: FRACTA_TEXTURE,
    disabledTextureUrl: FRACTA_TEXTURE_DISABLED,
  },
  [Missions.DIVISIA]: {
    id: Missions.DIVISIA,
    x: 400,
    y: 500,
    scale: 1.5,
    speed: 0.001,
    modelUrl: DIVISIA_MODEL,
    textureUrl: DIVISIA_TEXTURE,
    disabledTextureUrl: DIVISIA_TEXTURE_DISABLED,
  },
  [Missions.HYBRIDIA]: {
    id: Missions.HYBRIDIA,
    x: 350,
    y: 100,
    scale: 1.5,
    speed: 0.0001,
    modelUrl: HYBRIDIA_MODEL,
    textureUrl: HYBRIDIA_TEXTURE,
    disabledTextureUrl: HYBRIDIA_TEXTURE_DISABLED,
  },
  [Missions.QUESTIA]: {
    id: Missions.QUESTIA,
    x: -50,
    y: 350,
    scale: 1.5,
    speed: 0.0002,
    modelUrl: QUESTIA_MODEL,
    textureUrl: QUESTIA_TEXTURE,
    disabledTextureUrl: QUESTIA_TEXTURE_DISABLED,
  },
}

export const missionsIcon: {
  [key in Missions]: {
    enabled: string
    disabled: string
  }
} = {
  [Missions.TERRA]: {
    enabled: PLANET_TERRA_ICON,
    disabled: PLANET_TERRA_DISABLED_ICON,
  },
  [Missions.DIMINUS]: {
    enabled: PLANET_DIMINUS_ICON,
    disabled: PLANET_DIMINUS_DISABLED_ICON,
  },
  [Missions.FRACTA]: {
    enabled: PLANET_FRACTA_ICON,
    disabled: PLANET_FRACTA_DISABLED_ICON,
  },
  [Missions.ADENDUS]: {
    enabled: PLANET_ADENDUS_ICON,
    disabled: PLANET_ADENDUS_DISABLED_ICON,
  },
  [Missions.MULTIPLIS]: {
    enabled: PLANET_MULTIPLIS_ICON,
    disabled: PLANET_MULTIPLIS_DISABLED_ICON,
  },
  [Missions.QUESTIA]: {
    enabled: PLANET_QUESTIA_ICON,
    disabled: PLANET_QUESTIA_DISABLED_ICON,
  },
  [Missions.HYBRIDIA]: {
    enabled: PLANET_HYBRIDIA_ICON,
    disabled: PLANET_HYBRIDIA_DISABLED_ICON,
  },
  [Missions.DIVISIA]: {
    enabled: PLANET_DIVISIA_ICON,
    disabled: PLANET_DIVISIA_DISABLED_ICON,
  },
}
