import { useState, useContext } from 'react'
import { ITreeNode, TreeNodeEnum } from '../../../core/@types/image-tree'
import { GeneralContext } from '../../../core/context/generalContext'
import { Modal } from '../../atoms/modal'
import { TreeNode } from '../../atoms/tree-node'
import { AdminButton } from '../../atoms/admin-button'
import { Loading } from '../../atoms/loading'
import { Image } from '../../atoms/image'
import { EXTENSIONS } from '../../../core/constants/general'
import cx from 'classnames'
import locales from './lib/locales';
import styles from './media-picker.module.scss'
import { useBucket } from '../../../core/hooks/useBucket'

export interface IProps {
  value?: string
  onChange(value: string): void
  scope: 'image' | 'audio'
  size: 'small' | 'medium' | 'large'
}

export function MediaPicker({
  scope,
  size,
  value,
  onChange,
}: IProps) {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const [modal, setModal] = useState<{
    visible: boolean
    selectedNode: ITreeNode | null
  }>({
    visible: false,
    selectedNode: null,
  })
  const { treeNodes, refresh } = useBucket({ shouldFetch: modal.visible });

  function onOpenModal() {
    setModal({ ...modal, visible: true })
  }

  function onCloseModal() {
    setModal({ ...modal, visible: false })
  }

  function onChangeTree(node: ITreeNode) {
    setModal({
      ...modal,
      selectedNode: node,
    })
  }

  function renderPreview() {
    if (modal.selectedNode?.type === TreeNodeEnum.LEAF_IMAGE) {
      return (
        <>
          <div
            className={`${styles.media} d-flex align-items-center justify-content-center`}
          >
            <Image
              className={styles.mediaImage}
              src={modal.selectedNode.url}
              alt="preview"
            />
          </div>
          <div className={`${styles.previewLabel} mt-3`}>
            {modal.selectedNode.label}
          </div>
        </>
      )
    } else if (modal.selectedNode?.type === TreeNodeEnum.LEAF_AUDIO) {
      return (
        <>
          <div
            className={`${styles.media} d-flex align-items-center justify-content-center`}
          >
            <audio controls src={modal.selectedNode.url}>
              {locale.noAudioSupport}
            </audio>
          </div>
          <div className={`${styles.previewLabel} mt-3`}>
            {modal.selectedNode.label}
          </div>
        </>
      )
    }
    return (
      <div
        className={`${cx(
          styles.media,
          styles.placeholder
        )} d-flex align-items-center justify-content-center`}
      >
        {scope === 'image' ? locale.selectImage : locale.selectAudio}
      </div>
    )
  }

  function getSelectedMedia(url: string) {
    if (url) {
      const urlList = url.split('/')
      const name = urlList.length > 1 ? urlList[urlList.length - 1] : urlList[0]
      const [fileName, extension] = name.split('.')
      if (EXTENSIONS.image.indexOf(extension) !== -1) {
        return (
          <div
            className={`${cx(styles.media, {
              [styles.mediaSmall]: size === 'small',
              [styles.mediaMedium]: size === 'medium',
              [styles.mediaLarge]: size === 'large',
            })} d-flex align-items-center justify-content-center`}
          >
            <Image src={url} alt="preview" />
          </div>
        )
      } else if (EXTENSIONS.audio.indexOf(extension) !== -1) {
        return (
          <div
            className={`${cx(styles.media, {
              [styles.mediaSmall]: size === 'small',
              [styles.mediaMedium]: size === 'medium',
              [styles.mediaLarge]: size === 'large',
            })} d-flex align-items-center justify-content-center`}
          >
            <audio controls src={url}>
              {locale.noAudioSupport}
            </audio>
          </div>
        )
      }
    }
    return (
      <div
        className={`${cx(styles.media, styles.placeholder, {
          [styles.mediaSmall]: size === 'small',
          [styles.mediaMedium]: size === 'medium',
          [styles.mediaLarge]: size === 'large',
        })} d-flex align-items-center justify-content-center`}
      >
        {locale.select} {scope}
      </div>
    )
  }

  function onSubmitModal() {
    if (modal.selectedNode && modal.selectedNode.url) {
      const url = modal.selectedNode.url
      const urlList = url.split('/')
      const name = urlList.length > 1 ? urlList[urlList.length - 1] : urlList[0]
      const [fileName, extension] = name.split('.')
      if (EXTENSIONS[scope].indexOf(extension) !== -1) {
        onChange(url || '')
        setModal({
          selectedNode: null,
          visible: false,
        })
      }
    }
  }

  return (
    <>
      <Modal
        isVisible={modal.visible}
        title={scope === 'image' ? locale.selectImage : locale.selectAudio}
        submitText={locale.select}
        onSubmit={onSubmitModal}
        onCancel={onCloseModal}
      >
        <div className="d-flex">
          <div className="col-6">
            <AdminButton type="button" className={styles.refreshButton} onClick={refresh}>{locale.refresh}</AdminButton>
            {treeNodes.length > 0 ? treeNodes.map((node: ITreeNode) => (
              <TreeNode
                key={node.label}
                level={0}
                node={node}
                onChange={onChangeTree}
              />
            )): <Loading message={locale.loadingMedia} />}
          </div>
          <div className="col-6">{renderPreview()}</div>
        </div>
      </Modal>
      <div onClick={onOpenModal}>{getSelectedMedia(value || '')}</div>
    </>
  )
}
