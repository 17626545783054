import { useContext } from 'react'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { Button } from '../../../../../../components/atoms/button'
import { Title } from '../../../../../../components/atoms/title'
import { ArrowRight16 } from '@carbon/icons-react'
import locales from './lib/locales'
import styles from './missing-pack.module.scss'

interface IProps {
  onNextZone(): void
}

export function MissingPack({ onNextZone }: IProps) {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]

  return (
    <div className="container-fluid">
      <div className="row p-3">
        <div
          className={`col-12 p-5 d-flex flex-column align-items-center justify-content-center ${styles.missingPack}`}
        >
          <Title
            className="mb-3"
            headingLevel={2}
            title={locale.missingPacks}
          ></Title>
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <Button
                type="button"
                className={`${styles.button} ${styles.continueButton}`}
                onClick={onNextZone}
                title={
                  <>
                    <ArrowRight16 />
                    <span>{locale.nextZone}</span>
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
