export interface DevOptions {
  game: string;
  fps: boolean;
}

export enum Roles {
  AUTHENTICATED = 'Authenticated',
  ADMIN = 'Admin',
}
export type RoleType = Roles.ADMIN | Roles.AUTHENTICATED

export enum languageCodes {
  ro_RO = 'ro_RO',
  en_US = 'en_US',
}
export type languageCodeType = languageCodes.en_US | languageCodes.ro_RO
export interface MenuItem {
  url: string
  name: string
}

export type HeadingLevelType = 1 | 2 | 3 | 4 | 5 | 6

export enum OrderFilters {
  CREATED = 'createdAt:DESC',
  UPDATED = 'updatedAt:DESC',
}

export type OrderFilterType = OrderFilters.CREATED | OrderFilters.UPDATED

export interface ISelectOption {
  value: string
  disabled?: boolean
  title: string
}

export interface IGDPR {
  checked: boolean
}
