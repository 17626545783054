import { useContext, useMemo } from 'react'
import { GeneralContext } from '../../../../core/context/generalContext'
import { MissionContext } from '../../../../core/context/missionContext'
import { missionsIcon } from '../../../../core/game-data/missions'
import { IMission, MissionType } from '../../../../core/@types/game'
import { Button } from '../../../../components/atoms/button'
import { Image } from '../../../../components/atoms/image'
import { User16, Checkmark24 } from '@carbon/icons-react'
import { PROFILE } from '../../../../core/constants/routes'
import locales from './lib/locales'
import cx from 'classnames'
import styles from './mission-menu.module.scss'

interface IProps {
  navigateToMission(mission: MissionType): void
}

export function MissionMenu({ navigateToMission }: IProps) {
  const { missionStarted } = useContext(MissionContext)
  const { setCurrentMission, currentMission, gameInfo, languageCode } =
    useContext(GeneralContext)
  const locale = locales[languageCode]
  const game = gameInfo?.user?.extrauser?.game
  const availableMissions = useMemo(() => {
    const configuration = gameInfo?.user?.extrauser?.group?.configuration;
    if (configuration?.missions) {
      return configuration?.missions.reduce((list: IMission[], mission: IMission) => {
        if (configuration?.enabled_data && configuration?.enabled_data.visibility[mission.type].show) {
          return [
            ...list,
            mission
          ]
        }
        return list
      }, []);
    }
    return []
  }, [gameInfo])
   

  function selectMission(mission: MissionType) {
    if (!missionStarted) {
      setCurrentMission(mission)
      navigateToMission(mission)
    }
  }

  return (
    <div className={`${styles.missionsBar} pb-3`}>
      <Button
        className={styles.profileButton}
        type="button"
        url={PROFILE}
        title={
          <>
            <User16 /> <span>{locale.profile}</span>
          </>
        }
      />
      <div className={`${styles.missionsTitle} mt-3`}>{locale.missions}</div>
      <div className="container-fluid">
        <div className="row py-3">
          {availableMissions?.map((mission: IMission, index: number) => {
            const isDisabled = game?.missions?.indexOf(mission.type) === -1
            const data = missionsIcon[mission.type]
            return (
              <div
                key={index}
                className={`${cx(styles.mission, {
                  [styles.missionSelected]: mission.type === currentMission,
                  [styles.missionDisabled]:
                    missionStarted && mission.type !== currentMission,
                })} col-12 p-2 px-0 d-flex align-items-center justify-content-between`}
                onClick={() => selectMission(mission.type)}
              >
                <div className="d-flex align-items-center">
                  <Image
                    className={`${styles.missionImage} mr-3`}
                    src={isDisabled ? data.disabled : data.enabled}
                    alt="mission-image"
                    loadingSize={40}
                  />
                  {mission.type}
                </div>
                {!isDisabled && (
                  <span className={`${styles.checkmark} ml-2`}>
                    <Checkmark24 />
                  </span>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
