import { gql } from '@apollo/client'

export const getGameInfoQuery = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      username
      extrauser {
        id
        game
        group {
          title
          school
          city
          description
          configuration {
            id
            sem
            feedback
            time
            missions {
              id
              type
            }
            enabled_data
          }
        }
      }
    }
  }
`

export const getMission = gql`
  query Mission($id: ID!) {
    mission(id: $id) {
      zones {
        id
        type
      }
    }
  }
`

export const getZone = gql`
  query Zone($id: ID!) {
    zone(id: $id) {
      packs {
        id
        type
        name
      }
    }
  }
`

export const getPack = gql`
  query Pack($id: ID!) {
    pack(id: $id) {
      exercises {
        id
        type
      }
    }
  }
`

export const getExercise = gql`
  query Exercise($id: ID!) {
    exercise(id: $id) {
      properties
    }
  }
`
