import { useContext, useEffect, useMemo, useState } from 'react'
import { AdminHeader } from '../../components/atoms/admin-header'
import { Title } from '../../components/atoms/title'
import { useForm, FormProvider } from 'react-hook-form'
import { AdminButton } from '../../components/atoms/admin-button'
import { GeneralContext } from '../../core/context/generalContext'
import { useLazyQuery } from '@apollo/react-hooks'
import { getConfigurations } from '../../core/api/graphql/queries/configuration'
import { ErrorBox } from '../../components/atoms/error-box'
import locales, { ILocaleProps } from './lib/locales'
import { generateErrors } from '../../core/utils/generateErrors'
import { Configuration } from './components/configuration'
import { enableData } from '../../core/game-data/configuration'
import { merge } from 'lodash'
import styles from './configurations.module.scss'
import { FETCH_POLICY } from '../../core/constants/general'

/**
 * @function Configurations
 */
export function Configurations(): JSX.Element {
  const methods = useForm({
    shouldUnregister: false,
  })
  const [configurationList, setConfigurationList] = useState<number[]>([])
  const { reset } = methods
  const [fetchConfigurations, { data, loading, error }] = useLazyQuery(
    getConfigurations,
    { fetchPolicy: FETCH_POLICY }
  )
  const errorsMessages = useMemo(() => {
    return generateErrors(error) || []
  }, [error])
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]

  function loadMore() {
    fetchConfigurations({
      variables: {
        start: configurationList.length,
      },
    })
  }

  useEffect(() => {
    fetchConfigurations({
      variables: { start: 0 },
    })
  }, [fetchConfigurations])

  useEffect(() => {
    if (data && data.configurations && data.configurations.length > 0) {
      const list: number[] = []
      const result = data.configurations.map((elem: any, index: number) => {
        list.push(index)
        if (!elem.enabled_data) {
          return {
            ...elem,
            enabled_data: merge({}, enableData),
          }
        }
        return elem
      })
      reset({ configurations: result })
      setConfigurationList(list)
    }
  }, [data, reset])

  return (
    <div className={`${styles.reports}`}>
      <AdminHeader />
      <div className="container">
        <div className="row py-3">
          <div className="col-12">
            <div className="mb-3 d-flex align-items-center">
              <Title title={locale.configurationTitle} headingLevel={3} />
            </div>
            {errorsMessages?.map((messageCode: string, index: number) => (
              <ErrorBox key={`error-${index}`}>{messageCode}</ErrorBox>
            ))}
            <FormProvider {...methods}>
              {configurationList?.map((field: any, index: number) => (
                <Configuration
                  key={field.name}
                  name={`configurations.${index}`}
                />
              ))}
            </FormProvider>
            <div>
              <AdminButton
                type="button"
                className={styles.button}
                onClick={loadMore}
                isLoading={loading}
              >
                {locale?.loadButton}
              </AdminButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
