import { useContext, useMemo, useState, useEffect, useCallback } from 'react'
import {
  Packs as PacksEnum,
  PackType,
  IPack,
  IZoneResponse,
  ZoneType,
} from '../../../../../../core/@types/game'
import { getZone } from '../../../../../../core/api/graphql/queries/game'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { useQuery } from '@apollo/react-hooks'
import { MissionSkeleton } from '../mission-skeleton'
import { Exercises } from '../exercises'
import { MissingPack } from '../missing-pack'
import locales from './lib/locales'
import { MissionContext } from '../../../../../../core/context/missionContext'
import { FETCH_POLICY } from '../../../../../../core/constants/general'

interface IProps {
  zoneId: string
  onNextZone(): void
  currentZone: ZoneType | null
}

export function Packs({ zoneId, currentZone, onNextZone }: IProps) {
  const { gameInfo, languageCode, currentMission } = useContext(GeneralContext)
  const { updateGamePacks, gamePacks } = useContext(MissionContext)
  const locale = locales[languageCode]
  const configuration = gameInfo?.user?.extrauser?.group?.configuration
  const { data, error, loading } = useQuery(getZone, {
    variables: { id: zoneId },
    fetchPolicy: FETCH_POLICY,
  })
  const game = gameInfo?.user.extrauser.game
  const packs = useMemo<
    | {
        [key in PacksEnum]: {
          id: string
          name: string
        }
      }
    | null
  >(() => {
    const response: IZoneResponse = data
    return response && response.zone.packs && response.zone.packs
      ? response.zone.packs.reduce((map: any, pack: IPack) => {
          if (
            currentMission &&
            currentZone &&
            configuration?.enabled_data.visibility[currentMission].zones[currentZone]
              .packs[pack.type].show
          ) {
            return {
              ...map,
              [pack.type]: {
                id: pack.id,
                name: pack.name,
              },
            }
          }
          return map
        }, {})
      : null
  }, [data, configuration, currentZone, currentMission])
  const [currentPack, setCurrentPack] = useState<PackType | null>(null)
  const [currentZoneId, setCurrentZoneId] = useState<string | null>(null)
  const processZonePack = useCallback(
    (packs: {
      [key in PacksEnum]: {
        id: string
        name: string
      }
    }) => {
      // create a loop through the packs of a zone
      let activePack = null
      for (const [packType, packData] of Object.entries(packs)) {
        // if the pack does not exist, add it to the packs
        if (game?.packs.indexOf(packData.name) === -1) {
          activePack = packType as PackType
          updateGamePacks([...gamePacks, packData.name])
          break
        }
      }
      if (!activePack) {
        // If there is no activePack, reset the list of packs (remove the mission packs) and initialise with the first Pack
        const missionPackNames = Object.values(packs).map((pack) => pack.name)
        const newGamePacks = gamePacks.reduce(
          (list: string[], packName: string) => {
            if (missionPackNames.indexOf(packName) !== -1) {
              return list
            }
            return [...list, packName]
          },
          []
        )
        activePack = PacksEnum.PACK1
        newGamePacks.push(packs[activePack].name)
        updateGamePacks(newGamePacks)
      }
      setCurrentPack(activePack)
    },
    [game, updateGamePacks, gamePacks]
  )

  useEffect(() => {
    if (packs && currentZoneId !== zoneId) {
      processZonePack(packs)
      setCurrentZoneId(zoneId)
    }
  }, [currentZoneId, processZonePack, zoneId, packs])

  const packId =
    packs && currentPack && packs[currentPack] ? packs[currentPack].id : null

  if (error) {
    return <MissionSkeleton hideZones errorMessage={locale.errorPackages} />
  }

  if (loading) {
    return <MissionSkeleton hideZones loadingMessage={locale.loadingPacks} />
  }

  return packId ? (
    <Exercises packId={packId} currentZone={currentZone} currentPack={currentPack} onNextZone={onNextZone} />
  ) : (
    <MissingPack onNextZone={onNextZone} />
  )
}
