import React, { useContext } from 'react'
import { GeneralContext } from '../../core/context/generalContext'
import { HOME } from '../../core/constants/routes'
import { LogoutHeader } from '../../components/atoms/logout-header'
import { Image } from '../../components/atoms/image'
import {
  TEAM_ALINA_FLOREA,
  TEAM_GABRIEL_CRISAN,
  TEAM_IOANA_MAGDAS,
  TEAM_LAURA_VISU,
  TEAM_LAVINIA_CHEIE,
  TEAM_LIZICA_GROZA,
  TEAM_MONICA_BUTA,
  TEAM_SIMINA_CACUCI,
  TEAM_THOMAS_HUNT,
  BACKGROUND_SPACE_BACKGROUND,
} from '../../core/constants/resources'
import locales, { ILocaleProps } from './lib/locales'
import styles from './team.module.scss'

export function Team(): JSX.Element {
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]

  return (
    <div
      className={styles.team}
      style={{ backgroundImage: `url(${BACKGROUND_SPACE_BACKGROUND})` }}
    >
      <div className={`${styles.wrapper}`}>
        <LogoutHeader
          items={[
            {
              url: HOME,
              name: locale?.homeButton,
            },
          ]}
        />
        <div className="container px-5">
          <div className="row p-4">
            <div className="col-md-8 col-md-offset-2">
              <h3 className={styles.title}>{locale?.title}</h3>
              <p className={styles.description}>{locale?.description}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_GABRIEL_CRISAN} alt="team-member" />
                <span>{locale?.gabiTitle}</span>
                <h4>
                  <span>Vasile - Gabriel </span> Crișan
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_LAURA_VISU} alt="team-member" />
                <span>{locale?.lauraTitle}</span>
                <h4>
                  <span>Laura Visu</span> Petra
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_LAVINIA_CHEIE} alt="team-member" />
                <span>{locale?.laviniaTitle}</span>
                <h4>
                  <span>Lavinia</span> Cheie
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_MONICA_BUTA} alt="team-member" />
                <span>{locale?.monicaTitle}</span>
                <h4>
                  <span>Monica</span>Buta
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_SIMINA_CACUCI} alt="team-member" />
                <span>{locale?.siminaTitle}</span>
                <h4>
                  <span>Simina-Alexandra</span>Cacuci
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_ALINA_FLOREA} alt="team-member" />
                <span>{locale?.alinaTitle}</span>
                <h4>
                  <span>Alina</span>Florea
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_LIZICA_GROZA} alt="team-member" />
                <span>{locale?.lizicaTitle}</span>
                <h4>
                  <span>Lizica</span>Groza
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_IOANA_MAGDAS} alt="team-member" />
                <span>{locale?.ioanaTitle}</span>
                <h4>
                  <span>Ioana-Cristina</span>Magdaș
                </h4>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center pb-5">
              <div className={styles.member}>
                <Image src={TEAM_THOMAS_HUNT} alt="team-member" />
                <span>{locale?.thomasTitle}</span>
                <h4>
                  <span>Thomas</span>Hunt
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
