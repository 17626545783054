import React, { useState, useEffect } from 'react'
import { setStorage, getStorage, removeStorage } from '../storage'
import {
  JWT_TOKEN,
  USER_INFO,
  GAME_INFO,
  LANGUAGE_CODE,
  MATEMATROLII_DEV,
  FETCH_POLICY,
} from '../constants/general'
import { IUserInfo, IToken } from '../@types/auth'
import { IGameInfoResponse, MissionType, IGame } from '../@types/game'
import { languageCodeType, languageCodes, DevOptions } from '../@types/general'
import { updateGame } from '../api/game'
import { useLazyQuery } from '@apollo/react-hooks'
import { getGameInfoQuery } from '../api/graphql/queries/game'
import { updateConfiguration } from '../api/game'
import { client } from '../api/graphql/client'
import { Roles } from '../@types/general'
import { merge } from 'lodash'
import { enableData } from '../game-data/configuration'

interface IContext {
  logout(): void
  setApp(app: {
    jwtToken: IToken | null
    userInfo: IUserInfo | null
    gameInfo: IGameInfoResponse | null
  }): void
  userInfo: IUserInfo | null
  gameInfo: IGameInfoResponse | null
  jwtToken: IToken | null
  setLanguageCode(languageCode: languageCodeType): void
  updateGameInfo(game: IGame): Promise<boolean>
  currentMission: MissionType | null

  setCurrentMission(mission: MissionType): void
  languageCode: languageCodeType
}

interface IProps {
  children: React.ReactNode
  app: {
    userInfo: IUserInfo | null
    gameInfo: IGameInfoResponse | null
    jwtToken: IToken | null
  }
  setApp(app: {
    userInfo: IUserInfo | null
    gameInfo: IGameInfoResponse | null
    jwtToken: IToken | null
  }): void
}

const initialContext = {
  userInfo: null,
  gameInfo: null,
  jwtToken: null,
  setApp: function () {},
  logout: function () {},
  setLanguageCode: function () {},
  updateGameInfo: function (): Promise<boolean> {
    return new Promise((resolve) => resolve(false))
  },
  currentMission: null,
  setCurrentMission: () => {},
  languageCode: languageCodes.ro_RO,
}

export const GeneralContext = React.createContext<IContext>(initialContext)

let languageCodeData = getStorage(LANGUAGE_CODE)
const userStored: IUserInfo | null = getStorage(USER_INFO)
const jwtTokenStored: IToken | null = getStorage(JWT_TOKEN)
if (!languageCodeData) {
  languageCodeData = { languageCode: languageCodes.ro_RO }
  setStorage(languageCodeData, LANGUAGE_CODE, true)
}

export function GeneralContextProvider({ children, app, setApp }: IProps) {
  const [getGameInfo, { data: gameInfo, loading: loadingGameInfo }] =
    useLazyQuery(getGameInfoQuery, {
      fetchPolicy: FETCH_POLICY,
    })
  const appCurrentMission =
    app?.gameInfo?.user?.extrauser?.game?.currentMission || null
  // TODO: remove the local storage bypass after the planets are done
  let devStorage: DevOptions = getStorage(MATEMATROLII_DEV)
  const currentMission =
    devStorage && devStorage?.game
      ? devStorage.game.toUpperCase()
      : appCurrentMission
  const [languageCode, setLanguageCode] = useState(
    languageCodeData?.languageCode
  )
  const [globalCurrentMission, setGlobalCurrentMission] = useState<MissionType>(
    currentMission as MissionType
  )

  useEffect(() => {
    // if user is already logged in and is player
    if (userStored && userStored.role?.name === Roles.AUTHENTICATED) {
      getGameInfo({
        variables: { id: userStored.id },
      })
    }
  }, [getGameInfo])

  useEffect(() => {
    async function updateConfigurationData(id: string, data: any) {
      try {
        await updateConfiguration(id, data)
      } catch (err) {
        console.error('Error', err)
      }
    }
    if (!loadingGameInfo && gameInfo) {
      const storedGameInfo = merge({}, gameInfo)
      const configuration = storedGameInfo.user?.extrauser?.group?.configuration
      if (configuration && !configuration.enabled_data) {
        configuration.enabled_data = enableData
        updateConfigurationData(configuration.id, enableData)
      }
      setStorage(storedGameInfo, GAME_INFO, true)
      setApp({
        jwtToken: jwtTokenStored,
        userInfo: userStored,
        gameInfo: storedGameInfo as IGameInfoResponse,
      })
    }
  }, [gameInfo, loadingGameInfo, setApp])

  function logout() {
    client.clearStore()
    removeStorage(GAME_INFO)
    removeStorage(USER_INFO)
    removeStorage(JWT_TOKEN)
    setApp({
      jwtToken: null,
      userInfo: null,
      gameInfo: null,
    })
  }

  function setCurrentMission(mission: MissionType) {
    setGlobalCurrentMission(mission.toUpperCase() as MissionType)
  }

  async function updateGameInfo(game: IGame): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (app?.gameInfo?.user?.extrauser) {
        try {
          await updateGame(app?.gameInfo?.user?.extrauser?.id, game)
          const newGameInfo = JSON.parse(JSON.stringify(app?.gameInfo))
          newGameInfo.user.extrauser.game = game
          setApp({
            ...app,
            gameInfo: newGameInfo,
          })
          resolve(true)
        } catch (err) {
          reject(err)
        }
      }
      reject(new Error('No extrauser available for game update'))
    })
  }

  return (
    <GeneralContext.Provider
      value={{
        logout,
        setApp,
        currentMission: globalCurrentMission,
        setCurrentMission,
        userInfo: app?.userInfo,
        gameInfo: app?.gameInfo,
        jwtToken: app?.jwtToken,
        languageCode,
        updateGameInfo,
        setLanguageCode,
      }}
    >
      {children}
    </GeneralContext.Provider>
  )
}
