import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  missingZone: string
  errorZone: string
  loadingZone: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    missingZone: 'The zone is missing',
    errorZone: 'There was an error when fetching the zones',
    loadingZone: 'Loading mission zones...',
  },
  [languageCodes.ro_RO]: {
    missingZone: 'Zona lipsește',
    errorZone: 'A apărut o eroare în timpul primirii zonelor',
    loadingZone: 'Încarcare zone misiune...',
  },
}

export default localeData
