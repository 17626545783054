import { forwardRef, memo } from 'react'
import { createPortal } from 'react-dom'
import cx from 'classnames'
import styles from './canvas.module.scss'

interface IProps {
  className?: string
}

/**
 * @function Canvas
 * @param className
 */
const CanvasElement = forwardRef<HTMLCanvasElement, IProps>(function (
  { className },
  ref
): JSX.Element {
  return createPortal(
    <canvas className={cx(className, styles.canvas)} ref={ref} />,
    document.body
  )
})

export const Canvas = memo(CanvasElement)
