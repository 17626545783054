import cx from 'classnames'
import styles from './error-message.module.scss'

interface IProps {
  className?: string
  children?: JSX.Element[] | JSX.Element | string | undefined
}

/**
 * @function ErrorMessage
 * @param children
 * @param className
 */
export function ErrorMessage({ children, className }: IProps): JSX.Element {
  return <div className={cx(styles.error, className)}>{children}</div>
}
