import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  title: string
  button: string
  team: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    title: 'Mathematrolls',
    button: 'Start Game',
    team: 'About Team',
  },
  [languageCodes.ro_RO]: {
    title: 'Matematrolii',
    button: 'Start Joc',
    team: 'Despre Echipă',
  },
}

export default localeData
