import AWS, { AWSError } from 'aws-sdk'
import { ListObjectsV2Output } from 'aws-sdk/clients/s3'
import {
  AWS_ACCESS_KEY_ID,
  AWS_BUCKET_NAME,
  AWS_REGION,
  AWS_SECRET_ACCESS_KEY,
} from '../../constants/general'
interface IAWSParams {
  Bucket: string
  MaxKeys: number
  Prefix: string
  ContinuationToken?: string
}

AWS.config.update({
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
  region: AWS_REGION,
})

export async function getBucketData(
  prefix: string,
  token: string
): Promise<ListObjectsV2Output> {
  return new Promise((resolve, reject) => {
    const params: IAWSParams = {
      Bucket: AWS_BUCKET_NAME,
      MaxKeys: 30,
      Prefix: prefix,
    }
    if (token) {
      params.ContinuationToken = token
    }
    const s3 = new AWS.S3({
      params,
    })

    s3.listObjectsV2(function (err: AWSError, data: ListObjectsV2Output) {
      if (err) {
        console.log(err)
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}
