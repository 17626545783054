import { useState, MouseEvent } from 'react'
import {
  ChevronDown16,
  ChevronRight16,
  VolumeUp16,
  Image16,
  DocumentBlank16,
} from '@carbon/icons-react'
import {
  ITreeNode,
  TreeNodeEnum,
  TreeNodeType,
} from '../../../core/@types/image-tree'
import cx from 'classnames'
import styles from './tree-node.module.scss'

export interface IProps {
  node: ITreeNode
  level: number
  onChange(node: ITreeNode): void
}

function getPaddingLeft(level: number, type: TreeNodeType): number {
  const paddingLeft = level * 20
  return type !== TreeNodeEnum.LIST ? paddingLeft + 10 : paddingLeft
}

export function TreeNode({ node, level, onChange }: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isParent = node.type === TreeNodeEnum.LIST
  const isImage = node.type === TreeNodeEnum.LEAF_IMAGE
  const isAudio = node.type === TreeNodeEnum.LEAF_AUDIO
  const isDefault = node.type === TreeNodeEnum.LEAF_DEFAULT
  const hasChildren = node.children && node.children.length > 0

  function onToggle(ev: MouseEvent<HTMLDivElement>) {
    ev.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <div role="treeitem">
      <div
        className={styles.treeNode}
        style={{ paddingLeft: getPaddingLeft(level, node.type) }}
        onClick={isParent ? onToggle : undefined}
      >
        {isParent && (
          <div className="d-flex align-items-center mr-2">
            {isOpen ? (
              <ChevronDown16 data-testid="arrow-down" />
            ) : (
              <ChevronRight16 data-testid="arrow-right" />
            )}
          </div>
        )}
        {isImage && (
          <div className={`${styles.image} d-flex align-items-center  mr-2`}>
            <Image16 />
          </div>
        )}
        {isAudio && (
          <div className={`${styles.audio} d-flex align-items-center  mr-2`}>
            <VolumeUp16 />
          </div>
        )}
        {isDefault && (
          <div className="d-flex align-items-center mr-2">
            <DocumentBlank16 />
          </div>
        )}
        <span
          className={styles.nodeLabel}
          onClick={!isParent ? () => onChange(node) : undefined}
        >
          {node.label}
        </span>
      </div>
      {hasChildren && (
        <div
          className={cx(styles.nodeList, {
            [styles.nodeListVisible]: isOpen,
          })}
        >
          {node.children?.map((childNode: ITreeNode, index: number) => (
            <TreeNode
              key={`${childNode.label}-${index}`}
              node={childNode}
              level={level + 1}
              onChange={onChange}
            />
          ))}
        </div>
      )}
    </div>
  )
}
