import { ExerciseType } from './game'

export enum Mimes {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  IMAGE_WEBP = 'image/webp',
  IMAGE_SVG = 'image/svg+xml',
  AUDIO_MPEG = 'audio/mpeg',
}

export enum Extensions {
  JPEG = '.jpg',
  PNG = '.png',
  SVG = '.svg',
  WEBP = '.webp',
  MP3 = '.mp3',
}

export type extensionTypes =
  | Extensions.JPEG
  | Extensions.PNG
  | Extensions.WEBP
  | Extensions.SVG
  | Extensions.MP3
export type mimeTypes =
  | Mimes.IMAGE_JPEG
  | Mimes.IMAGE_PNG
  | Mimes.IMAGE_WEBP
  | Mimes.IMAGE_SVG
  | Mimes.AUDIO_MPEG

export interface IFile {
  _id?: string
  name: string
  alternativeText?: string
  caption?: string
  hash?: string
  ext?: extensionTypes
  mime: mimeTypes
  size?: number
  width?: number
  height?: number
  url: string
  provider?: string
  related?: []
  createdAt?: string
  updatedAt?: string
  __v?: number
  id: string
}

export interface IFilesResponse {
  files: IFile[]
}

export interface IExerciseImage {
  url: string
  startAt: string
}

export enum Helper {
  HELPER_ONE = 'helperOne',
  HELPER_TWO = 'helperTwo',
}

export enum OptionValue {
  TEXT = 'TEXT',
  IMAGES = 'IMAGES',
}

export type OptionValueType = OptionValue.IMAGES | OptionValue.TEXT

export enum Options {
  OPTION_ONE = 'optionOne',
  IMAGE_OPTION_ONE = 'imageOptionOne',
  OPTION_TWO = 'optionTwo',
  IMAGE_OPTION_TWO = 'imageOptionTwo',
  OPTION_THREE = 'optionThree',
  IMAGE_OPTION_THREE = 'imageOptionThree',
  OPTION_FOUR = 'optionFour',
  IMAGE_OPTION_FOUR = 'imageOptionFour',
  OPTION_SOLUTION = 'solution',
  IMAGE_OPTION_SOLUTION = 'imageSolution',
}

export type OptionTypes =
  | Options.OPTION_ONE
  | Options.IMAGE_OPTION_ONE
  | Options.OPTION_TWO
  | Options.IMAGE_OPTION_TWO
  | Options.OPTION_THREE
  | Options.IMAGE_OPTION_THREE
  | Options.OPTION_FOUR
  | Options.IMAGE_OPTION_FOUR
  | Options.OPTION_SOLUTION
  | Options.IMAGE_OPTION_SOLUTION

export type HelperTypes = Helper.HELPER_ONE | Helper.HELPER_TWO

export interface IExerciseHelper {
  audio: string
  images: IExerciseImage[]
}

export interface IExerciseProperties {
  optionOne: string
  imageOptionOne: string
  optionTwo: string
  imageOptionTwo: string
  optionThree: string
  imageOptionThree: string
  optionFour: string
  imageOptionFour: string
  solution: string
  imageSolution: string
  editor: string
  helperOne: IExerciseHelper
  helperTwo: IExerciseHelper
}

export interface IExercise {
  id: string
  name: string
  title: string
  properties: IExerciseProperties
  type: ExerciseType
  pack?: any
  histories?: any[]
}

export interface IExerciseResponse {
  exercise: IExercise
}

export interface IExerciseItem {
  id: string
  title: string
  name: string
}

export interface IExerciseUpdate {
  id: string
  title: string
  properties: IExerciseProperties
}

export interface IHistoryItem {
  _id: string
  extrauser: {
    __typename: string
    id: string
    firstName: string
    lastName: string
  }
  exercise: {
    __typename: string
    id: string
    title: string
  }
  isCorrect: boolean
  pickedValue: string
  timeSpent: number
  attempts: number
  createdAt: string
}

export interface IHistoryItemResult {
  historyId: string
  userId: string
  userName: string
  exerciseId: string
  exerciseTitle: string
  isCorrect: string
  pickedValue: string
  timeSpent: string
  attempts: number
  createdAt: string
}

export interface IHistoryData {
  attempts: number
  isCorrect: boolean
  pickedValue: string
  timeSpent: number
  exercise: string
  extrauser: string
}
