import { useState, useEffect, useRef, ReactNode } from 'react'
import cx from 'classnames'
import { useAnimationFrame } from '../../../core/hooks/useAnimationFrame'
import { ArrowLeft16, ArrowRight16 } from '@carbon/icons-react'
import styles from './carousel.module.scss'

interface IProps {
  currentSlide: number
  slides: ReactNode[]
  auto?: boolean
  onEnd?(): void
  onChange?(index: number): void
  hasBullets?: boolean
  hasArrows?: boolean
}

/**
 * @function Carousel
 * @param currentSlide
 * @param slides
 * @param auto
 * @param onEnd
 * @param onChange
 * @param hasBullets
 * @param hasArrows
 */
export function Carousel({
  currentSlide,
  slides,
  auto,
  onEnd,
  onChange,
  hasBullets,
  hasArrows,
}: IProps): JSX.Element {
  const [selected, setSelected] = useState(0)
  const size = slides.length
  const totalWidth = 100
  const wrapperWidth = size * totalWidth
  const slideWidth = totalWidth / size
  const position = -(totalWidth / size) * selected
  const { stopAnimation } = useAnimationFrame(
    () => {
      setSelected((prevSelected) => {
        if (prevSelected === size - 1) {
          //TODO: fix the warning after the carousel ends on story page (auto enabled)
          stopAnimation()
          onEnd && onEnd()
          return prevSelected
        } else {
          return prevSelected + 1
        }
      })
    },
    5, // trigger every 5 seconds
    !!auto
  )

  function onNextSlide() {
    stopAnimation()
    if (selected === size - 1) {
      onEnd && onEnd()
    } else {
      setSelected(selected + 1)
    }
  }

  function onPrevSlide() {
    stopAnimation()
    if (selected > 0) {
      setSelected(selected - 1)
    }
  }

  function onSlideChange(index: number) {
    stopAnimation()
    onChange && onChange(index)
    setSelected(index)
  }

  function renderSlides() {
    return slides.map((slide, index) => {
      return (
        <li
          key={index}
          className={styles.slide}
          style={{ width: `${slideWidth}%` }}
        >
          {slide}
        </li>
      )
    })
  }

  function renderBullets() {
    return slides.map((slide, index) => {
      const isSelected = selected === index
      return (
        <span
          key={index}
          className={cx(styles.bullet, {
            [styles.selected]: isSelected,
          })}
          onClick={() => onSlideChange(index)}
        />
      )
    })
  }

  useEffect(() => {
    setSelected(currentSlide)
  }, [currentSlide])

  return (
    <div className={styles.carousel}>
      {hasArrows && (
        <div
          className={cx(styles.arrowLeft, {
            [styles.disabled]: selected === 0,
          })}
          onClick={onPrevSlide}
        >
          <ArrowLeft16 />
        </div>
      )}
      <ul
        className={styles.slides}
        style={{
          width: `${wrapperWidth}%`,
          transform: `translateX(${position}%)`,
        }}
      >
        {renderSlides()}
      </ul>
      {hasArrows && (
        <div
          className={cx(styles.arrowRight, {
            [styles.disabled]: selected === size - 1,
          })}
          onClick={onNextSlide}
        >
          <ArrowRight16 />
        </div>
      )}
      {hasBullets && <div className={styles.navigation}>{renderBullets()}</div>}
    </div>
  )
}
