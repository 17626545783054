import { languageCodes } from '../../../../core/@types/general'

export interface ILocaleProps {
  message: string
  acceptButton: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    message:
      'For purposes such as displaying personalized content, we use cookies or similar technologies. By clicking Accept or browsing this website, you agree to allow the collection of information through cookies or similar technologies. Find out more about cookies in the Cookies Policy section, including the possibility of withdrawing your agreement.',
    acceptButton: 'Accept',
  },
  [languageCodes.ro_RO]: {
    message:
      'Pentru scopuri precum afișarea de conținut personalizat, folosim module cookie sau tehnologii similare. Apăsând Accept sau navigând pe acest website, ești de acord să permiți colectarea de informații prin cookie-uri sau tehnologii similare. Află in sectiunea Politica de Cookies mai multe despre cookie-uri, inclusiv despre posibilitatea retragerii acordului.',
    acceptButton: 'Acceptă',
  },
}

export default localeData
