import * as THREE from 'three'

export interface IScene {
  scene: any
  renderer: any
  camera: any
  clock: any
}

export interface IOptions {
  hasStats: boolean
}

export class Scene {
  canvas: HTMLCanvasElement | null = null
  renderer: THREE.WebGLRenderer | null = null
  scene: THREE.Scene | null = null
  camera: THREE.PerspectiveCamera | null = null
  clock: THREE.Clock | null = null

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    // Add event listener
    window.addEventListener('resize', this.handleResize)
    const bbox = this.canvas.getBoundingClientRect()
    const height = bbox.height
    const width = bbox.width

    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: false,
      canvas
    })
    this.renderer.setClearColor('#ffffff', 0)
    this.renderer.sortObjects = false
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(width, height)
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(50, width / height, 1, 5000)
    this.camera.position.set(0, 0, 400)
    // const axesHelper = new THREE.AxesHelper( 100 );
    // scene.add( axesHelper );
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.8)
    hemiLight.position.set(0, 50, 0)
    this.scene.add(hemiLight)
    this.clock = new THREE.Clock()
  }

  handleResize = () => {
    if (this.canvas) {
      const bbox = this.canvas.getBoundingClientRect()
      const height = bbox.height
      const width = bbox.width
      if (this.camera) {
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
      }
      if (this.renderer) {
        this.renderer.setSize(width, height)
      }
    }
  }

  render() {
    if (this.renderer && this.scene && this.camera) {
      this.renderer.render(this.scene, this.camera)
    }
  }

  destroy() {
    window.removeEventListener('resize', this.handleResize)
    if (this.scene) {
      while (this.scene.children.length > 0) {
        this.scene.remove(this.scene.children[0])
      }
      this.scene = null
    }
    if (this.renderer) {
      this.renderer.dispose()
      this.renderer.forceContextLoss()
      this.renderer = null
    }
    this.clock?.stop()
    this.clock = null
    this.canvas = null
    this.camera?.remove()
    this.camera = null
  }
}
