import { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import styles from './button.module.scss'

interface IProps {
  type: 'button' | 'submit'
  isLoading?: boolean
  disabled?: boolean
  url?: string
  title: JSX.Element | string
  className?: string
  loadingTitle?: JSX.Element | string
  onClick?(ev: MouseEvent<HTMLButtonElement>): void
}

/**
 * @function Button
 * @param isLoading
 * @param title
 * @param loadingTitle
 * @param type
 * @param url
 */
export function Button({
  isLoading,
  disabled,
  title,
  loadingTitle,
  type,
  url,
  className,
  onClick,
}: IProps): JSX.Element {
  if (url) {
    return (
      <Link className={`${cx(styles.button, className)}`} to={url} role={type}>
        {title}
      </Link>
    )
  }
  return (
    <button
      disabled={disabled || isLoading}
      onClick={onClick}
      className={`${cx(styles.button, className)}`}
      type={type}
    >
      {isLoading ? loadingTitle : title}
    </button>
  )
}
