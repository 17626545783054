/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'

export function useAnimationFrame(
  callback: (delta: number) => void,
  stepSeconds: number,
  enabled: boolean
) {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number | null>(null)
  const previousTimeRef = useRef<number | null>(null)

  const animate = (time: number) => {
    if (!previousTimeRef.current) {
      previousTimeRef.current = time
    }
    if (
      previousTimeRef.current &&
      time - previousTimeRef.current >= stepSeconds * 1000
    ) {
      previousTimeRef.current = time
      const deltaTime = time - previousTimeRef.current
      callback(deltaTime)
    }
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    if (enabled) {
      // remove current animation frame with the old callback
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
      requestRef.current = requestAnimationFrame(animate)
    }
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [callback]) // Make sure the effect runs only once

  //TODO: fix the animation frame blur because is losing states in galaxy hook (investigate)
  useEffect(() => {
    function enableAnimationFrame() {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
      requestRef.current = requestAnimationFrame(animate)
    }
    function disableAnimationFrame() {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }

    window.addEventListener('focus', enableAnimationFrame)
    window.addEventListener('blur', disableAnimationFrame)

    return () => {
      window.removeEventListener('blur', disableAnimationFrame)
      window.removeEventListener('focus', enableAnimationFrame)
    }
  }, [])

  const startAnimation = () => {
    if (enabled) {
      requestRef.current = requestAnimationFrame(animate)
    }
  }

  const stopAnimation = () => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current)
    }
  }

  return { startAnimation, stopAnimation }
}
