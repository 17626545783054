import { missionsData } from '../../../../core/game-data/missions'
import { rocketsData } from '../../../../core/game-data/rockets'
import { MissionType, IGame } from '../../../../core/@types/game'
import { IOptionPlanet, Missions, Rockets } from '../../../../core/@types/game'
import { Keys } from '../../../../core/@types/controls'
import { Scene } from './components/scene'
import { Rocket } from './components/rocket'
import { Planet } from './components/planet'
import { PlanetMarker } from './components/planet-marker'
import { ROCKET_NAVIGATION_EVENT } from '../../../../core/constants/game'

export class Galaxy {
  game: IGame | null = null
  scene: Scene | null = null
  planets: Planet[] | null = null
  rocket: Rocket | null = null
  planetMarker: PlanetMarker | null = null
  isUp: boolean = false
  isDown: boolean = false
  isLeft: boolean = false
  isRight: boolean = false
  animationFrame: number | null = null

  async loadPlanets() {
    if (this.game && this.scene) {
      const currentMission = (
        this.game.currentMission || Missions.TERRA
      ).toUpperCase()
      this.planets = Object.values(missionsData).map(
        (mission: IOptionPlanet) => {
          const isEnabled = this.game
            ? this.game.missions.indexOf(mission.id) !== -1
            : false
          return new Planet(
            this.scene?.scene,
            {
              ...mission,
            },
            isEnabled
          )
        }
      )
      await Promise.all(this.planets.map((planet) => planet.load()))
      const startPlanet = missionsData[currentMission as MissionType]
      this.rocket = new Rocket(this.scene.scene, this.scene.camera, {
        modelUrl:
          rocketsData[this.game.rocket || Rockets.ROCKET_APOLLO].modelUrl || '',
        textureUrl:
          rocketsData[this.game.rocket || Rockets.ROCKET_APOLLO].textureUrl ||
          '',
        x: startPlanet.x,
        y: startPlanet.y,
        scale: 2,
      })
      await this.rocket.load()
      this.planetMarker = new PlanetMarker(this.scene.scene, {
        x: startPlanet.x,
        y: startPlanet.y,
        scale: 1.5,
        speed: 0.001,
        textureUrl: '',
        disabledTextureUrl: '',
        modelUrl: '',
        id: Missions.TERRA,
      })
      this.planetMarker.load()
      this.rocket.followCamera()
    }
  }

  constructor(game: IGame, canvas: HTMLCanvasElement) {
    this.game = game
    this.scene = new Scene(canvas)
    this.loadPlanets()
    if (this.scene.camera) {
      this.scene.camera.position.set(0, 0, 400)
    }
    this.animationFrame = requestAnimationFrame(this.animate)
    window.addEventListener('focus', this.enableAnimationFrame)
    window.addEventListener('blur', this.disableAnimationFrame)
    window.addEventListener('keydown', this.downHandler)
    window.addEventListener('keyup', this.upHandler)
    window.addEventListener(
      ROCKET_NAVIGATION_EVENT as any,
      this.rocketNavigation
    )
  }

  animate = () => {
    this.render()
    this.animationFrame = requestAnimationFrame(this.animate)
  }

  enableAnimationFrame = () => {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame)
    }
    this.animationFrame = requestAnimationFrame(this.animate)
  }
  disableAnimationFrame = () => {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame)
    }
  }

  rocketNavigation = (ev: CustomEvent) => {
    const detail = ev.detail
    if (this.rocket && detail) {
      this.rocket.animate({
        x: detail.x,
        y: detail.y,
      })
    }
    if (this.planetMarker && detail) {
      this.planetMarker?.mesh?.position?.set(detail.x, detail.y, 0)
    }
  }

  downHandler = ({ key }: KeyboardEvent) => {
    switch (key) {
      case Keys.ARROW_UP:
        this.isUp = true
        break
      case Keys.ARROW_DOWN:
        this.isDown = true
        break
      case Keys.ARROW_LEFT:
        this.isLeft = true
        break
      case Keys.ARROW_RIGHT:
        this.isRight = true
        break
      default:
        break
    }
  }

  // If released key is our target key then set to false
  upHandler = ({ key }: KeyboardEvent) => {
    switch (key) {
      case Keys.ARROW_UP:
        this.isUp = false
        break
      case Keys.ARROW_DOWN:
        this.isDown = false
        break
      case Keys.ARROW_LEFT:
        this.isLeft = false
        break
      case Keys.ARROW_RIGHT:
        this.isRight = false
        break
      default:
        break
    }
  }

  render() {
    if (this.scene) {
      this.scene.render()
    }
    if (this.planets && this.planets.length > 0) {
      for (let i = this.planets.length - 1; i >= 0; i--) {
        this.planets[i].update()
      }
    }
    if (this.rocket) {
      this.rocket.update()
    }
    if (this.planetMarker) {
      this.planetMarker.update()
    }

    if (this.scene && this.scene.clock && this.scene.camera) {
      const delta = this.scene.clock.getDelta()
      const position = 200 * delta

      if (this.isLeft) {
        this.scene.camera.translateX(-1 * position)
        if (this.scene.camera.position.x < -200) {
          this.scene.camera.position.set(
            -200,
            this.scene.camera.position.y,
            this.scene.camera.position.z
          )
        }
      }
      if (this.isRight) {
        this.scene.camera.translateX(position)
        if (this.scene.camera.position.x > 300) {
          this.scene.camera.position.set(
            300,
            this.scene.camera.position.y,
            this.scene.camera.position.z
          )
        }
      }
      if (this.isUp) {
        this.scene.camera.translateY(position)
        if (this.scene.camera.position.y > 1000) {
          this.scene.camera.position.set(
            this.scene.camera.position.x,
            1000,
            this.scene.camera.position.z
          )
        }
      }
      if (this.isDown) {
        this.scene.camera.translateY(-1 * position)
        if (this.scene.camera.position.y < 0) {
          this.scene.camera.position.set(
            this.scene.camera.position.x,
            0,
            this.scene.camera.position.z
          )
        }
      }
    }
  }

  destroy() {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame)
    }
    window.removeEventListener('blur', this.disableAnimationFrame)
    window.removeEventListener('focus', this.enableAnimationFrame)
    window.removeEventListener('keydown', this.downHandler)
    window.removeEventListener('keyup', this.upHandler)
    window.removeEventListener(
      ROCKET_NAVIGATION_EVENT as any,
      this.rocketNavigation
    )
    this.rocket?.destroy()
    this.scene?.destroy()
  }
}
