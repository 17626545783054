import { useState, UIEventHandler, SyntheticEvent } from 'react';
import throttle from 'lodash/throttle';

interface VirtualRenderingProps {
  list: object[];
  oncePerMs?: number;
  viewportHeight: number;
  totalRows: number;
  rowHeight: number;
  rowsBuffered: number;
}

export function useVirtualRendering({
  list = [],
  oncePerMs = 500,
  viewportHeight = 400,
  totalRows = 40,
  rowHeight = 40,
  rowsBuffered = 2,
}: VirtualRenderingProps): {
  onScroll: UIEventHandler<HTMLDivElement>;
  getTopPosition(position: number): number;
  containerHeight: number;
  renderedList: object[];
} {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const containerHeight = totalRows * rowHeight;
  const indexStart = Math.max(
    Math.floor(scrollTop / rowHeight) - rowsBuffered,
    0
  );
  const indexEnd = Math.min(
    Math.ceil((scrollTop + viewportHeight) / rowHeight - 1) + rowsBuffered,
    totalRows - 1
  );
  const selectedList = list.slice(indexStart, indexEnd + 1);
  const pendingArraySize = indexEnd + 1 - indexStart - selectedList.length;
  const renderedList = selectedList.concat(
    Array(pendingArraySize > 0 ? pendingArraySize : 0).fill(undefined)
  );

  const onScroll = (event: SyntheticEvent<HTMLDivElement>) => {
    event.persist();
    throttle((ev) => setScrollTop(ev.target.scrollTop), oncePerMs, {
      leading: false,
    })(event);
  };

  const getTopPosition = (position: number) => {
    return (indexStart + position) * rowHeight;
  };

  return {
    onScroll,
    getTopPosition,
    containerHeight,
    renderedList,
  };
}
