import { ApolloError } from '@apollo/react-hooks'

export function generateErrors(errors: ApolloError | undefined): string[] {
  const graphQLErrors =
    errors && errors.graphQLErrors ? errors.graphQLErrors : []
  return graphQLErrors.reduce(
    (list: any, { extensions }): string[] | undefined => {
      const data =
        extensions &&
        extensions?.exception &&
        extensions?.exception?.data &&
        extensions?.exception?.data?.data
          ? extensions?.exception?.data?.data
          : []
      return [
        ...list,
        ...data.reduce(
          (dataList: any, { messages }: any): string[] | undefined => {
            return [
              ...dataList,
              ...messages?.reduce(
                (messageList: any, { id, message }: any): string[] => {
                  return [...messageList, id]
                },
                []
              ),
            ]
          },
          []
        ),
      ]
    },
    []
  )
}
