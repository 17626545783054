import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  underAttack: string
  endDanger: string
  land: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    underAttack: 'is under Matematrolls attack! Your home planet!',
    endDanger: 'End now the danger of Matematroli over the galaxy',
    land: 'Land on',
  },
  [languageCodes.ro_RO]: {
    underAttack: 'este sub atacul Matematrolilor! Planeta ta!',
    endDanger: 'Încheie acum pericolul Matematrolilor asupra galaxiei',
    land: 'Aterizează pe',
  },
}

export default localeData
