import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  saveButton: string
  showGame: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    saveButton: 'Save',
    showGame: 'Show Game'
  },
  [languageCodes.ro_RO]: {
    saveButton: 'Salvează',
    showGame: 'Arată Jocul'
  },
}

export default localeData
