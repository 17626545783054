import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  title: string
  thePlanet: string
  message: string
  button: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    title: "You Won!",
    thePlanet: "Your planet",
    message: "is saved! The Matematrols will no longer be a threat to our galaxy",
    button: "Continue to credits"
  },
  [languageCodes.ro_RO]: {
    title: "Ai câștigat!",
    thePlanet: "Planeta ta",
    message: "este salvată! Matematrolii nu vor mai fi o amenințare galaxiei noastre",
    button: "Continuă la credite"
  },
}

export default localeData
