import * as THREE from 'three'
// @ts-ignore
import * as TWEEN from 'es6-tween'
import { getRandomRange } from '../../../../../core/utils/getRandomRange'

class SmokeParticle {
  geometry: THREE.Geometry = new THREE.IcosahedronGeometry(75, 1)
  material: THREE.Material = new THREE.MeshLambertMaterial({
    color: 'grey',
    opacity: 0.5,
    flatShading: false,
    transparent: true,
  })
  mesh: THREE.Mesh = new THREE.Mesh(this.geometry, this.material)

  constructor(resetSmoke: (smoke: SmokeParticle) => void) {
    resetSmoke(this)
  }
}

export function RocketSmoke(scene: THREE.Scene) {
  const frequency = 5
  let freqCount = 0
  const smokeRecycle: SmokeParticle[] = []
  let worldCoords: THREE.Vector3 | null = null

  function setSmokeCoords(mesh: THREE.Mesh, geometry: THREE.Geometry) {
    if (mesh && geometry) {
      geometry.computeBoundingBox()
      const boundingBox = geometry.boundingBox

      if (boundingBox) {
        worldCoords = new THREE.Vector3()
        worldCoords.subVectors(boundingBox.max, boundingBox.min)
        worldCoords.multiplyScalar(0.5)
        worldCoords.add(boundingBox.min)
        worldCoords.applyMatrix4(mesh.matrixWorld)
      }
    }
  }

  function resetSmoke(smoke: SmokeParticle) {
    smoke.mesh.position.x = 0
    smoke.mesh.position.y = 0
    smoke.mesh.position.z = 0
    smoke.mesh.rotation.x = Math.random() * Math.PI * 2
    smoke.mesh.rotation.y = Math.random() * Math.PI * 2
    smoke.mesh.rotation.z = Math.random() * Math.PI * 2
    smoke.mesh.scale.set(0.1, 0.1, 0.1)
    smoke.material.opacity = 0
    smoke.material.needsUpdate = true
    scene.add(smoke.mesh)
    smokeRecycle.push(smoke)
  }

  function dropSmoke(smoke: SmokeParticle) {
    smoke.material.opacity = 1
    if (worldCoords) {
      smoke.mesh.position.x = worldCoords.x
      smoke.mesh.position.y = worldCoords.y
      smoke.mesh.position.z = worldCoords.z
      smoke.mesh.scale.set(0.001, 0.001, 0.001)
    }
    const maxSize = getRandomRange(0.05, 0.1)
    const minSize = getRandomRange(0.01, 0.03)
    const tweenSmokeEnter = new TWEEN.Tween(smoke.mesh.scale)
      .to(
        {
          x: maxSize,
          y: maxSize,
          z: maxSize,
        },
        getRandomRange(100, 300)
      )
      .easing(TWEEN.Easing.Quadratic.Out)
      //.on('update', this._updateMesh)
      .on('complete', () => {
        TWEEN.remove(tweenSmokeEnter)
        const tweenSmokeLeave = new TWEEN.Tween(smoke.mesh.scale)
          .to(
            {
              x: minSize,
              y: minSize,
              z: minSize,
            },
            getRandomRange(500, 1000)
          )
          .easing(TWEEN.Easing.Quadratic.In)
          //.on('update', this._updateMesh)
          .on('complete', () => {
            TWEEN.remove(tweenSmokeLeave)
            resetSmoke(smoke)
          })
          .start()
      })
      .start()
  }

  function getSmokeParticle() {
    if (smokeRecycle.length) {
      return smokeRecycle.pop()
    } else {
      return new SmokeParticle(resetSmoke)
    }
  }

  function createDroppingWaste() {
    const smoke = getSmokeParticle()
    if (smoke) {
      dropSmoke(smoke)
    }
  }

  function updateSmokeArr() {
    if (freqCount % frequency === 0) {
      createDroppingWaste()
    }
    freqCount++
  }

  return {
    setSmokeCoords, //before rocket
    updateSmokeArr, // after rocket
  }
}
