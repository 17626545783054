import React, { useState, useEffect } from 'react'
import { CheckBox } from '../../atoms/checkbox'
import { Image } from '../../atoms/image'
import { OptionTypes, Options } from '../../../core/@types/exercise'
import cx from 'classnames'
import styles from './problem.module.scss'

type ISelectionState = {
  [key in OptionTypes]: boolean
}

interface IProps {
  id: string
  className?: string
  onSelect(checked: boolean, value: string): void
  editor: string
  solution: string
  imageSolution: string
  optionOne: string
  imageOptionOne: string
  optionTwo: string
  imageOptionTwo: string
  optionThree: string
  imageOptionThree: string
  optionFour: string
  imageOptionFour: string
}

/**
 * @function Problem
 * @param editor
 * @param optionOne,
 * @param optionTwo,
 * @param optionThree,
 * @param optionFour,
 * @param solution,
 * @param onSelect
 */
export function Problem({
  id,
  onSelect,
  className,
  ...attr
}: IProps): JSX.Element {
  const [selections, setSelections] = useState<ISelectionState>({
    [Options.OPTION_ONE]: false,
    [Options.IMAGE_OPTION_ONE]: false,
    [Options.OPTION_TWO]: false,
    [Options.IMAGE_OPTION_TWO]: false,
    [Options.OPTION_THREE]: false,
    [Options.IMAGE_OPTION_THREE]: false,
    [Options.OPTION_FOUR]: false,
    [Options.IMAGE_OPTION_FOUR]: false,
    [Options.OPTION_SOLUTION]: false,
    [Options.IMAGE_OPTION_SOLUTION]: false,
  })
  const [currentOption, setCurrentOption] = useState<OptionTypes | null>(null)

  function onHandleSelect(option: OptionTypes, value: string) {
    const trimValue = value.replace(/\s/g, '')
    const solution = attr[Options.OPTION_SOLUTION] || attr[Options.IMAGE_OPTION_SOLUTION] || '';
    const trimSolution = solution.replace(/\s/g, '')

    if (!selections[option]) {
      onSelect(trimValue === trimSolution, trimValue)
      setSelections({
        ...selections,
        [option]: true,
      })
    }
    setCurrentOption(option)
  }

  useEffect(() => {
    setSelections({
      [Options.OPTION_ONE]: false,
      [Options.IMAGE_OPTION_ONE]: false,
      [Options.OPTION_TWO]: false,
      [Options.IMAGE_OPTION_TWO]: false,
      [Options.OPTION_THREE]: false,
      [Options.IMAGE_OPTION_THREE]: false,
      [Options.OPTION_FOUR]: false,
      [Options.IMAGE_OPTION_FOUR]: false,
      [Options.OPTION_SOLUTION]: false,
      [Options.IMAGE_OPTION_SOLUTION]: false,
    })
    setCurrentOption(null)
  }, [id])

  function renderOption({
    option,
    isImage,
  }: {
    option: OptionTypes
    isImage: boolean
  }): JSX.Element | null {
    const isValid =
      currentOption === option &&
      (attr[option] === attr[Options.OPTION_SOLUTION] ||
        attr[option] === attr[Options.IMAGE_OPTION_SOLUTION])
    const isInvalid =
      currentOption === option &&
      attr[option] !== attr[Options.OPTION_SOLUTION] &&
      attr[option] !== attr[Options.IMAGE_OPTION_SOLUTION]
    return attr[option] ? (
      <div className={`${styles.optionWrapper} px-3`}>
        <CheckBox
          isSelected={currentOption === option}
          checked={selections[option]}
          name={option}
          hasValidation={true}
          disabled={false}
          valid={isValid}
          invalid={isInvalid}
          onChange={() => onHandleSelect(option, attr[option])}
        >
          {isImage ? (
            <Image src={attr[option]} alt="option" title="option" />
          ) : (
            attr[option]
          )}
        </CheckBox>
      </div>
    ) : null
  }

  return (
    <div className={`${cx(styles.wrapper, className)} p-3`}>
      <div
        className={`${styles.problem} p-3 d-flex justify-content-center align-items-center`}
        dangerouslySetInnerHTML={{ __html: attr.editor }}
      ></div>
      <div className={`${styles.options} p-3 d-flex justify-content-between`}>
        {renderOption({
          option: Options.OPTION_ONE,
          isImage: false,
        })}
        {renderOption({
          option: Options.IMAGE_OPTION_ONE,
          isImage: true,
        })}
        {renderOption({
          option: Options.OPTION_TWO,
          isImage: false,
        })}
        {renderOption({
          option: Options.IMAGE_OPTION_TWO,
          isImage: true,
        })}
        {renderOption({
          option: Options.OPTION_THREE,
          isImage: false,
        })}
        {renderOption({
          option: Options.IMAGE_OPTION_THREE,
          isImage: true,
        })}
        {renderOption({
          option: Options.OPTION_FOUR,
          isImage: false,
        })}
        {renderOption({
          option: Options.IMAGE_OPTION_FOUR,
          isImage: true,
        })}
      </div>
    </div>
  )
}
