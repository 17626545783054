import { useContext, useEffect, useRef, useState } from 'react'
import { GeneralContext } from '../../core/context/generalContext'
import { useHistory } from 'react-router-dom'
import locales from './lib/locales'
import { Redirect } from 'react-router-dom'
import { CREDITS, MISSIONS } from '../../core/constants/routes'
import { Canvas } from '../../components/atoms/canvas'
import { LoadingRocket } from '../../components/atoms/loading-rocket'
import { World } from '@matematrolii/sketchbook'
import { MATEMATROL_ICON } from '../../core/constants/resources'
import { Model32 } from '@carbon/icons-react'
import { Instructions } from './components/instructions'
import { CompleteModal } from './components/complete-modal'
import { CompleteFinalModal } from './components/complete-final-modal'
import { FailModal } from './components/fail-modal'
import styles from './game.module.scss'
import { IGame, Missions } from '../../core/@types/game'
import { getMissionData } from './getMissionData'
import { pauseGame } from '../../core/utils/gameActions'
import { MATEMATROLII_EVENT } from '../../core/constants/game'
import { getStorage } from '../../core/storage'
import { MATEMATROLII_DEV } from '../../core/constants/general'
import { DevOptions } from '../../core/@types/general'
import { Image } from '../../components/atoms/image'

const devStorage: DevOptions = getStorage(MATEMATROLII_DEV)

export function Game() {
  const history = useHistory()
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const world = useRef<any>(null)
  const [gameStats, setGameStats] = useState<{
    enemies: number | null
    items: number
    player: number
    initialised: boolean
  }>({
    enemies: null,
    items: 0,
    player: 1,
    initialised: false,
  })
  const currrentGameStatsRef = useRef(gameStats)
  const { gameInfo, languageCode, updateGameInfo, currentMission } =
    useContext(GeneralContext)
  const locale = locales[languageCode]
  const game = gameInfo?.user?.extrauser?.game
  const hasFailed = !!currentMission && gameStats.player === 0
  const hasCompleted = !!currentMission && gameStats.enemies === 0
  const isLastMission = currentMission === Missions.TERRA

  useEffect(() => {
    if (!world.current && canvasRef.current && currentMission && game?.hero && game?.rocket) {
      for (var i = 1; i < 99999; i++) {
        window.clearInterval(i);
        (window as any).cancelRequestAnimFrame(i);
      }
      const missionData = getMissionData(
        currentMission,
        game?.hero,
        game?.rocket
      )
      world.current = new World(
        missionData,
        {
          Pointer_Lock: true,
          Shadows: true,
          Debug_FPS: devStorage && devStorage?.fps,
          FXAA: false,
        },
        canvasRef.current
      )
    }
  }, [currentMission, game?.rocket, game?.hero])

  useEffect(() => {
    function handleGameEvent(ev: any) {
      if (ev.detail) {
        const type = ev.detail.type
        const targets = ev.detail.targets
        switch (type) {
          case 'enemies':
            currrentGameStatsRef.current = {
              ...currrentGameStatsRef.current,
              enemies: targets && targets.length > 0 ? parseInt(targets[0]) : 0,
            }
            setGameStats({
              ...currrentGameStatsRef.current,
            })
            break
          case 'enemy':
            currrentGameStatsRef.current = {
              ...currrentGameStatsRef.current,
              enemies: currrentGameStatsRef.current.enemies
                ? currrentGameStatsRef.current.enemies - 1
                : null,
            }
            setGameStats({
              ...currrentGameStatsRef.current,
            })
            break
          case 'item':
            currrentGameStatsRef.current = {
              ...currrentGameStatsRef.current,
              items: currrentGameStatsRef.current.items + 1,
            }
            setGameStats({
              ...currrentGameStatsRef.current,
            })
            break
          case 'player':
            currrentGameStatsRef.current = {
              ...currrentGameStatsRef.current,
              player: 0,
            }
            setGameStats({
              ...currrentGameStatsRef.current,
            })
            break
          case 'initialise':
            currrentGameStatsRef.current = {
              ...currrentGameStatsRef.current,
              initialised: true,
            }
            setGameStats({
              ...currrentGameStatsRef.current,
              initialised: true,
            })
            break
          case 'missing-world':
            break
        }
      }
    }
    window.document.removeEventListener(MATEMATROLII_EVENT, handleGameEvent)
    window.document.addEventListener(MATEMATROLII_EVENT, handleGameEvent, false)

    return () => {
      window.document.removeEventListener(
        MATEMATROLII_EVENT,
        handleGameEvent,
        false
      )
    }
  }, [])

  useEffect(() => {
    if (hasCompleted || hasFailed) {
      pauseGame()
    }
  }, [hasCompleted, hasFailed])

  if (!currentMission) {
    return <Redirect to={MISSIONS} />
  }

  async function redirectToMissions() {
    setIsRedirecting(true)
    if (game) {
      try {
        if (world.current) {
          world.current.destroy();
        }
        await updateGameInfo({
          ...game,
          currentMission,
          gold: gameStats.player === 1 ? gameStats.items : game.gold,
        } as IGame)
        history.push(MISSIONS)
      } catch (err) {
        console.error('There was an error while updating game missions', err)
      }
    }
  }

  async function redirectToCredits() {
    setIsRedirecting(true)
    if (game && currentMission === Missions.TERRA) {
      try {
        if (world.current) {
          world.current.destroy();
        }
        await updateGameInfo({
          ...game,
          currentMission,
          missions: [...game.missions, currentMission],
          gold: gameStats.player === 1 ? gameStats.items : game.gold,
        } as IGame)
        history.push(CREDITS)
      } catch (err) {
        console.error('There was an error while updating game missions', err)
      }
    }
  }

  return (
    <>
      <Canvas ref={canvasRef} />
      <div
        className={`${styles.board} d-flex justify-content-center align-items-center`}
      >
        {!gameStats.initialised && (
          <div className={styles.loadingScreen}>
            <LoadingRocket message={`${locale.landing} ${currentMission}`} />
          </div>
        )}
        {isRedirecting && (
          <div className={styles.loadingScreen}>
            <LoadingRocket message={`${locale.leaving} ${currentMission}`} />
          </div>
        )}
        {currentMission && !isLastMission && (
          <CompleteModal
            onClick={redirectToMissions}
            planet={currentMission}
            visible={hasCompleted && !isRedirecting}
          />
        )}
        {currentMission && isLastMission && (
          <CompleteFinalModal
            onClick={redirectToCredits}
            planet={currentMission}
            visible={hasCompleted && !isRedirecting}
          />
        )}
        <FailModal
          onClick={redirectToMissions}
          visible={hasFailed && !isRedirecting}
        />
        <div className={`${styles.stats} container-fluid`}>
          <div className="row">
            <div className="col-3 col-lg-2 col-xl-1 d-flex align-items-center justify-content-center">
              <Instructions initialised={gameStats.initialised} />
            </div>
            <div
              className={`${styles.enemy} col-3 col-lg-2 col-xl-1 d-flex align-items-center justify-content-center`}
            >
              <span>{gameStats.enemies}</span>
              <Image src={MATEMATROL_ICON} alt="enemy" loadingSize={60} />
            </div>
            <div
              className={`${styles.item} col-3 col-lg-2 col-xl-1 d-flex align-items-center justify-content-center`}
            >
              <span>{gameStats.items}</span>
              <Model32 />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
