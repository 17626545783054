import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { GameHeader } from '../../components/atoms/game-header'
import { Carousel } from '../../components/atoms/carousel'
import { Button } from '../../components/atoms/button'
import { GeneralContext } from '../../core/context/generalContext'
import { PROFILE } from '../../core/constants/routes'
import { Image } from '../../components/atoms/image'
import {
  STORY_SCENE_1,
  STORY_SCENE_2,
  STORY_SCENE_3,
} from '../../core/constants/resources'
import { Book32 } from '@carbon/icons-react'
import locales from './lib/locales'
import styles from './story.module.scss'

/**
 * @function Story
 */
export function Story(): JSX.Element {
  const history = useHistory()
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const images = [STORY_SCENE_1, STORY_SCENE_2, STORY_SCENE_3]

  function renderSlides(): React.ReactNode[] {
    return images?.map((image: string, index: number) => {
      return (
        <Image
          key={index}
          src={image}
          alt="story"
          title="story"
          onSuccess={() => {}}
        />
      )
    })
  }

  return (
    <div className={styles.story}>
      <GameHeader />
      <div className={styles.wrapper}>
        <Carousel
          hasArrows
          slides={renderSlides()}
          onEnd={() => history.push(PROFILE)}
          onChange={() => {}}
          currentSlide={0}
        />
      </div>
      <div className="row justify-content-center">
        <Button
          type="button"
          url={PROFILE}
          title={
            <>
              <Book32 />
              <span>{locale?.skipButton}</span>
            </>
          }
        />
      </div>
    </div>
  )
}
