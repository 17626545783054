import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  landing: string
  leaving: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    landing: "Landing on planet",
    leaving: "Leaving planet"
  },
  [languageCodes.ro_RO]: {
    landing: "Aterizare pe planeta",
    leaving: "Părăsește planeta"
  },
}

export default localeData
