import { useContext, useEffect, useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link } from 'react-router-dom'
import { AdminButton } from '../../components/atoms/admin-button'
import { ErrorBox } from '../../components/atoms/error-box'
import { Empty } from '../../components/atoms/empty'
import { Title } from '../../components/atoms/title'
import { InputField } from '../../components/atoms/input-field'
import { GeneralContext } from '../../core/context/generalContext'
import { AdminHeader } from '../../components/atoms/admin-header'
import { getExercise } from '../../core/api/graphql/queries/exercise'
import { updateExercise } from '../../core/api/graphql/mutations/exercise'
import { MediaPicker } from '../../components/molecule/media-picker'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Dropdown } from '../../components/atoms/dropdown'
import { EXERCISES_VIEW } from '../../core/constants/routes'
import { Notebook32, Add16, View16, TrashCan16 } from '@carbon/icons-react'
import { Editor } from '@tinymce/tinymce-react'
import { IExercise, IExerciseImage } from '../../core/@types/exercise'
import locales, { ILocaleProps } from './lib/locales'
import { generateErrors } from '../../core/utils/generateErrors'
import styles from './exercise-edit.module.scss'
import {
  HelperTypes,
  Helper,
  OptionValue,
  OptionValueType,
} from '../../core/@types/exercise'
import { FETCH_POLICY } from '../../core/constants/general'

export function ExerciseEdit() {
  // router param for a specific exercise id
  const params = useParams<{ id: string }>()
  const [valueType, setValueType] = useState<OptionValueType>(OptionValue.TEXT)
  const hasTextType = valueType === OptionValue.TEXT
  const {
    data: exerciseData,
    loading: exerciseLoading,
    error: exerciseError,
  } = useQuery(getExercise, {
    variables: {
      id: params.id,
    },
    fetchPolicy: FETCH_POLICY,
  })
  const [handleUpdateExercise, { error: updateError, loading: updateLoading }] =
    useMutation(updateExercise)
  const errorsMessages = useMemo(() => {
    const exerciseErrors = generateErrors(exerciseError) || []
    const updateErrors = generateErrors(updateError) || []
    return [...exerciseErrors, ...updateErrors]
  }, [exerciseError, updateError])
  // form controls and hooks
  const { register, handleSubmit, errors, control, reset } = useForm({
    shouldUnregister: true
  })
  // arrays to control the images list of helpers
  const [helperOneIndexes, setHelperOneIndexes] = useState<number[]>([])
  const [helperTwoIndexes, setHelperTwoIndexes] = useState<number[]>([])
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]

  const handleValueType = (value: OptionValueType) => {
    setValueType(value)
  }

  async function onSubmit(data: IExercise) {
    try {
      await handleUpdateExercise({
        variables: {
          id: params?.id,
          title: data?.title,
          properties: data?.properties,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (exerciseData) {
      const exercise = exerciseData?.exercise
      const hasOptionValueText = exercise?.properties?.optionOne && exercise?.properties?.optionTwo && exercise?.properties?.optionThree && exercise?.properties?.optionFour && exercise?.properties?.solution
      setValueType(hasOptionValueText ? OptionValue.TEXT : OptionValue.IMAGES)
      reset(exercise)
      setHelperOneIndexes(
        exercise?.properties?.helperOne?.images?.map(
          (image: IExerciseImage, index: number) => index
        ) || []
      )
      setHelperTwoIndexes(
        exercise?.properties?.helperTwo?.images?.map(
          (image: IExerciseImage, index: number) => index
        ) || []
      )
    }
  }, [exerciseData, reset])

  function renderImage(
    helper: HelperTypes,
    indexItem: number,
    onRemove: () => void
  ) {
    return (
      <div className={`${styles.imageWrapper} my-3 p-3 pt-5`} key={indexItem}>
        <Controller
          control={control}
          name={`properties.${helper}.images[${indexItem}].url`}
          render={({ onChange, value }) => (
            <MediaPicker
              value={value}
              scope="image"
              onChange={(url) => onChange(url)}
              size="large"
            />
          )}
        />
        <InputField
          className={`${styles.input} mt-3`}
          name={`properties.${helper}.images[${indexItem}].startAt`}
          type="number"
          ref={register()}
          label={'Start At'}
        />
        <div className={styles.removeImage} onClick={onRemove}>
          <TrashCan16 />
        </div>
      </div>
    )
  }

  function renderForm() {
    return (
      <form
        className={`${styles.form} container p-3 py-4`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row mb-3">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <Title
              className="mr-3"
              headingLevel={3}
              title={locale?.exerciseTitle}
            />
            <Link
              className={`${styles.redirectButton} d-flex align-items-center`}
              to={`${EXERCISES_VIEW}/${params?.id}`}
            >
              <View16 />
              <span>{locale?.viewExercise}</span>
            </Link>
          </div>
        </div>
        <div className="row mb-3">
          {errorsMessages?.map((messageCode: string, index: number) => (
            <ErrorBox key={`error-${index}`}>{messageCode}</ErrorBox>
          ))}
          <div className="col-5">
            <InputField
              className={`${styles.input} mb-3`}
              name="name"
              type="text"
              ref={register()}
              disabled
              label={locale?.labelName}
            />
            <InputField
              className={`${styles.input} mb-3`}
              name="title"
              type="text"
              ref={register({
                required: {
                  value: true,
                  message: locale?.requiredTitle,
                },
              })}
              placeholder={locale.titlePlaceholder}
              error={errors?.title?.message}
              label={locale?.labelTitle}
              hasValidation
            />
            <div className="mb-3">
              <label className={styles.label}>{locale?.labelExercise}</label>
              <Controller
                control={control}
                name="properties.editor"
                render={({ onChange, value }) => (
                  <Editor
                    apiKey="gufuk10ix4d4njfbwv4h3nyhij01n2tlt9qfsuwad3uvmuhu"
                    value={value}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: ['advlist lists image charmap'],
                      toolbar:
                        'formatselect bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image charmap removeformat',
                      a11y_advanced_options: true,
                      image_caption: true,
                      image_description: false,
                      image_uploadtab: false,
                      images_file_types: 'jpg,png,webp',
                    }}
                    onEditorChange={(content) => onChange(content)}
                  />
                )}
              />
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <Dropdown
                  items={[
                    {
                      value: OptionValue.TEXT,
                      title: locale?.text,
                    },
                    {
                      value: OptionValue.IMAGES,
                      title: locale?.images,
                    },
                  ]}
                  value={valueType}
                  onChange={handleValueType}
                  placeholder={locale?.placeholderValueType}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                {hasTextType && (
                  <InputField
                    className={`${styles.input} mb-3`}
                    name="properties.optionOne"
                    type="text"
                    ref={register()}
                    error={errors?.properties?.optionOne?.message}
                    hasValidation
                    label={`${locale?.labelOption} 1`}
                  />
                )}
                {!hasTextType && (
                  <Controller
                    control={control}
                    name="properties.imageOptionOne"
                    render={({ onChange, value }) => (
                      <MediaPicker
                        value={value}
                        scope="image"
                        size="small"
                        onChange={(url) => onChange(url)}
                      />
                    )}
                  />
                )}
              </div>
              <div className="col-3">
                {hasTextType && (
                  <InputField
                    className={`${styles.input} mb-3`}
                    name="properties.optionTwo"
                    type="text"
                    ref={register()}
                    error={errors?.properties?.optionTwo?.message}
                    hasValidation
                    label={`${locale?.labelOption} 2`}
                  />
                )}
                {!hasTextType && (
                  <Controller
                    control={control}
                    name="properties.imageOptionTwo"
                    render={({ onChange, value }) => (
                      <MediaPicker
                        value={value}
                        scope="image"
                        size="small"
                        onChange={(url) => onChange(url)}
                      />
                    )}
                  />
                )}
              </div>
              <div className="col-3">
                {hasTextType && (
                  <InputField
                    className={`${styles.input} mb-3`}
                    name="properties.optionThree"
                    type="text"
                    ref={register()}
                    error={errors?.properties?.optionThree?.message}
                    hasValidation
                    label={`${locale?.labelOption} 3`}
                  />
                )}
                {!hasTextType && (
                  <Controller
                    control={control}
                    name="properties.imageOptionThree"
                    render={({ onChange, value }) => (
                      <MediaPicker
                        value={value}
                        scope="image"
                        size="small"
                        onChange={(url) => onChange(url)}
                      />
                    )}
                  />
                )}
              </div>
              <div className="col-3">
                {hasTextType && (
                  <InputField
                    className={`${styles.input} mb-3`}
                    name="properties.optionFour"
                    type="text"
                    ref={register()}
                    error={errors?.properties?.optionFour?.message}
                    hasValidation
                    label={`${locale?.labelOption} 4`}
                  />
                )}
                {!hasTextType && (
                  <Controller
                    control={control}
                    name="properties.imageOptionFour"
                    render={({ onChange, value }) => (
                      <MediaPicker
                        value={value}
                        scope="image"
                        size="small"
                        onChange={(url) => onChange(url)}
                      />
                    )}
                  />
                )}
              </div>
              <div className="col-3 mt-3">
                {hasTextType && (
                  <InputField
                    className={`${styles.input} mb-3`}
                    name="properties.solution"
                    type="text"
                    ref={register()}
                    error={errors?.properties?.solution?.message}
                    hasValidation
                    label={locale?.labelSolution}
                  />
                )}
                {!hasTextType && (
                  <Controller
                    control={control}
                    name="properties.imageSolution"
                    render={({ onChange, value }) => (
                      <MediaPicker
                        value={value}
                        scope="image"
                        size="small"
                        onChange={(url) => onChange(url)}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-7">
            <div className="row">
              <div className="col-6">
                <div className="d-flex justify-content-center">
                  <Title headingLevel={4} title={locale?.helperOne} />
                </div>
                <Controller
                  control={control}
                  name="properties.helperOne.audio"
                  render={({ onChange, value }) => (
                    <MediaPicker
                      value={value}
                      scope="audio"
                      size="large"
                      onChange={(url) => onChange(url)}
                    />
                  )}
                />
                <div className="image-list mb-3">
                  {helperOneIndexes?.map((indexItem: number) =>
                    renderImage(Helper.HELPER_ONE, indexItem, () => {
                      const list = [...helperOneIndexes]
                      list.splice(indexItem, 1)
                      setHelperOneIndexes(list)
                    })
                  )}
                </div>
                <AdminButton
                  type="button"
                  className="d-flex align-items-center justify-content-center"
                  onClick={() =>
                    setHelperOneIndexes([
                      ...helperOneIndexes,
                      helperOneIndexes.length,
                    ])
                  }
                >
                  <Add16 />
                  <span>{locale?.addImageButton}</span>
                </AdminButton>
              </div>
              <div className="col-6">
                <div className="d-flex justify-content-center">
                  <Title headingLevel={4} title={locale?.helperTwo} />
                </div>
                <Controller
                  control={control}
                  name="properties.helperTwo.audio"
                  render={({ onChange, value }) => (
                    <MediaPicker
                      value={value}
                      scope="audio"
                      size="large"
                      onChange={(url) => onChange(url)}
                    />
                  )}
                />
                <div className="image-list mb-3">
                  {helperTwoIndexes?.map((indexItem: number) =>
                    renderImage(Helper.HELPER_TWO, indexItem, () => {
                      const list = [...helperTwoIndexes]
                      list.splice(indexItem, 1)
                      setHelperTwoIndexes(list)
                    })
                  )}
                </div>
                <AdminButton
                  type="button"
                  className="d-flex align-items-center justify-content-center"
                  onClick={() =>
                    setHelperTwoIndexes([
                      ...helperTwoIndexes,
                      helperTwoIndexes.length,
                    ])
                  }
                >
                  <Add16 />
                  <span>{locale?.addImageButton}</span>
                </AdminButton>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="offset-4 col-4">
            <AdminButton type="submit" isLoading={updateLoading}>
              {locale?.updateButton}
            </AdminButton>
          </div>
        </div>
      </form>
    )
  }

  return (
    <div className={styles.exerciseEdit}>
      <AdminHeader />
      {exerciseLoading ? (
        <div className="py-5">
          <Empty>
            <Notebook32 />
            <span>{locale?.loadingExercise}</span>
          </Empty>
        </div>
      ) : (
        renderForm()
      )}
    </div>
  )
}
