import { languageCodes } from '../../../../core/@types/general'

export interface ILocaleProps {
  language: string
  romanian: string
  english: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    language: 'Language',
    romanian: 'Romania',
    english: 'English',
  },
  [languageCodes.ro_RO]: {
    language: 'Limba',
    romanian: 'Română',
    english: 'Engleză',
  },
}

export default localeData
