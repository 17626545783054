import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  homeButton: string
  requiredEmail: string
  requiredPassword: string
  emailFormat: string
  passwordFormat: string
  emailPlaceholder: string
  passwordPlaceholder: string
  passwordLength: string
  loginButton: string
  loadingButton: string
  passwordLabel: string
  emailLabel: string
  ['Auth.form.error.invalid']: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    homeButton: 'Back to homepage',
    requiredEmail: 'Email is required for login',
    requiredPassword: 'Password is required for login',
    emailFormat: 'Entered value does not match email format',
    passwordFormat: 'Password must contain only numbers and letters',
    emailPlaceholder: 'Enter your email',
    passwordPlaceholder: 'Enter your password',
    passwordLength: 'Password must contain at least 8 characters',
    loginButton: 'Login',
    loadingButton: 'Loading',
    'Auth.form.error.invalid': 'Identifier or password invalid.',
    emailLabel: "Email",
    passwordLabel: "Password"
  },
  [languageCodes.ro_RO]: {
    homeButton: 'Înapoi la pagina home',
    requiredEmail: 'E-mail este necesar pentru autentificare',
    requiredPassword: 'Parola este necesară pentru autentificare',
    emailFormat: 'Formatul introdus nu corespunde unui e-mail',
    passwordFormat: 'Parola trebuie sa conțină numai litere și cifre',
    emailPlaceholder: 'Introduce e-mailul',
    passwordPlaceholder: 'Introduce parola',
    passwordLength: 'Parola trebuie să conțină cel puțin 8 caractere',
    loginButton: 'Autentificare',
    loadingButton: 'Se Încarcă',
    'Auth.form.error.invalid': 'Utilizatorul sau parola sunt invalide.',
    emailLabel: "E-mail",
    passwordLabel: "Parolă"
  },
}

export default localeData
