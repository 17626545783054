import cx from 'classnames'
import { Error32 } from '@carbon/icons-react'
import styles from './error-box.module.scss'

interface IProps {
  className?: string
  children?: string | undefined
}

/**
 * @function ErrorBox
 * @param children
 * @param className
 */
export function ErrorBox({ children, className }: IProps): JSX.Element {
  return (
    <div
      className={`${cx(
        styles.error,
        className
      )} d-flex align-items-center justify-content-center`}
    >
      <Error32 />
      <span>{children}</span>
    </div>
  )
}
