import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  errorExercise: string
  loadingExercise: string,
  tryAgain: string,
  noExercise: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    errorExercise: 'There was an error when fetching the exercise properties',
    loadingExercise: 'Loading exercise model...',
    tryAgain: 'Try Again',
    noExercise: 'There is no exercise available'
  },
  [languageCodes.ro_RO]: {
    errorExercise: 'A fost o eroare în timpul primirii proprietăților exercițiului',
    loadingExercise: 'Încărcare model exercițiu...',
    tryAgain: 'Încearcă din nou',
    noExercise: 'Nu exista un exercițiu disponibil'
  },
}

export default localeData
