import { ReactNode } from 'react'
import { AdminButton } from '../admin-button'
import { Close24 } from '@carbon/icons-react'
import cx from 'classnames'
import styles from './modal.module.scss'

export interface IProps {
  title: string | undefined
  submitText?: string | JSX.Element | JSX.Element[]
  cancelText?: string | JSX.Element | JSX.Element[]
  onSubmit?(): void
  onCancel?(): void
  children: ReactNode
  isVisible: boolean
  className?: string
}

export function Modal({
  title,
  submitText,
  cancelText,
  isVisible,
  onSubmit,
  onCancel,
  children,
  className,
}: IProps) {
  const hasSubmitButton = submitText && onSubmit
  const hasCancelButton = cancelText && onCancel
  const hasModalFooter = hasSubmitButton || hasCancelButton
  return (
    <div
      className={`align-items-center justify-content-center ${cx(
        styles.modalWrapper,
        className,
        {
          [styles.modalWrapperVisible]: isVisible,
        }
      )}`}
      role="dialog"
    >
      <div className={styles.modalContainer}>
        <div
          className={`${styles.modalHeader} d-flex align-items-center justify-content-between p-3`}
        >
          {title}
          <div className={styles.closeButton} onClick={onCancel}>
            <Close24 />
          </div>
        </div>
        <div className={`${styles.modalBodyWrapper} p-3`}>
          <div className={styles.modalBody}>{children}</div>
        </div>
        {hasModalFooter && (
          <div
            className={`${styles.modalFooter} d-flex align-items-center justify-content-end p-3`}
          >
            {hasSubmitButton && (
              <AdminButton type="button" onClick={onSubmit}>
                {submitText || ''}
              </AdminButton>
            )}
            {hasCancelButton && (
              <AdminButton type="button" onClick={onCancel}>
                {cancelText || ''}
              </AdminButton>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
