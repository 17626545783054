import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// @ts-ignore
import * as TWEEN from 'es6-tween'
import { calculateDistance } from '../../../../../core/utils/calculateDistance'
import { Howl } from 'howler'
import { RocketSmoke } from './rocket-smoke'
import { IOptionRocket } from '../../../../../core/@types/game'
import { getRandomRange } from '../../../../../core/utils/getRandomRange'
import { ROCKET_SOUND } from '../../../../../core/constants/resources'

const objectLoader = new GLTFLoader()

export class Rocket {
  rocketBody: THREE.Group | null = new THREE.Group()
  scene: THREE.Scene | null = null
  camera: THREE.Camera | null = null
  options: IOptionRocket | null = null
  spline: THREE.CatmullRomCurve3 | null = null
  dir: THREE.Vector3 | null = new THREE.Vector3(1, 0, 0)
  axis: THREE.Vector3 | null = new THREE.Vector3()
  rocketFuelGeometry: THREE.BoxGeometry | null = new THREE.BoxGeometry(
    0.1,
    0.1,
    0.1
  )
  rocketFuelMesh: THREE.Mesh | null =
    this.rocketFuelGeometry &&
    new THREE.Mesh(
      this.rocketFuelGeometry,
      new THREE.MeshBasicMaterial({ color: 0x000000, wireframe: true })
    )
  rocketSmoke: any = null
  rocketSound: Howl | null = null
  time = { t: 0 }
  tween = null
  speed = 1

  isMoving = false

  constructor(
    scene: THREE.Scene | null,
    camera: THREE.Camera | null,
    options: IOptionRocket
  ) {
    this.scene = scene
    this.camera = camera
    this.options = options
  }

  load(): Promise<Rocket> {
    return new Promise((resolve, reject) => {
      if (this.options) {
        objectLoader.load(this.options.modelUrl || '', (gltf) => {
          const mesh = gltf.scene
          const image = new Image() // or document.createElement('img' );
          image.crossOrigin = 'Anonymous'
          // Create texture
          const texture = new THREE.Texture(image)
          // On image load, update texture
          image.onload = () => {
            texture.needsUpdate = true
          }
          // Set image source/
          image.src = this.options?.textureUrl || ''
          texture.flipY = false
          const material = new THREE.MeshPhongMaterial({
            map: texture,
            reflectivity: 0.1,
          })
          mesh.traverse((node: any) => {
            if (node.isMesh) {
              // for a multi-material mesh, `o.material` may be an array,
              // in which case you'd need to set `.map` on each value.
              if (node.material.isGLTFSpecularGlossinessMaterial) {
                node.onBeforeRender = function () {}
              }
              node.material = material
            }
          })
          this.rocketBody?.add(mesh)
          const scale = this.options?.scale || 1
          const x = this.options?.x || 0
          const y = this.options?.y || 0

          this.rocketBody?.scale.set(scale, scale, scale)
          this.rocketBody?.position.set(x, y, 50)

          //this.camera?.lookAt(this.rocketBody.position);
          // return rocket on promise

          if (this.rocketFuelMesh) {
            this.rocketFuelMesh.position.x = -5
            this.rocketBody?.add(this.rocketFuelMesh)
          }

          if (this.scene && this.rocketBody) {
            this.scene.add(this.rocketBody)
            this.rocketSmoke = RocketSmoke(this.scene)
          }

          this.rocketSound = new Howl({
            src: [ROCKET_SOUND],
            html5: true,
            autoplay: false,
            loop: true,
            onload: () => {
              this.rocketSound?.stop()
              resolve(this)
            },
            format: ['mp3'],
          })
        })
      } else {
        reject(new Error('Rocket is missing options configurations'))
      }
    })
  }

  updateMesh() {
    if (this.rocketSmoke && this.spline && this.dir && this.axis) {
      const { t } = this.time
      this.rocketSmoke.setSmokeCoords(
        this.rocketFuelMesh,
        this.rocketFuelGeometry
      )
      this.rocketBody?.position.copy(this.spline.getPoint(t))
      // get the tangent to the curve
      const tangent = this.spline.getTangent(t).normalize()

      // calculate the axis to rotate around
      this.axis?.crossVectors(this.dir, tangent).normalize()

      // calcluate the angle between the up vector and the tangent
      const radians = Math.acos(this.dir.dot(tangent))

      // set the quaternion
      this.rocketBody?.quaternion.setFromAxisAngle(this.axis, radians)
      this.rocketSmoke.updateSmokeArr()
    }
  }

  animate(planetPosition: { x: number; y: number }) {
    if (this.rocketBody) {
      const curvature = getRandomRange(30, 100)
      const curvatureDistance = getRandomRange(-50, -20)
      const centerX = (this.rocketBody.position.x + planetPosition.x) / 2
      const centerY = (this.rocketBody.position.y + planetPosition.y) / 2
      const direction = [
        new THREE.Vector3(
          this.rocketBody.position.x,
          this.rocketBody.position.y,
          50
        ),
        new THREE.Vector3(
          centerX + curvature,
          centerY + curvature,
          curvatureDistance
        ),
        new THREE.Vector3(planetPosition.x, planetPosition.y, 50),
      ]
      const distance = Math.round(
        calculateDistance(
          this.rocketBody.position.x,
          this.rocketBody.position.y,
          planetPosition.x,
          planetPosition.y
        )
      )
      const time = (distance * 1000) / 100 //considering 100 scene units = 1 sec move
      this.rocketSound?.play()
      this.time.t = 0
      this.isMoving = true
      this.spline = new THREE.CatmullRomCurve3(direction)
      if (this.tween) {
        TWEEN.remove(this.tween)
      }
      this.tween = new TWEEN.Tween(this.time)
        .to({ t: 1.0 }, time)
        .easing(TWEEN.Easing.Quadratic.Out)
        .on('update', () => {
          this.updateMesh()
          this.followCamera()
        })
        .on('complete', () => {
          this.isMoving = false
          TWEEN.remove(this.tween)
          this.tween = null
          this.rocketSound?.stop()
        })
        .start()
    }
  }

  followCamera() {
    if (this.camera && this.rocketBody) {
      this.camera.position
        .set(
          this.camera.position.x - this.rocketBody.position.x,
          this.camera.position.y - this.rocketBody.position.y,
          this.camera.position.z
        )
        .set(0, 0, this.camera.position.z)
        .set(
          this.camera.position.x + this.rocketBody.position.x,
          this.camera.position.y + this.rocketBody.position.y,
          this.camera.position.z
        )
      this.camera.lookAt(this.rocketBody.position)
    }
  }

  update() {
    TWEEN.update()

    if (
      this.rocketBody &&
      this.rocketBody.children[0] &&
      this.rocketBody.children[0].children[0]
    ) {
      const rocketMesh = this.rocketBody.children[0].children[0]
      rocketMesh.rotation.x -= 0.005 * 2
    }
  }

  destroy() {
    if (this.rocketBody) {
      while (this.rocketBody.children.length > 0) {
        this.rocketBody.remove(this.rocketBody.children[0])
      }
    }
    this.rocketFuelGeometry?.dispose()
    this.rocketFuelGeometry = null
    this.rocketFuelMesh = null
    this.rocketSmoke = null
    this.rocketBody = null
    this.scene = null
    this.camera = null
    this.options = null
    this.spline = null
    this.dir = null
    this.axis = null
    this.rocketSound?.stop()
    this.rocketSound = null
    this.tween = null
  }
}
