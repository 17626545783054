import styles from './progress.module.scss'

interface ProgressProps {
  width: number
}

export const Progress = ({ width }: ProgressProps) => (
  <div className={styles.outer}>
    <div
      className={styles.inner}
      style={{
        width: `${width}%`
      }}
    />
  </div>
);