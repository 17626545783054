import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  errorExercises: string
  loadingExercises: string
  warning: string
  cancelMission: string
  lostProgress: string
  cancelMissionTwo: string
  continueZone: string
  skipZone: string
  continue: string
  missionFailed: string
  exitMission: string
  missingExercise: string
  exitMissionTwo: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    errorExercises: 'There was an error when fetching the exercises',
    loadingExercises: 'Loading pack exercises...',
    warning: 'Warning message',
    cancelMission: 'Cancel Mission',
    lostProgress: 'The progress will be lost. Are you sure?',
    cancelMissionTwo: 'Cancel Mission',
    continueZone: 'Continue to next zone',
    skipZone: 'Skip Zone',
    continue: 'Continue',
    missionFailed: 'The Mission has failed',
    exitMission: 'Exit Mission',
    missingExercise: 'The Exercise is missing',
    exitMissionTwo: 'Exit Mission',
  },
  [languageCodes.ro_RO]: {
    errorExercises: 'A apărut o eroare în timpul primirii exercițiilor',
    loadingExercises: 'Încărcare pachet exerciții...',
    warning: 'Mesaj de avertizare',
    cancelMission: 'Anulează Misiunea',
    lostProgress: 'Progresul va fi pierdut. Ești sigur?',
    cancelMissionTwo: 'Anulează Misiunea',
    continueZone: 'Continuă cu zona următoare',
    skipZone: 'Sari peste Zonă',
    continue: 'Continuă',
    missionFailed: 'Misiunea a eșuat',
    exitMission: 'Anulează Misiunea',
    missingExercise: 'Exercițiul lipsește',
    exitMissionTwo: 'Anulează Misiunea',
  },
}

export default localeData
