import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from 'react'
import { Carousel } from '../../atoms/carousel'
import { ErrorBox } from '../../atoms/error-box'
import { Image } from '../../atoms/image'
import { Play16, Help32 } from '@carbon/icons-react'
import { IExerciseHelper, IExerciseImage } from '../../../core/@types/exercise'
import { Howl } from 'howler'
import { GeneralContext } from '../../../core/context/generalContext'
import locales from './lib/locales'
import styles from './helper.module.scss'

interface IProps {
  helper: IExerciseHelper
}

/**
 * @function Helper
 * @param helper
 */
export function Helper({ helper }: IProps): JSX.Element {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const [audio, setAudio] = useState<Howl | null>(null)
  const requestAnimation = useRef<any>(null)
  const requestTime = useRef<any>(0)
  const fakePlay = useRef<HTMLSpanElement>(null)
  const resourceCount = useRef<number>(0)
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState(true)
  const images = useMemo(() => {
    if (helper) {
      return helper?.images
        ?.reduce((list: any[], item: IExerciseImage): any[] => {
          if (item) {
            return [
              ...list,
              {
                ...item,
                startAt: parseFloat(item.startAt),
              },
            ]
          }
          return list
        }, [])
        .sort((a, b) => {
          return a.startAt - b.startAt
        })
    }
    return []
  }, [helper])
  const step = useCallback(() => {
    if (audio && images.length > 0) {
      const time = audio.seek()
      let slideIndex = 0
      for (let i = images.length - 1; i >= 0; i--) {
        if (time >= images[i].startAt) {
          slideIndex = i
          break
        }
      }
      setCurrentSlide(slideIndex)
    }
  }, [audio, images])
  const animate = useCallback(
    (time: number) => {
      const seconds = 1 * 1000
      if (!requestTime.current || time - requestTime.current >= seconds) {
        requestTime.current = time
        step()
      }
      requestAnimation.current = requestAnimationFrame(animate)
    },
    [step]
  )
  const handleOnLoad = useCallback(() => {
    if (!loaded && images.length > 0) {
      resourceCount.current += 1
      if (resourceCount.current === images.length + 1) {
        setLoaded(true)
      }
    }
  }, [images, loaded])
  const handleOnEnd = useCallback(() => {
    cancelAnimationFrame(requestAnimation.current)
    if (audio) {
      audio.stop()
    }
    setIsComplete(true)
  }, [audio])

  const renderSlides = () => {
    return images?.map((image, index) => {
      return (
        <Image
          key={index}
          src={image.url}
          alt="helper"
          title="helper"
          onSuccess={handleOnLoad}
        />
      )
    })
  }

  function handleOnPlay() {
    cancelAnimationFrame(requestAnimation.current)
    requestAnimation.current = requestAnimationFrame(animate)
    if (audio) {
      audio.play()
      setIsComplete(false)
    }
  }

  useEffect(() => {
    if (helper && !audio) {
      setAudio(
        new Howl({
          src: [helper.audio],
          html5: true,
          autoplay: false,
          onload: handleOnLoad,
          onend: handleOnEnd,
          format: ['mp3'],
        })
      )
    }
    return () => {
      if (audio) {
        audio.unload()
        cancelAnimationFrame(requestAnimation.current)
      }
    }
  }, [audio, handleOnEnd, handleOnLoad, helper, step])

  if (!helper) {
    return <ErrorBox>{locale?.errorRenderHelper}</ErrorBox>
  }

  return (
    <div className={styles.helper}>
      <span ref={fakePlay} onClick={handleOnPlay}></span>
      {isComplete && (
        <div
          className={`${styles.buttonOverlay} d-flex align-items-center justify-content-center`}
        >
          <div
            className={`${styles.refresh} d-flex align-items-center justify-content-center`}
            onClick={handleOnPlay}
          >
            <Play16 />
          </div>
        </div>
      )}
      {!loaded && (
        <div
          className={`${styles.overlay} d-flex align-items-center justify-content-center`}
        >
          <Help32 />
        </div>
      )}
      <Carousel currentSlide={currentSlide} slides={renderSlides()} />
    </div>
  )
}
