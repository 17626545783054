import cx from 'classnames'
import styles from './loading.module.scss'

interface IProps {
  className?: string
  message?: string
  size?: number
}

/**
 * @function Loading
 */
export function Loading({ className, message, size }: IProps): JSX.Element {
  const iconSize = size || 60
  return (
    <div
      className={`${cx(
        styles.loading,
        className
      )} d-flex justify-content-center align-items-center`}
    >
      <svg
        width={iconSize}
        height={iconSize}
        viewBox={`0 0 ${iconSize} ${iconSize}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          fill="none"
          cx={iconSize / 2}
          cy={iconSize / 2}
          r={iconSize / 2}
        />
      </svg>
      {message && <span>{message}</span>}
    </div>
  )
}
