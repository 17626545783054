import React, { forwardRef, ChangeEvent } from 'react'
import cx from 'classnames'
import { ErrorMessage } from '../error-message'
import styles from './input-field.module.scss'

interface IProps {
  value?: string
  name: string
  error?: string
  type: 'text' | 'email' | 'password' | 'number'
  placeholder?: string
  disabled?: boolean
  hasValidation?: boolean
  readOnly?: boolean
  tabIndex?: number
  autocomplete?: string
  dataQa?: string
  className?: string
  onChange?(ev: ChangeEvent<HTMLInputElement>): void
  onBlur?(): void
  label?: string
}

export const InputField = forwardRef<HTMLInputElement, IProps>(function (
  {
    type,
    name,
    value,
    placeholder,
    error,
    hasValidation,
    disabled,
    onBlur,
    onChange,
    tabIndex,
    readOnly,
    autocomplete,
    dataQa,
    className,
    label,
  },
  ref
) {
  const hasError = !!error && hasValidation

  return (
    <div className={cx(styles.wrapper, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        className={cx(styles.inputField, {
          [styles.valid]: !hasError && value && hasValidation,
          [styles.invalid]: hasError,
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        tabIndex={tabIndex}
        ref={ref}
        autoComplete={autocomplete}
        data-qa={dataQa}
      />
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  )
})
