import { gql } from '@apollo/client'

export const getExercise = gql`
  query Exercise($id: ID!) {
    exercise(id: $id) {
      id
      name
      title
      properties
      type
    }
  }
`

export const getHistories = gql`
  query Histories($start: Int) {
    histories(start: $start) {
      _id
      attempts
      isCorrect
      pickedValue
      timeSpent
      createdAt
      exercise {
        id
        title
      }
      extrauser {
        id
        firstName
        lastName
      }
    }
  }
`

export const getExercises = gql`
  query Exercises($start: Int, $sort: String, $filter: String) {
    exercises(start: $start, sort: $sort, where: { name_contains: $filter }) {
      id
      title
      name
      updatedAt
    }
  }
`
