import { useState, useContext, useEffect } from 'react'
import {
  Help32,
  ArrowShiftDown16,
  Close24,
  Close16,
  Model32,
  Help16,
} from '@carbon/icons-react'
import cx from 'classnames'
import { MATEMATROL_ICON } from '../../../../core/constants/resources'
import { pauseGame, startGame } from '../../../../core/utils/gameActions'
import { GeneralContext } from '../../../../core/context/generalContext'
import locales from './lib/locales'
import { Image } from '../../../../components/atoms/image'
import styles from './instructions.module.scss'
interface IProps {
  initialised: boolean
}

export function Instructions({ initialised }: IProps) {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const [showInstructions, setShowInstructions] = useState<boolean>(false)

  function closeInstructions() {
    startGame()
    setShowInstructions(false)
  }

  function openInstructions() {
    pauseGame()
    setShowInstructions(true)
  }

  useEffect(() => {
    if (initialised) {
      pauseGame()
      setShowInstructions(true)
    }
  }, [initialised])

  return (
    <div className={styles.instructions}>
      <div
        className={`${styles.panel} ${cx({
          [styles.closedPanel]: !showInstructions,
        })}`}
      >
        <div className={styles.closeButton} onClick={closeInstructions}>
          <Close24 />
        </div>
        <div className={`${styles.wrapper} p-3`}>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="row py-3">
                <div
                  className={`${styles.title} col-12 d-flex justify-content-center`}
                >
                  {locale.playerControls}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                  <div
                    className={`${styles.key} ${styles.eKey} d-flex align-items-center justify-content-center mb-2`}
                  >
                    W
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className={`${styles.key} ${styles.eKey} d-flex align-items-center justify-content-center`}
                    >
                      A
                    </div>
                    <div
                      className={`${styles.key} ${styles.eKey} d-flex align-items-center justify-content-center mx-2`}
                    >
                      S
                    </div>
                    <div
                      className={`${styles.key} ${styles.eKey} d-flex align-items-center justify-content-center`}
                    >
                      D
                    </div>
                  </div>
                </div>
                <div className="col-6">{locale.movement}</div>
              </div>
              <div className="row mb-3">
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <div className={styles.key}>
                    <ArrowShiftDown16 className={styles.shiftKey} />
                  </div>
                </div>
                <div className="col-6">{locale.sprint}</div>
              </div>
              <div className="row mb-3">
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <div
                    className={`${styles.key} ${styles.eKey} d-flex align-items-center justify-content-center`}
                  >
                    E
                  </div>
                </div>
                <div className="col-6">{locale.pickShards}</div>
              </div>
              <div className="row mb-3">
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <div className={styles.spaceKey}></div>
                </div>
                <div className="col-6">{locale.jump}</div>
              </div>
              <div className="row mb-3">
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <div
                    className={`${styles.mouseKey} d-flex align-items-start justify-content-center`}
                  >
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div className="col-6">{locale.fire}</div>
              </div>
              <div className="row">
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <div
                    className={`${styles.key} d-flex align-items-center justify-content-center`}
                  >
                    esc
                  </div>
                </div>
                <div className="col-6">{locale.exit}</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row py-3">
                <div
                  className={`${styles.title} col-12 d-flex justify-content-center`}
                >
                  {locale.objectives}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6 d-flex justify-content-center">
                  <Image
                    className={styles.enemy}
                    src={MATEMATROL_ICON}
                    alt="matematroll"
                    loadingSize={50}
                  />
                </div>
                <div className="col-6">{locale.killEnemies}</div>
              </div>
              <div className="row mb-3">
                <div className="col-6 d-flex justify-content-center">
                  <Model32 className={styles.shard} />
                </div>
                <div className="col-6">{locale.collectShards}</div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div
              className={`${styles.title} col-12 d-flex justify-content-center`}
            >
              {locale.tips}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {locale.toggleInstructions} <Help16 /> {locale.orText} <Close16 />{' '}
              {locale.buttons}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${styles.button} d-flex justify-content-center align-items-center`}
        onClick={openInstructions}
      >
        <Help32 />
      </div>
    </div>
  )
}
