import { useContext } from 'react'
import { Logout16 } from '@carbon/icons-react'
import { GeneralContext } from '../../../core/context/generalContext'
import { LOGO_IMAGE } from '../../../core/constants/resources'
import { Image } from '../image'
import locales from './lib/locales'
import styles from './game-header.module.scss'

export function GameHeader() {
  const { logout, userInfo, languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  return (
    <div className={`${styles.header} container-fluid`}>
      <div className="row py-3">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <div>
            <Image className={styles.logo} src={LOGO_IMAGE} alt="logo" loadingSize={30} />
          </div>
          <div className="d-flex align-items-center">
            <div className={styles.user}>
              {locale?.userLabel}: <span>{userInfo?.username}</span>
            </div>
            <button
              className={`${styles.button} d-flex align-items-center`}
              onClick={logout}
            >
              <Logout16 /> <span>{locale?.logoutButton}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
