import { MATEMATROLII_EVENT } from '../constants/game'

export function startGame() {
  const event = new CustomEvent(MATEMATROLII_EVENT, {
    detail: {
      type: 'start',
      targets: [],
    },
  })
  window.document.dispatchEvent(event)
}

export function pauseGame() {
  const event = new CustomEvent(MATEMATROLII_EVENT, {
    detail: {
      type: 'pause',
      targets: [],
    },
  })
  window.document.dispatchEvent(event)
}
