import React, { useContext } from 'react'
import { GameConsole32 } from '@carbon/icons-react'
import { Button } from '../../components/atoms/button'
import { LOGIN, TEAM } from '../../core/constants/routes'
import locales, { ILocaleProps } from './lib/locales'
import { GeneralContext } from '../../core/context/generalContext'
import { LogoutHeader } from '../../components/atoms/logout-header'
import {
  BACKGROUND_INTRO_BACKGROUND,
  LOGO_IMAGE,
} from '../../core/constants/resources'
import styles from './home.module.scss'

export function Home() {
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]

  return (
    <div
      className={`${styles.home}`}
      style={{ backgroundImage: `url(${BACKGROUND_INTRO_BACKGROUND})` }}
    >
      <LogoutHeader
        items={[
          {
            url: TEAM,
            name: locale?.team,
          },
        ]}
      />
      <div
        className={`${styles.rowWrapper} row justify-content-center flex-column`}
      >
        <div className={`${styles.colWrapper} offset-3 col-6`}>
          <h1
            className={styles.title}
            style={{ backgroundImage: `url(${LOGO_IMAGE})` }}
          >
            {locale?.title}
          </h1>
          <Button
            type="button"
            url={LOGIN}
            title={
              <>
                <GameConsole32 />
                <span>{locale?.button}</span>
              </>
            }
          />
        </div>
      </div>
    </div>
  )
}
