import { AWS_S3_URL } from './general'
export const TERRA_MODEL = `${AWS_S3_URL}/missions/planets/terra/terra.glb`
export const TERRA_TEXTURE = `${AWS_S3_URL}/missions/planets/terra/terra.webp`
export const TERRA_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/terra/terra-disabled.webp`
export const ADENDUS_MODEL = `${AWS_S3_URL}/missions/planets/adendus/adendus.glb`
export const ADENDUS_TEXTURE = `${AWS_S3_URL}/missions/planets/adendus/adendus.webp`
export const ADENDUS_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/adendus/adendus-disabled.webp`
export const DIMINUS_MODEL = `${AWS_S3_URL}/missions/planets/diminus/diminus.glb`
export const DIMINUS_TEXTURE = `${AWS_S3_URL}/missions/planets/diminus/diminus.webp`
export const DIMINUS_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/diminus/diminus-disabled.webp`
export const MULTIPLIS_MODEL = `${AWS_S3_URL}/missions/planets/multiplis/multiplis.glb`
export const MULTIPLIS_TEXTURE = `${AWS_S3_URL}/missions/planets/multiplis/multiplis.webp`
export const MULTIPLIS_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/multiplis/multiplis-disabled.webp`
export const FRACTA_MODEL = `${AWS_S3_URL}/missions/planets/fracta/fracta.glb`
export const FRACTA_TEXTURE = `${AWS_S3_URL}/missions/planets/fracta/fracta.webp`
export const FRACTA_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/fracta/fracta-disabled.webp`
export const DIVISIA_MODEL = `${AWS_S3_URL}/missions/planets/divisia/divisia.glb`
export const DIVISIA_TEXTURE = `${AWS_S3_URL}/missions/planets/divisia/divisia.webp`
export const DIVISIA_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/divisia/divisia-disabled.webp`
export const HYBRIDIA_MODEL = `${AWS_S3_URL}/missions/planets/hybridia/hybridia.glb`
export const HYBRIDIA_TEXTURE = `${AWS_S3_URL}/missions/planets/hybridia/hybridia.webp`
export const HYBRIDIA_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/hybridia/hybridia-disabled.webp`
export const QUESTIA_MODEL = `${AWS_S3_URL}/missions/planets/questia/questia.glb`
export const QUESTIA_TEXTURE = `${AWS_S3_URL}/missions/planets/questia/questia.webp`
export const QUESTIA_TEXTURE_DISABLED = `${AWS_S3_URL}/missions/planets/questia/questia-disabled.webp`
export const APOLLO_MODEL = `${AWS_S3_URL}/rocket/rocket.glb`
export const APOLLO_TEXTURE = `${AWS_S3_URL}/rocket/textures/apollo.webp`
export const SKYLAB_MODEL = `${AWS_S3_URL}/rocket/rocket.glb`
export const SKYLAB_TEXTURE = `${AWS_S3_URL}/rocket/textures/skylab.webp`
export const ROCKET_SOUND = `${AWS_S3_URL}/rocket/rocket.mp3`

export const HERO_MODEL = `${AWS_S3_URL}/hero/hero.glb`
export const HERO_TEXTURE_OXY = `${AWS_S3_URL}/hero/textures/oxy.webp`
export const HERO_TEXTURE_NANO = `${AWS_S3_URL}/hero/textures/nano.webp`

export const MATEMATROL_MODEL = `${AWS_S3_URL}/matematrol/matematrol.glb`
export const MATEMATROL_TEXTURE = `${AWS_S3_URL}/matematrol/matematrol.webp`

export const ITEM_MODEL = `${AWS_S3_URL}/diamond/diamond.glb`
export const ITEM_TEXTURE = `${AWS_S3_URL}/items/mushroom/mushroom.webp` // TODO: create specific texture for the item

// Environmnets
export const ADENDUS_MISSION_MODEL = `${AWS_S3_URL}/environments/adendus.glb`
export const DIMINUS_MISSION_MODEL = `${AWS_S3_URL}/environments/diminus.glb`
export const FRACTA_MISSION_MODEL = `${AWS_S3_URL}/environments/fracta.glb`
export const MULTIPLIS_MISSION_MODEL = `${AWS_S3_URL}/environments/multiplis.glb`
export const QUESTIA_MISSION_MODEL = `${AWS_S3_URL}/environments/questia.glb`
export const HYBRIDIA_MISSION_MODEL = `${AWS_S3_URL}/environments/hybridia.glb`
export const DIVISIA_MISSION_MODEL = `${AWS_S3_URL}/environments/divisia.glb`
export const TERRA_MISSION_MODEL = `${AWS_S3_URL}/environments/terra.glb`

// Resources APP
export const BACKGROUND_INTRO_SOUND = `${AWS_S3_URL}/resources-app/backgrounds/sound/intro.mp3`
export const BACKGROUND_MISSIONS_SOUND = `${AWS_S3_URL}/resources-app/backgrounds/sound/missions.mp3`
export const BACKGROUND_INTRO_BACKGROUND = `${AWS_S3_URL}/resources-app/backgrounds/matematrolii-intro.svg`
export const BACKGROUND_SPACE_BACKGROUND = `${AWS_S3_URL}/resources-app/backgrounds/matematrolii-space.svg`
export const PLACEHOLDER_IMAGE = `${AWS_S3_URL}/resources-app/backgrounds/placeholder-image.webp`;
export const BADGE_ACCURACY = `${AWS_S3_URL}/resources-app/badges/accuracy-badge.svg`
export const BADGE_HERO = `${AWS_S3_URL}/resources-app/badges/hero-badge.svg`
export const BADGE_MULTIPLIER = `${AWS_S3_URL}/resources-app/badges/multiplier-badge.svg`
export const BADGE_SPEEDSTER = `${AWS_S3_URL}/resources-app/badges/speedster-badge.svg`
export const BADGE_STARTER = `${AWS_S3_URL}/resources-app/badges/starter-badge.svg`
export const BADGE_TRAVELER = `${AWS_S3_URL}/resources-app/badges/traveler-badge.svg`
export const HERO_NANO_AVATAR = `${AWS_S3_URL}/resources-app/heroes/avatars/nano-avatar.webp`
export const HERO_OXY_AVATAR = `${AWS_S3_URL}/resources-app/heroes/avatars/oxy-avatar.webp`
export const HERO_NANO_ICON = `${AWS_S3_URL}/resources-app/heroes/icons/nano-icon-square.webp`
export const HERO_OXY_ICON = `${AWS_S3_URL}/resources-app/heroes/icons/oxy-icon-square.webp`
export const ITEM_BOTTLE_ICON = `${AWS_S3_URL}/resources-app/items/bottle-icon.webp`
export const ITEM_COIN_ICON = `${AWS_S3_URL}/resources-app/items/coin-icon.webp`
export const LOGO_IMAGE = `${AWS_S3_URL}/resources-app/logo/logo.webp`
export const MATEMATROL_ICON = `${AWS_S3_URL}/resources-app/matematrol/matematrol-icon.webp`
export const PLANET_ADENDUS_ICON = `${AWS_S3_URL}/resources-app/planets/adendus/adendus-icon.webp`
export const PLANET_ADENDUS_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/adendus/adendus-disabled-icon.webp`
export const PLANET_DIMINUS_ICON = `${AWS_S3_URL}/resources-app/planets/diminus/diminus-icon.webp`
export const PLANET_DIMINUS_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/diminus/diminus-disabled-icon.webp`
export const PLANET_DIVISIA_ICON = `${AWS_S3_URL}/resources-app/planets/divisia/divisia-icon.webp`
export const PLANET_DIVISIA_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/divisia/divisia-disabled-icon.webp`
export const PLANET_FRACTA_ICON = `${AWS_S3_URL}/resources-app/planets/fracta/fracta-icon.webp`
export const PLANET_FRACTA_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/fracta/fracta-disabled-icon.webp`
export const PLANET_HYBRIDIA_ICON = `${AWS_S3_URL}/resources-app/planets/hybridia/hybridia-icon.webp`
export const PLANET_HYBRIDIA_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/hybridia/hybridia-disabled-icon.webp`
export const PLANET_MULTIPLIS_ICON = `${AWS_S3_URL}/resources-app/planets/multiplis/multiplis-icon.webp`
export const PLANET_MULTIPLIS_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/multiplis/multiplis-disabled-icon.webp`
export const PLANET_QUESTIA_ICON = `${AWS_S3_URL}/resources-app/planets/questia/questia-icon.webp`
export const PLANET_QUESTIA_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/questia/questia-disabled-icon.webp`
export const PLANET_TERRA_ICON = `${AWS_S3_URL}/resources-app/planets/terra/terra-icon.webp`
export const PLANET_TERRA_DISABLED_ICON = `${AWS_S3_URL}/resources-app/planets/terra/terra-disabled-icon.webp`
export const RANK_ADENDUS = `${AWS_S3_URL}/resources-app/ranks/adendus.svg`
export const RANK_DIMINUS = `${AWS_S3_URL}/resources-app/ranks/diminus.svg`
export const RANK_DIVISIA = `${AWS_S3_URL}/resources-app/ranks/divisia.svg`
export const RANK_FRACTA = `${AWS_S3_URL}/resources-app/ranks/fracta.svg`
export const RANK_HYBRIDIA = `${AWS_S3_URL}/resources-app/ranks/hybridia.svg`
export const RANK_MULTIPLIS = `${AWS_S3_URL}/resources-app/ranks/multiplis.svg`
export const RANK_QUESTIA = `${AWS_S3_URL}/resources-app/ranks/questia.svg`
export const RANK_TERRA = `${AWS_S3_URL}/resources-app/ranks/terra.svg`
export const ROCKET_APOLLO = `${AWS_S3_URL}/resources-app/rockets/apollo-icon.webp`
export const ROCKET_SKYLAB = `${AWS_S3_URL}/resources-app/rockets/skylab-icon.webp`
export const SEM_AVATAR_2 = `${AWS_S3_URL}/resources-app/sem/avatars/sem-avatar-2.webp`
export const SEM_AVATAR = `${AWS_S3_URL}/resources-app/sem/avatars/sem-avatar.webp`
export const SEM_ICON = `${AWS_S3_URL}/resources-app/sem/icons/sem-icon-square.webp`
export const SPACESHIP_ICON = `${AWS_S3_URL}/resources-app/spaceship/spaceship-icon.webp`
export const STORY_SCENE_1 = `${AWS_S3_URL}/resources-app/story/scene1.jpg`
export const STORY_SCENE_2 = `${AWS_S3_URL}/resources-app/story/scene2.jpg`
export const STORY_SCENE_3 = `${AWS_S3_URL}/resources-app/story/scene3.jpg`
export const TEAM_ALINA_FLOREA = `${AWS_S3_URL}/resources-app/team/alina-florea.webp`
export const TEAM_GABRIEL_CRISAN = `${AWS_S3_URL}/resources-app/team/gabriel-crisan.webp`
export const TEAM_IOANA_MAGDAS = `${AWS_S3_URL}/resources-app/team/ioana-magdas.webp`
export const TEAM_LAURA_VISU = `${AWS_S3_URL}/resources-app/team/laura-visu.webp`
export const TEAM_LAVINIA_CHEIE = `${AWS_S3_URL}/resources-app/team/lavinia-cheie.webp`
export const TEAM_LIZICA_GROZA = `${AWS_S3_URL}/resources-app/team/lizica-groza.webp`
export const TEAM_MONICA_BUTA = `${AWS_S3_URL}/resources-app/team/monica-buta.webp`
export const TEAM_SIMINA_CACUCI = `${AWS_S3_URL}/resources-app/team/simina-cacuci.webp`
export const TEAM_THOMAS_HUNT = `${AWS_S3_URL}/resources-app/team/thomas-hunt.webp`
