import { gql } from '@apollo/client'

export const updateConfiguration = gql`
  mutation updateConfiguration($id: ID!, $data: JSON!) {
    updateConfiguration(
      input: {
        where: { id: $id }
        data: { enabled_data: $data }
      }
    ) {
      configuration {
        _id
      }
    }
  }
`
