import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { MissionContext } from '../../../../../../core/context/missionContext'
import { Button } from '../../../../../../components/atoms/button'
import { Title } from '../../../../../../components/atoms/title'
import { Image } from '../../../../../../components/atoms/image'
import { GAME } from '../../../../../../core/constants/routes'
import { countMissionOccurences } from '../../../../../../core/utils/countMissionOccurences'
import { Rocket32, Close32, Information32 } from '@carbon/icons-react'
import { ranksData } from '../../../../../../core/game-data/ranks'
import locales from './lib/locales'
import styles from './mission-complete.module.scss'

interface IProps {
  showGame: boolean | undefined
  cleanupCanvas(): void
}

export function MissionComplete({ showGame, cleanupCanvas }: IProps) {
  const history = useHistory()
  const { gameInfo, currentMission, languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const { onMissionFail } = useContext(MissionContext)
  const game = gameInfo?.user?.extrauser?.game
  const countMissions = countMissionOccurences(game?.missions, currentMission)
  const hasNewRank = countMissions === 1
  const landOnPlanetEnabled =
    currentMission && game?.missions?.indexOf(currentMission) !== -1
  const unlockMessage = `${locale.unlocked} ${currentMission}`
  const reachMessage = `${locale.reached} x${countMissions} ${currentMission}`

  function handleRedirectToGame() {
    cleanupCanvas()
    history.push(GAME)
  }

  const renderPlanetActions = () => {
    return (
      <div className="row align-items-center">
        <div className="col-12 col-lg-6">
          <Button
            disabled={!landOnPlanetEnabled}
            className={styles.button}
            type="button"
            title={
              <>
                <Rocket32 />
                <span>{locale.landPlanet}</span>
              </>
            }
            onClick={handleRedirectToGame}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Button
            type="button"
            className={`${styles.button} ${styles.cancelButton}`}
            onClick={onMissionFail}
            title={
              <>
                <Close32 />
                <span>{locale.cancelMission}</span>
              </>
            }
          />
        </div>
      </div>
    )
  }

  const renderNextMissionActions = () => {
    return (
      <div className="row align-items-center">
        <div className="col-12 col-lg-6">
          <Button
            type="button"
            className={styles.button}
            onClick={onMissionFail}
            title={
              <>
                <Information32 />
                <span>{locale.continueNextMission}</span>
              </>
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <div className="row p-3">
        <div
          className={`col-12 p-5 d-flex flex-column align-items-center justify-content-center ${styles.missionComplete}`}
        >
          <Title
            className="mb-3"
            headingLevel={1}
            title={locale.congratulations}
          ></Title>
          {countMissions > 0 && (
            <Title
              className="mb-3"
              headingLevel={3}
              title={hasNewRank ? unlockMessage : reachMessage}
            />
          )}
          {currentMission && (
            <div className="my-3">
              <Image
                src={ranksData[currentMission].image}
                alt={ranksData[currentMission].name}
              />
            </div>
          )}
          <Title
            className="mb-3"
            headingLevel={2}
            title={`${locale.saved} ${currentMission}!`}
          ></Title>
          {showGame ? renderPlanetActions() : renderNextMissionActions()}
        </div>
      </div>
    </div>
  )
}
