import { useContext } from 'react'
import { Button } from '../../components/atoms/button'
import { PROFILE } from '../../core/constants/routes'
import { UserProfile16, Receipt24, Events24 } from '@carbon/icons-react'
import { GeneralContext } from '../../core/context/generalContext'
import { LOGO_IMAGE } from '../../core/constants/resources'
import locales from './lib/locales'
import styles from './credits.module.scss'

export function Credits(): JSX.Element {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]

  return (
    <div className={styles.creditsWrapper}>
      <div
        className={`${styles.credits} d-flex flex-column py-3 align-items-center`}
      >
        <div
          className={styles.app}
          style={{ backgroundImage: `url(${LOGO_IMAGE})` }}
        >
          {locale?.title}
        </div>
        <div className={`${styles.job} mt-5`}>
          <Receipt24 />
          <span>{locale?.titleDescription}</span>
        </div>
        <div className={styles.name}>{locale?.description}</div>
        <div className={`${styles.job} mt-5`}>
          <Events24 />
          <span>{locale?.titleTeam}</span>
        </div>
        <div className={styles.name}>Gabriel Crișan</div>
        <div className={styles.name}>Laura Vișu-Petra</div>
        <div className={styles.name}>Lavinia Cheie</div>
        <div className={styles.name}>Monica Buta</div>
        <div className={styles.name}>Simina-Alexandra Cacuci</div>
        <div className={styles.name}>Lizica Groza</div>
        <div className={styles.name}>Ioana-Cristina Magdaș</div>
        <div className={`${styles.job} mt-5`}>{locale?.titleDeveloped}</div>
        <div className={styles.name}>React</div>
      </div>
      <div
        className={`${styles.buttonWrapper} d-flex justify-content-center align-items-center`}
      >
        <Button
          type="button"
          url={PROFILE}
          title={
            <>
              <UserProfile16 />
              <span>{locale?.profileButton}</span>
            </>
          }
        />
      </div>
    </div>
  )
}
