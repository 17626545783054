import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  unlocked: string
  reached: string
  congratulations: string
  saved: string
  landPlanet: string
  cancelMission: string
  continueNextMission: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    unlocked: 'You unlocked the rank:',
    reached: 'You reached:',
    congratulations: 'Congratulations!',
    saved: 'You saved the planet',
    landPlanet: 'Land on planet',
    cancelMission: 'Cancel Mission',
    continueNextMission: 'Continue with next mission'
  },
  [languageCodes.ro_RO]: {
    unlocked: 'Ai deblocat rank-ul:',
    reached: 'Ai completat:',
    congratulations: 'Felicitări!',
    saved: 'Ai salvat planeta',
    landPlanet: 'Aterizează pe planeta',
    cancelMission: 'Anulează Misiunea',
    continueNextMission: 'Continuă cu misiunea urmatoare'
  },
}

export default localeData
