import React, { useContext } from 'react'
import { GeneralContext } from '../../../../core/context/generalContext'
import { Dropdown } from '../../../../components/atoms/dropdown'
import { useFormContext, useController } from 'react-hook-form'
import locales, { ILocaleProps } from './lib/locales'
import style from './enableDropdown.module.scss'

interface EnableDropdownProps {
  name: string
}

export const EnableDropdown: React.FC<EnableDropdownProps> = ({ name }) => {
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]
  const { control } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  })

  const handleChange = (value: string) => {
    const isTrue = value === 'true'
    onChange(isTrue)
  }

  return (
    <Dropdown
      items={[
        {
          value: 'true',
          title: locale?.show,
        },
        {
          value: 'false',
          title: locale?.hide,
        },
      ]}
      value={`${value}`}
      onChange={handleChange}
      className={style.enableDropdown}
    />
  )
}
