import { Heroes, IGameData } from '../@types/game'
import { HERO_NANO_ICON, HERO_OXY_ICON } from '../constants/resources'

export const heroData: { [key in Heroes]: IGameData } = {
  [Heroes.HERO_OXY]: {
    id: Heroes.HERO_OXY,
    name: 'Oxy',
    price: 29,
    image: HERO_OXY_ICON,
    description: '',
  },
  [Heroes.HERO_NANO]: {
    id: Heroes.HERO_NANO,
    name: 'Nano',
    price: 20,
    image: HERO_NANO_ICON,
    description: '',
  },
}
