import { useContext, useMemo } from 'react'
import { GeneralContext } from '../../../../core/context/generalContext'
import { MissionContext } from '../../../../core/context/missionContext'
import { Zones } from './components/zones'
import { IMission, Missions, MissionType } from '../../../../core/@types/game'
import { countMissionOccurences } from '../../../../core/utils/countMissionOccurences'
import { Button } from '../../../../components/atoms/button'
import { Title } from '../../../../components/atoms/title'
import { Checkmark24 } from '@carbon/icons-react'
import { WelcomeMessage } from './components/welcome-message'
import { FinalMission } from './components/final-mission'
import locales from './lib/locales'
import cx from 'classnames'
import styles from './mission.module.scss'

interface IProps {
  cleanupCanvas(): void
}

export function Mission({ cleanupCanvas }: IProps) {
  const { gameInfo, currentMission, languageCode } = useContext(GeneralContext)
  const { setMissionStarted, missionStarted } = useContext(MissionContext)
  const locale = locales[languageCode]
  const game = gameInfo?.user?.extrauser?.game
  const configuration = gameInfo?.user?.extrauser?.group?.configuration
  const configurationMissions = useMemo(() => {
    return configuration && configuration.missions
      ? configuration.missions.reduce((list: IMission[], mission: IMission) => {
          if (
            configuration.enabled_data &&
            configuration.enabled_data.visibility[mission.type].show
          ) {
            return [
              ...list,
              mission,
            ]
          }
          return list
        }, [])
      : []
  }, [configuration])
  const isDisabled =
    game?.missions?.indexOf(currentMission as MissionType) === -1
  const isFinalMission =
    configurationMissions.length > 0
      ? configurationMissions.every((mission: IMission) => {
          if (mission.type === Missions.TERRA) {
            //skip mission terra
            return true
          }
          const occurences = countMissionOccurences(
            game?.missions,
            mission.type
          )
          return occurences >= 1
        })
      : false
  const isFinalMissionCompleted =
    game && game.missions ? game.missions.indexOf(Missions.TERRA) !== -1 : false
  const missions = useMemo<
    | {
        [key in Missions]: string
      }
    | null
  >(() => {
    return configurationMissions.length > 0
      ? configurationMissions.reduce((map: any, mission: IMission) => {
          return {
            ...map,
            [mission.type]: mission.id,
          }
        }, {})
      : null
  }, [configurationMissions])

  const missionId =
    missions && currentMission && missions[currentMission]
      ? missions[currentMission]
      : null
  const showFinalMission = isFinalMission && !isFinalMissionCompleted

  function renderMission() {
    if (missionId) {
      return !missionStarted ? (
        <div className="row p-3">
          <div className="col-12 d-flex flex-column align-items-center p-3">
            <Title
              headingLevel={1}
              title={`${locale.mission} ${currentMission}`}
            ></Title>
            {!isDisabled && (
              <div
                className={`${styles.missionStatus} d-flex align-items-center justify-content-center`}
              >
                {locale.missionCompleted} <Checkmark24 />
              </div>
            )}
            <Button
              type="button"
              title={locale.startMission}
              onClick={() => setMissionStarted(true)}
            />
          </div>
        </div>
      ) : (
        <Zones missionId={missionId} cleanupCanvas={cleanupCanvas} />
      )
    }
    return null
  }

  return (
    <div
      className={cx(styles.mission, {
        [styles.missionStarted]: missionStarted,
      })}
    >
      {showFinalMission ? (
        <FinalMission />
      ) : missionId ? (
        renderMission()
      ) : (
        <WelcomeMessage />
      )}
    </div>
  )
}
