import { useEffect, useState, useCallback } from 'react'
import { ITreeNode, TreeNodeEnum } from '../@types/image-tree'
import { getBucketData } from '../api/aws-sdk/getBucketData'
import { AWS_S3_URL, EXTENSIONS, BUCKET_RESOURCES } from '../constants/general'
import { Missions } from '../@types/game'
import { capitalise } from '../utils/capitalise'
import { ListObjectsV2Output, Object, ObjectList } from 'aws-sdk/clients/s3'
import { getStorage, setStorage, removeStorage } from '../storage'

const skipResources = [
  'Adendus_Nivel_1_Audio_b_online_audio_converter_com_bc77c966c5.mp3',
  'Adendus_Nivel_1_Audio_a_online_audio_converter_com_13e1d6c244.mp3',
]

function getTypeMime(name: string) {
  const [fileName, extension] = name.split('.')
  if (EXTENSIONS.image.indexOf(extension) !== -1) {
    return TreeNodeEnum.LEAF_IMAGE
  } else if (EXTENSIONS.audio.indexOf(extension) !== -1) {
    return TreeNodeEnum.LEAF_AUDIO
  }
  return TreeNodeEnum.LEAF_DEFAULT
}

async function fetchBucketData(
  prefix: string,
  list: ObjectList,
  token: string
) {
  const result: ListObjectsV2Output = await getBucketData(prefix, token)
  if (result.Contents) {
    const contents = result.Contents.reduce(
      (mediaList: ObjectList, item: Object) => {
        if (item.Key && skipResources.indexOf(item.Key) !== -1) {
          return mediaList
        }
        return [...mediaList, item]
      },
      []
    )
    Array.prototype.push.apply(list, contents)
    if (result.IsTruncated) {
      await fetchBucketData(prefix, list, result.NextContinuationToken || '')
    }
  }
}

interface IBucket {
  treeNodes: ITreeNode[]
  refresh(): void
}

export function useBucket({ shouldFetch }: { shouldFetch: boolean }): IBucket {
  const [treeNodes, setTreeNodes] = useState<ITreeNode[]>([])

  const fetchImages = useCallback(async () => {
    const images: ObjectList = []
    await fetchBucketData(capitalise(Missions.ADENDUS), images, '')
    await fetchBucketData(capitalise(Missions.DIMINUS), images, '')
    await fetchBucketData(capitalise(Missions.MULTIPLIS), images, '')
    await fetchBucketData(capitalise(Missions.FRACTA), images, '')
    await fetchBucketData(capitalise(Missions.DIVISIA), images, '')
    await fetchBucketData(capitalise(Missions.QUESTIA), images, '')
    await fetchBucketData(capitalise(Missions.HYBRIDIA), images, '')
    let result: ITreeNode[] = []
    let level = { result }
    images.forEach((image: Object) => {
      if (image.Key) {
        const imageKeyList = image.Key.split('/')
        imageKeyList.reduce((r: any, name: string, index: number) => {
          const isLast = imageKeyList.length - 1 === index
          if (name && !r[name]) {
            r[name] = { result: [] }
            const nodeData = {
              label: name,
              children: r[name].result,
              type: isLast ? getTypeMime(name) : TreeNodeEnum.LIST,
              url: isLast ? `${AWS_S3_URL}/${image.Key}` : undefined,
            }
            r.result.push(nodeData)
          }
          return r[name]
        }, level)
      }
    })
    setTreeNodes(result)
    setStorage(result, BUCKET_RESOURCES, true)
  }, []);

  const refresh = () => {
    setTreeNodes([]);
    removeStorage(BUCKET_RESOURCES);
    fetchImages();
  }

  useEffect(() => {
    if (treeNodes.length === 0 && shouldFetch) {
      const storedTreeNodes = getStorage(BUCKET_RESOURCES)
      if (storedTreeNodes) {
        setTreeNodes(storedTreeNodes)
      } else {
        fetchImages()
      }
    }
  }, [treeNodes, shouldFetch, fetchImages])

  return { treeNodes, refresh }
}
