import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { RoleType, Roles } from '../@types/general'
import { IGame } from '../@types/game'
import { LOGIN, NOT_FOUND, SETUP, PROFILE } from '../constants/routes'

export interface CustomRouteProps extends RouteProps {
  role: RoleType | null
  accessRoles?: string[]
  isUnauthenticated?: boolean
  game: IGame | null
}

export function AuthenticatedRoute({
  children,
  role,
  accessRoles,
  game,
  ...rest
}: CustomRouteProps) {
  const hasAccess = role && accessRoles?.indexOf(role) !== -1
  const hasSetup =
    hasAccess &&
    rest.path !== SETUP &&
    role === Roles.AUTHENTICATED &&
    !game
  const hasGame =
    hasAccess && rest.path === SETUP && role === Roles.AUTHENTICATED && game
  return (
    <Route {...rest}>
      {hasSetup ? (
        <Redirect to={SETUP} />
      ) : hasGame ? (
        <Redirect to={PROFILE} />
      ) : hasAccess ? (
        children
      ) : role ? (
        <Redirect to={NOT_FOUND} />
      ) : (
        <Redirect to={LOGIN} />
      )}
    </Route>
  )
}
