import { useContext, useState } from 'react'
import { GeneralContext } from '../../../../../../core/context/generalContext'
import { Button } from '../../../../../../components/atoms/button'
import { Title } from '../../../../../../components/atoms/title'
import { Image } from '../../../../../../components/atoms/image'
import { GAME } from '../../../../../../core/constants/routes'
import { useHistory } from 'react-router-dom'
import { Rocket32 } from '@carbon/icons-react'
import { missionsIcon } from '../../../../../../core/game-data/missions'
import { IGame, Missions } from '../../../../../../core/@types/game'
import locales from './lib/locales'
import styles from './final-mission.module.scss'

export function FinalMission() {
  const history = useHistory()
  const [disabled, setDisabled] = useState<boolean>(false)
  const { updateGameInfo, setCurrentMission, gameInfo, languageCode } =
    useContext(GeneralContext)
  const locale = locales[languageCode]
  const game = gameInfo?.user?.extrauser?.game

  async function handleFinalMission() {
    const currentMission = Missions.TERRA
    setDisabled(true)
    setCurrentMission(currentMission)
    try {
      await updateGameInfo({
        ...game,
        currentMission,
      } as IGame)
      history.push(GAME)
    } catch (err) {
      console.error('There was an error while updating game missions', err)
    }
  }

  return (
    <div
      className={`${styles.finalMission} p-5 d-flex flex-column align-items-center justify-content-center`}
    >
      <Title
        headingLevel={3}
        title={`${Missions.TERRA} ${locale.underAttack}`}
      />
      <div className="my-3">
        <Image
          className={styles.image}
          src={missionsIcon[Missions.TERRA].enabled}
          alt={Missions.TERRA}
          loadingSize={100}
        />
      </div>
      <Title className="mb-3" headingLevel={3} title={locale.endDanger} />
      <Button
        className={`${styles.button} d-inline`}
        disabled={disabled}
        type="button"
        title={
          <>
            <Rocket32 />
            <span>
              {locale.land} {Missions.TERRA}
            </span>
          </>
        }
        onClick={handleFinalMission}
      />
    </div>
  )
}
