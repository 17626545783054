import { IExerciseProperties } from './exercise'

export enum Badges {
  BADGE_ACCURACY = 'BADGE_ACCURACY',
  BADGE_SPEEDSTER = 'BADGE_SPEEDSTER',
  BADGE_TRAVELER = 'BADGE_TRAVELER',
  BADGE_HERO = 'BADGE_HERO',
  BADGE_STARTER = 'BADGE_STARTER',
  BADGE_MULTIPLIER = 'BADGE_MULTIPLIER',
}

export type BadgeType =
  | Badges.BADGE_ACCURACY
  | Badges.BADGE_SPEEDSTER
  | Badges.BADGE_TRAVELER
  | Badges.BADGE_HERO
  | Badges.BADGE_STARTER
  | Badges.BADGE_MULTIPLIER

export enum Heroes {
  HERO_OXY = 'HERO_OXY',
  HERO_NANO = 'HERO_NANO',
}

export type HeroType = Heroes.HERO_NANO | Heroes.HERO_OXY

export enum Helpers {
  SEM = 'SEM',
}

export enum Items {
  ITEM_BOTTLE = 'ITEM_BOTTLE',
  ITEM_COIN = 'ITEM_COIN',
  ITEM_MUSHROOM = 'ITEM_MUSHROOM',
}

export type ItemType = Items.ITEM_BOTTLE | Items.ITEM_COIN | Items.ITEM_MUSHROOM

export enum Rockets {
  ROCKET_APOLLO = 'ROCKET_APOLLO',
  ROCKET_SKYLAB = 'ROCKET_SKYLAB',
}

export type RocketType = Rockets.ROCKET_APOLLO | Rockets.ROCKET_SKYLAB

export enum Resources {
  TYPE_FUEL = 'TYPE_FUEL',
  TYPE_GOLD = 'TYPE_GOLD',
}

export type ResourceType = Resources.TYPE_FUEL | Resources.TYPE_GOLD

export enum Missions {
  ADENDUS = 'ADENDUS',
  DIMINUS = 'DIMINUS',
  MULTIPLIS = 'MULTIPLIS',
  FRACTA = 'FRACTA',
  DIVISIA = 'DIVISIA',
  QUESTIA = 'QUESTIA',
  HYBRIDIA = 'HYBRIDIA',
  TERRA = 'TERRA',
}

export type MissionType =
  | Missions.ADENDUS
  | Missions.DIMINUS
  | Missions.DIVISIA
  | Missions.FRACTA
  | Missions.HYBRIDIA
  | Missions.MULTIPLIS
  | Missions.QUESTIA
  | Missions.TERRA

export enum Packs {
  PACK1 = 'PACK1',
  PACK2 = 'PACK2',
  PACK3 = 'PACK3',
  PACK4 = 'PACK4',
  PACK5 = 'PACK5',
  PACK6 = 'PACK6',
  PACK7 = 'PACK7',
}

export type PackType =
  | Packs.PACK1
  | Packs.PACK2
  | Packs.PACK3
  | Packs.PACK4
  | Packs.PACK5
  | Packs.PACK6
  | Packs.PACK7

export enum Zones {
  ZONE1 = 'ZONE1',
  ZONE2 = 'ZONE2',
  ZONE3 = 'ZONE3',
  ZONE4 = 'ZONE4',
  ZONE5 = 'ZONE5',
  ZONE6 = 'ZONE6',
  ZONE7 = 'ZONE7',
}

export type ZoneType =
  | Zones.ZONE1
  | Zones.ZONE2
  | Zones.ZONE3
  | Zones.ZONE4
  | Zones.ZONE5
  | Zones.ZONE6
  | Zones.ZONE7

export enum Exercises {
  EXERCISE1 = 'EXERCISE1',
  EXERCISE2 = 'EXERCISE2',
  EXERCISE3 = 'EXERCISE3',
  EXERCISE4 = 'EXERCISE4',
  EXERCISE5 = 'EXERCISE5',
  EXERCISE6 = 'EXERCISE6',
}

export type ExerciseType =
  | Exercises.EXERCISE1
  | Exercises.EXERCISE2
  | Exercises.EXERCISE3
  | Exercises.EXERCISE4
  | Exercises.EXERCISE5
  | Exercises.EXERCISE6

export enum Steps {
  DEFAULT = 'DEFAULT', // show welcome panel when there is no mission selected
  MISSION_SELECTED = 'MISSION_SELECTED', // show mission selected panel before starting it
  MISSION_NAVIGATING = 'MISSION_NAVIGATING', // temporary state between missions selected (enable the animations on messages
  MISSION_STARTED = 'MISSION_STARTED', // display intro exercise panel - welcome message on mission
  EXERCISE_STARTED = 'EXERCISE_STARTED', // display exercise panel
  EXERCISE_ENDED = 'EXERCISE_ENDED', // display exercise success panel to redirect to game
  CONTINUE_ZONE = 'CONTINUE_ZONE', // if 4 exercises have passed and there is a next zone, display a continue zone panel
  CONTINUE_GAME = 'CONTINUE_GAME', // if 4 exercises have passed and there is a last zone, display a continue game panel
  HELPER_STARTED = 'HELPER_STARTED', // show helper part 1
  HELPER2_STARTED = 'HELPER2_STARTED', // show helper part 2
  EXERCISE_FAILED = 'EXERCISE_FAILED',
  FINAL_GAME_SUCCESS = 'FINAL_GAME_SUCCESS', // display outbounds message when hero is out of map area
  FINAL_GAME_FAILED = 'FINAL_GAME_FAILED', // display outbounds message when hero is out of map area
  MISSION_FINAL = 'MISSION_FINAL', // display modal for final battle
  GAME_DEFAULT = 'GAME_DEFAULT', // initial game state after entering the page
  GAME_STARTED = 'GAME_STARTED', // enter game mission page with an intro modal
  GAME_SUCCESS = 'GAME_SUCCESS', // display end game modal to redirect back to missions
  GAME_FAILED = 'GAME_FAILED', // display failed mission modal
  HERO_REWARDING = 'HERO_REWARDING', // display rewards like progress and badges inside a modal
  HERO_OUTBOUNDS = 'HERO_OUTBOUNDS', // display outbounds message when hero is out of map area
  ROCKET_ATTACKED = 'ROCKET_ATTACKED', // notifications for attacked hero
  GAME_END_DEFAULT = 'GAME_END_DEFAULT', // initial game end page
  GAME_SETUP_DEFAULT = 'GAME_SETUP_DEFAULT', // initial game end page
  GAME_CREDITS_DEFAULT = 'GAME_CREDITS_DEFAULT', // initial game end page
  GAME_STORY_DEFAULT = 'GAME_STORY_DEFAULT', // initial game end page
}

export type StepType =
  | Steps.DEFAULT
  | Steps.MISSION_SELECTED
  | Steps.MISSION_NAVIGATING
  | Steps.MISSION_STARTED
  | Steps.EXERCISE_STARTED
  | Steps.EXERCISE_ENDED
  | Steps.CONTINUE_ZONE
  | Steps.CONTINUE_GAME
  | Steps.HELPER_STARTED
  | Steps.HELPER2_STARTED
  | Steps.EXERCISE_FAILED
  | Steps.FINAL_GAME_SUCCESS
  | Steps.FINAL_GAME_FAILED
  | Steps.MISSION_FINAL
  | Steps.GAME_DEFAULT
  | Steps.GAME_STARTED
  | Steps.GAME_SUCCESS
  | Steps.GAME_FAILED
  | Steps.HERO_REWARDING
  | Steps.HERO_OUTBOUNDS
  | Steps.ROCKET_ATTACKED
  | Steps.GAME_END_DEFAULT
  | Steps.GAME_SETUP_DEFAULT
  | Steps.GAME_CREDITS_DEFAULT
  | Steps.GAME_STORY_DEFAULT

export interface ISetup {
  name: string
  rocket: RocketType
  hero: HeroType
}

export interface IGame {
  gold: number
  name: string | null
  rocket: RocketType | null
  hero: HeroType | null
  currentMission: MissionType | null
  packs: string[]
  missions: MissionType[]
  badges: BadgeType[]
  heroes: HeroType[]
  rockets: RocketType[]
}

export interface IExerciseResponse {
  exercise: {
    properties: IExerciseProperties
  }
}

export interface IExercise {
  id: string
  type: ExerciseType
}

export interface IPackResponse {
  pack: {
    exercises: IExercise[]
  }
}

export interface IPack {
  id: string
  type: PackType
  name: string
}

export interface IZoneResponse {
  zone: {
    packs: IPack[]
  }
}

export interface IZone {
  id: string
  type: ZoneType
}

export interface IMissionResponse {
  mission: {
    zones: IZone[]
  }
}

export interface IMission {
  id: string
  type: MissionType
}
export interface IConfiguration {
  sem: boolean
  feedback: boolean
  time: boolean
  missions: IMission[]
  enabled_data: IConfigurationEnableData
}
export interface IGroup {
  title: string
  school: string
  city: string
  description: string
  configuration: IConfiguration
}
export interface IGameInfoResponse {
  user: {
    id: string
    username: string
    extrauser: {
      id: string
      game: IGame | null
      group: IGroup
    }
  }
}

export interface IGameData {
  id: MissionType | BadgeType | HeroType | RocketType
  name: string
  image: string
  description: string
  price?: number
  textureUrl?: string
  modelUrl?: string
}

export interface IOptionPlanet {
  x: number
  y: number
  scale: number
  speed: number
  modelUrl: string
  textureUrl: string
  disabledTextureUrl: string
  id: MissionType
}

export interface IOptionRocket {
  x: number
  y: number
  scale: number
  modelUrl: string
  textureUrl: string
}

export interface IMissionsConfiguration {
  missions?: {
    [key in MissionType]: {
      zones: {
        [key in ZoneType]: {
          packs: {
            [key in PackType]: {
              name: string
              exercises: {
                [key in ExerciseType]: IExercise
              }
            }
          }
        }
      }
    }
  }
}

export interface IWorldOptions {
  playerModel: string
  playerTexture: string
  enemyModel: string
  enemyTexture: string
  itemModel: string
  itemTexture: string
  rocketTexture: string
  scenario: string
  world: string
}

export interface IEnableExercises {
  [Exercises.EXERCISE1]: {
    show: boolean
  }
  [Exercises.EXERCISE2]: {
    show: boolean
  }
  [Exercises.EXERCISE3]: {
    show: boolean
  }
  [Exercises.EXERCISE4]: {
    show: boolean
  }
  [Exercises.EXERCISE5]: {
    show: boolean
  }
  [Exercises.EXERCISE6]: {
    show: boolean
  }
}

export interface IEnablePacks {
  [Packs.PACK1]: {
    show: boolean
    exercises: IEnableExercises
  }
  [Packs.PACK2]: {
    show: boolean
    exercises: IEnableExercises
  }
  [Packs.PACK3]: {
    show: boolean
    exercises: IEnableExercises
  }
  [Packs.PACK4]: {
    show: boolean
    exercises: IEnableExercises
  }
  [Packs.PACK5]: {
    show: boolean
    exercises: IEnableExercises
  }
  [Packs.PACK6]: {
    show: boolean
    exercises: IEnableExercises
  }
  [Packs.PACK7]: {
    show: boolean
    exercises: IEnableExercises
  }
}

export interface IEnableZones {
  [Zones.ZONE1]: {
    show: boolean
    packs: IEnablePacks
  }
  [Zones.ZONE2]: {
    show: boolean
    packs: IEnablePacks
  }
  [Zones.ZONE3]: {
    show: boolean
    packs: IEnablePacks
  }
  [Zones.ZONE4]: {
    show: boolean
    packs: IEnablePacks
  }
  [Zones.ZONE5]: {
    show: boolean
    packs: IEnablePacks
  }
  [Zones.ZONE6]: {
    show: boolean
    packs: IEnablePacks
  }
  [Zones.ZONE7]: {
    show: boolean
    packs: IEnablePacks
  }
}

export interface IConfigurationEnableData {
  showGame: boolean
  visibility: {
    [Missions.ADENDUS]: {
      show: boolean
      zones: IEnableZones
    }
    [Missions.DIMINUS]: {
      show: boolean
      zones: IEnableZones
    }
    [Missions.MULTIPLIS]: {
      show: boolean
      zones: IEnableZones
    }
    [Missions.FRACTA]: {
      show: boolean
      zones: IEnableZones
    }
    [Missions.DIVISIA]: {
      show: boolean
      zones: IEnableZones
    }
    [Missions.QUESTIA]: {
      show: boolean
      zones: IEnableZones
    }
    [Missions.HYBRIDIA]: {
      show: boolean
      zones: IEnableZones
    }
    [Missions.TERRA]: {
      show: boolean
      zones: IEnableZones
    }
  }
}
