import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  show: string
  hide: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    show: 'Show',
    hide: 'Hide',
  },
  [languageCodes.ro_RO]: {
    show: 'Arată',
    hide: 'Ascunde',
  },
}

export default localeData
