import cx from 'classnames'
import styles from './empty.module.scss'

interface IProps {
  className?: string
  children?: JSX.Element[] | JSX.Element | string | undefined
}

/**
 * @function Error
 * @param { JSX.Element[] | JSX.Element | string } children
 * @param { String } className
 */
export function Empty({ children, className }: IProps): JSX.Element {
  return (
    <div
      className={`${cx(
        styles.empty,
        className
      )} d-flex justify-content-center align-items-center`}
    >
      {children}
    </div>
  )
}
