export enum TreeNodeEnum {
  LIST = 'list',
  LEAF_IMAGE = 'leaf-image',
  LEAF_AUDIO = 'leaf-audio',
  LEAF_DEFAULT = 'leaf-default',
}

export type TreeNodeType =
  | TreeNodeEnum.LEAF_IMAGE
  | TreeNodeEnum.LEAF_AUDIO
  | TreeNodeEnum.LEAF_DEFAULT
  | TreeNodeEnum.LIST

export interface ITreeNode {
  type: TreeNodeType
  label: string
  children?: ITreeNode[]
  url?: string
}
