import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  exerciseTitle: string
  errorFetchExercise: string
  errorRenderExercise: string
  loadingExercise: string
  editButton: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    exerciseTitle: 'View Exercise',
    errorRenderExercise: 'There is no exercise available',
    errorFetchExercise: 'There was an error while fetching the exercise',
    loadingExercise: 'Loading Exercise',
    editButton: 'Edit Exercise',
  },
  [languageCodes.ro_RO]: {
    exerciseTitle: 'Vizualizare Exercițiu',
    errorRenderExercise: 'Nu există un exercițiu disponibil',
    errorFetchExercise: 'A apărut o eroare în timpul primirii exercițiului',
    loadingExercise: 'Încărcare Exercițiu',
    editButton: 'Editare Exercițiu',
  },
}

export default localeData
