import React from 'react'
import { Roles } from './core/@types/general'
import { AuthenticatedRoute } from './core/custom-route/AuthenticatedRoute'
import { UnauthenticatedRoute } from './core/custom-route/UnauthenticatedRoute'
import {
  PROFILE,
  EXERCISES,
  EXERCISES_EDIT,
  LOGIN,
  HOME,
  TEAM,
  REPORTS,
  EXERCISES_VIEW,
  CREDITS,
  SETUP,
  MISSIONS,
  STORY,
  GAME,
  CONFIGURATIONS,
} from './core/constants/routes'
import { Home } from './pages/home'
import { Exercises } from './pages/exercises'
import { ExerciseEdit } from './pages/exercise-edit'
import { ExerciseView } from './pages/exercise-view'
import { Reports } from './pages/reports'
import { Profile } from './pages/profile'
import { Login } from './pages/login'
import { Team } from './pages/team'
import { Credits } from './pages/credits'
import { Setup } from './pages/setup'
import { Missions } from './pages/missions'
import { Story } from './pages/story'
import { Game } from './pages/game'
import { Configurations } from './pages/configurations'

export interface IRoute {
  route: typeof AuthenticatedRoute | typeof UnauthenticatedRoute
  path: string
  component: React.FC
  roles: string[]
}

export const AppRoutes: IRoute[] = [
  {
    route: AuthenticatedRoute,
    path: GAME,
    component: Game,
    roles: [Roles.AUTHENTICATED],
  },
  {
    route: AuthenticatedRoute,
    path: CREDITS,
    component: Credits,
    roles: [Roles.AUTHENTICATED],
  },
  {
    route: AuthenticatedRoute,
    path: STORY,
    component: Story,
    roles: [Roles.AUTHENTICATED],
  },
  {
    route: AuthenticatedRoute,
    path: MISSIONS,
    component: Missions,
    roles: [Roles.AUTHENTICATED],
  },
  {
    route: AuthenticatedRoute,
    path: SETUP,
    component: Setup,
    roles: [Roles.AUTHENTICATED],
  },
  {
    route: AuthenticatedRoute,
    path: PROFILE,
    component: Profile,
    roles: [Roles.AUTHENTICATED],
  },
  {
    route: AuthenticatedRoute,
    path: `${EXERCISES_EDIT}/:id`,
    component: ExerciseEdit,
    roles: [Roles.ADMIN],
  },
  {
    route: AuthenticatedRoute,
    path: `${EXERCISES_VIEW}/:id`,
    component: ExerciseView,
    roles: [Roles.ADMIN],
  },
  {
    route: AuthenticatedRoute,
    path: CONFIGURATIONS,
    component: Configurations,
    roles: [Roles.ADMIN],
  },
  {
    route: AuthenticatedRoute,
    path: EXERCISES,
    component: Exercises,
    roles: [Roles.ADMIN],
  },
  {
    route: AuthenticatedRoute,
    path: REPORTS,
    component: Reports,
    roles: [Roles.ADMIN],
  },
  {
    route: UnauthenticatedRoute,
    path: LOGIN,
    component: Login,
    roles: [],
  },
  {
    route: UnauthenticatedRoute,
    path: TEAM,
    component: Team,
    roles: [],
  },
  {
    route: UnauthenticatedRoute,
    path: HOME,
    component: Home,
    roles: [],
  },
]
