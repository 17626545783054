import { gql } from '@apollo/client'

export const updateGameMutation = gql`
  mutation updateGameMutation($id: ID!, $game: JSON!) {
    updateExtrauser(input: { where: { id: $id }, data: { game: $game } }) {
      extrauser {
        _id
      }
    }
  }
`

export const updateConfigurationMutation = gql`
  mutation updateConfigurationMutation($id: ID!, $enabledData: JSON!) {
    updateConfiguration(input: { where: { id: $id }, data: { enabled_data: $enabledData } }) {
      configuration {
        _id
      }
    }
  }
`
