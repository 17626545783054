import { useEffect, useCallback, useState, forwardRef } from 'react'
import { preloadImage } from '../../../core/utils/preloadImage'
import { Loading } from '../loading';
import { PLACEHOLDER_IMAGE } from '../../../core/constants/resources';

interface IProps {
  id?: string
  className?: string
  src?: string
  alt: string
  title?: string
  onSuccess?: (url: string) => void
  onError?: (error: Error) => void
  placeholder?: string
  loadingSize?: number
}

export const Image = forwardRef<HTMLImageElement, IProps>(function (
  props,
  ref
): JSX.Element {
  const { id, className, src, alt, title, placeholder, loadingSize, onSuccess, onError } =
    props
  const [imageState, setImageState] = useState<{
    loading: boolean
    error: boolean
  }>({
    loading: true,
    error: false,
  })
  const hasError = imageState.error || !src
  const placeholderImage = placeholder || PLACEHOLDER_IMAGE
  const imageSrc = hasError ? placeholderImage : src

  const preload = useCallback(async () => {
    if (src) {
      try {
        await preloadImage(src)
        setImageState({
          loading: false,
          error: false,
        })
        onSuccess && onSuccess(src)
      } catch (error) {
        setImageState({
          loading: false,
          error: true,
        })
        onError && onError(error as Error)
      }
    }
  }, [src, onError, onSuccess])

  useEffect(() => {
    preload()
  }, [preload])

  if (imageState.loading) {
    return <Loading size={loadingSize} />;
  }

  return (
    <img
      id={id}
      className={className}
      ref={ref}
      src={imageSrc}
      alt={alt}
      title={title}
    />
  )
})
