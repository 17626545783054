import { gql } from '@apollo/client'

export const getConfigurations = gql`
  query Configurations($start: Int) {
    configurations(start: $start) {
      _id
      name
      title
      enabled_data
    }
  }
`
