import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  title: string
  titleDescription: string
  description: string
  titleTeam: string
  titleDeveloped: string
  profileButton: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    title: 'Matematrols',
    titleDescription: 'Description',
    description: 'Anxiety Game for Mathematics in Schools',
    titleTeam: 'Team',
    titleDeveloped: 'Developed under',
    profileButton: 'Profile',
  },
  [languageCodes.ro_RO]: {
    title: 'Matematroli',
    titleDescription: 'Descriere',
    description: 'Joc Anxietate pentru Matematica din Școli',
    titleTeam: 'Echipa',
    titleDeveloped: 'Dezvoltat folosind',
    profileButton: 'Profil',
  },
}

export default localeData
