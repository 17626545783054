import { languageCodes } from '../../../../core/@types/general'

export interface ILocaleProps {
  loadingMedia: string
  select: string
  selectImage: string
  selectAudio: string
  noAudioSupport: string
  refresh: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    loadingMedia: 'Loading Media Resources',
    select: 'Select',
    selectImage: 'Select Image',
    selectAudio: 'Select Audio',
    noAudioSupport: 'There is no audio support',
    refresh: 'Refresh Resources',
  },
  [languageCodes.ro_RO]: {
    loadingMedia: 'Încarcă resursele media',
    select: 'Selectează',
    selectImage: 'Selectează Imagine',
    selectAudio: 'Selectează Audio',
    noAudioSupport: 'Nu există suport audio',
    refresh: 'Actualizează resursele',
  },
}

export default localeData
