import { getStorage, removeAppStorage } from '../storage'
const storageVersion = 2

// storage settings
const JWT_TOKEN_KEY = 'matematrolii_jwt_token'
const USER_INFO_KEY = 'matematrolii_user_info'
const GAME_INFO_KEY = 'matematrolii_game_info'
const MATEMATROLII_DEV_KEY = 'matematrolii_dev'
const GDPR_KEY = 'matematrolii_gdpr'
const LANGUAGE_CODE_KEY = 'matematrolii_language_code'
const BUCKET_RESOURCES_KEY = 'matematrolii_s3_resources'
export const JWT_TOKEN = `${JWT_TOKEN_KEY}_${storageVersion}`
export const USER_INFO = `${USER_INFO_KEY}_${storageVersion}`
export const GAME_INFO = `${GAME_INFO_KEY}_${storageVersion}`
export const MATEMATROLII_DEV = `${MATEMATROLII_DEV_KEY}_${storageVersion}`
export const GDPR = `${GDPR_KEY}_${storageVersion}`
export const LANGUAGE_CODE = `${LANGUAGE_CODE_KEY}_${storageVersion}`
export const BUCKET_RESOURCES = `${BUCKET_RESOURCES_KEY}_${storageVersion}`

// clean storage old versions after new deploy
const gdprStorage = getStorage(`${GDPR_KEY}`) || getStorage(`${GDPR_KEY}_${storageVersion-1}`);
if (gdprStorage !== null) {
  removeAppStorage()
}

// api settings
export const API_URL = 'https://admin.matematrolii.com'
export const FETCH_POLICY = 'no-cache'

// debug settings
export const DEBUG = true

// s3 bucket settings
export const AWS_S3_URL = 'https://matematroli.s3.eu-central-1.amazonaws.com'
export const AWS_BUCKET_NAME = 'matematroli'
export const AWS_SECRET_ACCESS_KEY = 'Doo/ccV4J2Oa8Ut60O0hShTiBAcSPuXsjvHio17M'
export const AWS_REGION = 'eu-central-1'
export const AWS_ACCESS_KEY_ID = 'AKIAIVHVBF7XZZM6ZQZQ'
export const EXTENSIONS = {
  image: ['jpg', 'png', 'svg', 'webp', 'jpeg', 'jfif'],
  audio: ['mp3'],
}
