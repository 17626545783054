import { IGame } from '../@types/game'

export const gameData: IGame = {
  gold: 0,
  name: null,
  rocket: null,
  hero: null,
  currentMission: null,
  missions: [],
  packs: [],
  badges: [],
  heroes: [],
  rockets: [],
}
