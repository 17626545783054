import { Skeleton } from '../../../../../../components/atoms/skeleton'
import { ErrorBox } from '../../../../../../components/atoms/error-box'
import styles from './mission-skeleton.module.scss'

interface IProps {
  hideTime?: boolean
  hideProgress?: boolean
  hideExercise?: boolean
  hideZones?: boolean
  loadingMessage?: string
  errorMessage?: string
}

export function MissionSkeleton({
  hideTime,
  hideProgress,
  hideExercise,
  hideZones,
  loadingMessage,
  errorMessage,
}: IProps) {
  return (
    <div className="px-3">
      {!hideTime && (
        <div className="d-flex py-3">
          <div className="col-12 d-flex justify-content-between align-items-end">
            <Skeleton height={35} />
          </div>
        </div>
      )}
      {!hideProgress && (
        <div className="d-flex">
          <div className="col-12 py-3">
            <Skeleton height={30} />
          </div>
        </div>
      )}
      {!hideExercise && (
        <div className="d-flex">
          <div className="col-12">
            {errorMessage ? (
              <div className={`${styles.errorMessage} p-5`}>
                <ErrorBox>{errorMessage}</ErrorBox>
              </div>
            ) : (
              <Skeleton height={300} message={loadingMessage} />
            )}
          </div>
        </div>
      )}
      {!hideZones && (
        <div className="d-flex py-3">
          <div className="col-12 d-flex">
            <Skeleton height={59} />
          </div>
        </div>
      )}
    </div>
  )
}
