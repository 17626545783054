import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { API_URL, JWT_TOKEN } from '../../constants/general'
import { IToken } from '../../@types/auth'
import { getStorage } from '../../storage'

// Log any GraphQL errors or network error that occurred
const httpLink = createHttpLink({
  uri: `${API_URL}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token: IToken | undefined = getStorage(JWT_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token?.jwt}` : '',
    },
  }
})

const link = from([authLink, httpLink])

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
})
