import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  title: string
  description: string
  homeButton: string
  gabiTitle: string
  lauraTitle: string
  ioanaTitle: string
  laviniaTitle: string
  monicaTitle: string
  siminaTitle: string
  alinaTitle: string
  lizicaTitle: string
  thomasTitle: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    title: 'Here is the team',
    description:
      'Reducing math anxiety during early elementary school: An intervention based on cognitive tutoring versus a working memory training program and their impact on number sense development',
    homeButton: 'Back to homepage',
    gabiTitle: 'UI Architect',
    lauraTitle: 'Associate Professor',
    ioanaTitle: 'Associate Professor',
    laviniaTitle: 'Lecturer',
    monicaTitle: 'PhD Student',
    siminaTitle: "Master's Student",
    alinaTitle: "Master's Student",
    lizicaTitle: "Master's Student",
    thomasTitle: 'Senior Lecturer in Psychology',
  },
  [languageCodes.ro_RO]: {
    title: 'Aici este echipa',
    description:
      'Reducerea anxietății matematice în timpul școlii elementare timpurii: o intervenție bazată pe îndrumare cognitivă versus un program de formare a memoriei de lucru și impactul acestora asupra dezvoltării simțului numeric',
    homeButton: 'Înapoi la pagina home',
    gabiTitle: 'Arhitect UI',
    lauraTitle: 'Profesor Asociat',
    ioanaTitle: 'Profesor Asociat',
    laviniaTitle: 'Lector',
    monicaTitle: 'Student PhD',
    siminaTitle: "Student Master",
    alinaTitle: "Student Master",
    lizicaTitle: "Student Master",
    thomasTitle: 'Lector Senior în Psihologie',
  },
}

export default localeData
