import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'
import { GeneralContext } from '../../../core/context/generalContext'
import { EXERCISES, REPORTS, CONFIGURATIONS } from '../../../core/constants/routes'
import { MenuItem } from '../../../core/@types/general'
import { Logout16, User16 } from '@carbon/icons-react'
import locales, { ILocaleProps } from './lib/locales'
import { LOGO_IMAGE } from '../../../core/constants/resources'
import { Image } from '../image'
import styles from './admin-header.module.scss'

interface IProps {
  className?: string
}



/**
 * @function AdminHeader
 * @param items
 * @param className
 */
export function AdminHeader({ className }: IProps): JSX.Element {
  const location = useLocation()
  const { languageCode, logout, userInfo } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]
  const items: MenuItem[] = [
    {
      url: EXERCISES,
      name: locale?.exercises,
    },
    {
      url: REPORTS,
      name: locale?.reports,
    },
    {
      url: CONFIGURATIONS,
      name: locale?.configurations,
    },
  ]
  return (
    <div className={`${cx(styles.header, className)} p-3`}>
      <div className="row justify-content-between align-items-center">
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-start">
          <Link to={EXERCISES} className={styles.item}>
            <Image
              className={styles.logo}
              src={LOGO_IMAGE}
              alt="logo"
              loadingSize={30}
            />
          </Link>
          {items.map((item) => {
            const isSelected = location.pathname.indexOf(item.url) !== -1
            return (
              <Link
                to={item.url}
                key={item.name}
                className={cx(styles.item, {
                  [styles.selected]: isSelected,
                })}
              >
                {item.name}
              </Link>
            )
          })}
        </div>
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-end">
          <div className={`${styles.item} d-flex align-items-center`}>
            <User16 />
            <span>{userInfo?.username}</span>
          </div>
          <div
            onClick={() => logout()}
            className={`${styles.item} d-flex align-items-center`}
          >
            <Logout16 />
            <span>{locale.signOut}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
