import React, { useContext, useState } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
import { InputField } from '../../components/atoms/input-field'
import { Title } from '../../components/atoms/title'
import { Image } from '../../components/atoms/image'
import { useForm, Controller } from 'react-hook-form'
import { GeneralContext } from '../../core/context/generalContext'
import { heroData } from '../../core/game-data/heroes'
import { rocketsData } from '../../core/game-data/rockets'
import { Heroes, Rockets } from '../../core/@types/game'
import { Button } from '../../components/atoms/button'
import { GameConsole16, ViewNext24 } from '@carbon/icons-react'
import { ISetup } from '../../core/@types/game'
import { gameData } from '../../core/game-data/game'
import { ErrorBox } from '../../components/atoms/error-box'
import { ErrorMessage } from '../../components/atoms/error-message'
import { STORY } from '../../core/constants/routes'
import { BACKGROUND_SPACE_BACKGROUND, PLACEHOLDER_IMAGE } from '../../core/constants/resources'
import locales from './lib/locales'
import styles from './setup.module.scss'

/**
 * @function Setup
 */
export function Setup(): JSX.Element {
  const history = useHistory()
  const { languageCode, updateGameInfo } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const { register, errors, handleSubmit, control } = useForm()
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  async function onSubmit(data: ISetup) {
    setError(null)
    try {
      setLoading(true)
      await updateGameInfo({
        ...gameData,
        name: data.name,
        rocket: data.rocket,
        hero: data.hero,
        rockets: [data.rocket],
        heroes: [data.hero],
      })
      setLoading(false)
      history.push(STORY)
    } catch (err) {
      console.error(err)
      setLoading(false)
      setError(locale?.errorSetupUpdate)
    }
  }

  return (
    <div
      className={styles.setup}
      style={{ backgroundImage: `url(${BACKGROUND_SPACE_BACKGROUND})` }}
    >
      <div className="row">
        <form className="offset-4 col-4 py-5" onSubmit={handleSubmit(onSubmit)}>
          {error && <ErrorBox>{error}</ErrorBox>}
          <Title
            className={`${styles.label} mb-3`}
            headingLevel={4}
            title={`${locale?.nameTitle}:`}
          />
          <InputField
            className="mb-5"
            name="name"
            type="text"
            ref={register({
              required: {
                value: true,
                message: locale?.requiredName,
              },
            })}
            placeholder={locale?.namePlaceholder}
            error={errors?.name?.message}
            hasValidation
          />
          <Title
            className={`${styles.label} mb-3`}
            headingLevel={4}
            title={`${locale?.costumeTitle}:`}
          />
          {errors?.hero && <ErrorMessage>{errors?.hero?.message}</ErrorMessage>}
          <Controller
            control={control}
            name="hero"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: locale?.requiredCostume,
              },
            }}
            render={({ onChange, value }) => (
              <div className="row mb-5">
                <div
                  onClick={() => onChange(Heroes.HERO_OXY)}
                  className={cx(`${styles.card} col-6 py-3`, {
                    [styles.selected]: value === Heroes.HERO_OXY,
                  })}
                >
                  <Image
                    src={heroData[Heroes.HERO_OXY].image}
                    alt={Heroes.HERO_OXY}
                  />
                </div>
                <div
                  onClick={() => onChange(Heroes.HERO_NANO)}
                  className={cx(`${styles.card} col-6 py-3`, {
                    [styles.selected]: value === Heroes.HERO_NANO,
                  })}
                >
                  <Image
                    src={heroData[Heroes.HERO_NANO].image}
                    alt={Heroes.HERO_NANO}
                  />
                </div>
              </div>
            )}
          />
          <Title
            className={`${styles.label} mb-3`}
            headingLevel={4}
            title={`${locale?.rocketTitle}:`}
          />
          {errors?.rocket && (
            <ErrorMessage>{errors?.rocket?.message}</ErrorMessage>
          )}
          <Controller
            control={control}
            name="rocket"
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: locale?.requiredRocket,
              },
            }}
            render={({ onChange, value }) => (
              <div className="row mb-5">
                <div
                  className={cx(`${styles.card} col-6 py-3`, {
                    [styles.selected]: value === Rockets.ROCKET_APOLLO,
                  })}
                  onClick={() => onChange(Rockets.ROCKET_APOLLO)}
                >
                  <Image
                    src={rocketsData[Rockets.ROCKET_APOLLO].image}
                    alt={Rockets.ROCKET_APOLLO}
                  />
                </div>
                <div
                  onClick={() => onChange(Rockets.ROCKET_SKYLAB)}
                  className={cx(`${styles.card} col-6 py-3`, {
                    [styles.selected]: value === Rockets.ROCKET_SKYLAB,
                  })}
                >
                  <Image
                    src={rocketsData[Rockets.ROCKET_SKYLAB].image}
                    alt={Rockets.ROCKET_SKYLAB}
                  />
                </div>
              </div>
            )}
          />
          <Button
            type="submit"
            isLoading={loading}
            title={
              <>
                <GameConsole16 />
                <span>{locale?.startButton}</span>
              </>
            }
            loadingTitle={
              <>
                <ViewNext24 />
                <span>{locale?.loadingButton}</span>
              </>
            }
          />
        </form>
      </div>
    </div>
  )
}
