import { client } from './graphql/client'
import { updateGameMutation, updateConfigurationMutation } from './graphql/mutations/game'
import { FETCH_POLICY } from '../constants/general'

export async function updateGame(id: string, game: any) {
  const gameData = await client.mutate({
    mutation: updateGameMutation,
    fetchPolicy: FETCH_POLICY,
    variables: {
      id,
      game,
    },
  })
  return gameData
}

export async function updateConfiguration(id: string, enabledData: any) {
  const gameData = await client.mutate({
    mutation: updateConfigurationMutation,
    fetchPolicy: FETCH_POLICY,
    variables: {
      id,
      enabledData,
    },
  })
  return gameData
}
