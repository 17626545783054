import { useState, useContext } from 'react'
import { GDPR } from '../../../core/constants/general'
import { getStorage, setStorage } from '../../../core/storage'
import { IGDPR } from '../../../core/@types/general'
import { GeneralContext } from '../../../core/context/generalContext'
import { AdminButton } from '../admin-button'
import locales, { ILocaleProps } from './lib/locales'
import styles from './gdpr-notification.module.scss'

const gdprData: IGDPR = getStorage(GDPR)

export function GDPRNotification() {
  const [showGDPR, setShowGDPR] = useState<boolean>(!gdprData?.checked)
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]

  function onClick() {
    setShowGDPR(false)
    setStorage({ checked: true }, GDPR, true)
  }

  return showGDPR ? (
    <div className={styles.gdpr}>
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center">
            <p className={styles.textBlock}>{locale?.message}</p>
            <AdminButton
              type="button"
              className={styles.button}
              onClick={onClick}
            >
              {locale?.acceptButton}
            </AdminButton>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
