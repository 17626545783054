// introduction
export const HOME = '/'
export const NOT_FOUND = '/404'
export const TEAM = '/team'
export const LOGIN = '/login'

// admin routes
export const EXERCISES = '/exercises'
export const EXERCISES_VIEW = '/exercises/view'
export const EXERCISES_EDIT = '/exercises/edit'
export const REPORTS = '/reports'
export const CONFIGURATIONS = '/configurations'

// profile routes
export const GAME = '/game'
export const PROFILE = '/profile'
export const MISSIONS = '/missions'
export const STORY = '/story'
export const SETUP = '/setup'
export const CREDITS = '/credits'
