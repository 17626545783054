import { useLayoutEffect, useState } from 'react'
import { AuthenticatedRoute } from './core/custom-route/AuthenticatedRoute'
import { UnauthenticatedRoute } from './core/custom-route/UnauthenticatedRoute'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { GDPRNotification } from './components/atoms/gdpr-notification'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './core/api/graphql/client'
import { FileNotFound } from './pages/file-not-found'
import { DEBUG, GAME_INFO } from './core/constants/general'
import { GeneralContextProvider } from './core/context/generalContext'
import { IUserInfo, IToken } from './core/@types/auth'
import { IGameInfoResponse } from './core/@types/game'
import { getStorage } from './core/storage'
import { JWT_TOKEN, USER_INFO } from './core/constants/general'
import { AppRoutes } from './AppRoutes'
import './core/scss/style.scss'

const userStored: IUserInfo | null = getStorage(USER_INFO)
const jwtTokenStored: IToken | null = getStorage(JWT_TOKEN)
const gameInfoStored: IGameInfoResponse | null = getStorage(GAME_INFO)

export default function App() {
  const [app, setApp] = useState<{
    userInfo: IUserInfo | null
    gameInfo: IGameInfoResponse | null
    jwtToken: IToken | null
  }>({
    jwtToken: jwtTokenStored,
    userInfo: userStored,
    gameInfo: gameInfoStored,
  })
  const isUnauthenticated = !app.userInfo || !app.jwtToken
  const userRole = app?.userInfo?.role?.name || null
  const game = app?.gameInfo?.user?.extrauser?.game || null

  useLayoutEffect(() => {
    if (!DEBUG) {
      if (!window.console) {
        ;(window.console as any) = {}
      }
      ;['log', 'debug', 'warn', 'info'].forEach(
        (method) => ((window.console as any)[method] = function () {})
      )
    }
    ;(window as any).cancelRequestAnimFrame = (function () {
      return (
        window.cancelAnimationFrame ||
        (window as any).webkitCancelRequestAnimationFrame ||
        (window as any).mozCancelRequestAnimationFrame ||
        (window as any).oCancelRequestAnimationFrame ||
        (window as any).msCancelRequestAnimationFrame ||
        clearTimeout
      )
    })()
  }, [])

  const renderRoute = (
    Route: typeof AuthenticatedRoute | typeof UnauthenticatedRoute,
    path: string,
    Component: React.FC,
    accessRoles: string[]
  ): JSX.Element => {
    return (
      <Route
        path={path}
        exact
        role={userRole}
        game={game}
        accessRoles={accessRoles}
        isUnauthenticated={isUnauthenticated}
      >
        <Component />
      </Route>
    )
  }

  return (
    <ApolloProvider client={client}>
      <Router>
        <GeneralContextProvider app={app} setApp={setApp}>
          <GDPRNotification />
          <Switch>
            {AppRoutes.map((appRoute) =>
              renderRoute(
                appRoute.route,
                appRoute.path,
                appRoute.component,
                appRoute.roles
              )
            )}
            <Route component={FileNotFound} />
          </Switch>
        </GeneralContextProvider>
      </Router>
    </ApolloProvider>
  )
}
