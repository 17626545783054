import { useRef, useEffect, useContext } from 'react'
import { MissionContextProvider } from '../../core/context/missionContext'
import { GeneralContext } from '../../core/context/generalContext'
import { MissionType } from '../../core/@types/game'
import { missionsData } from '../../core/game-data/missions'
import { GameHeader } from '../../components/atoms/game-header'
import { Canvas } from '../../components/atoms/canvas'
import { Galaxy } from './components/galaxyData'
import { Mission } from './components/mission'
import { MissionMenu } from './components/mission-menu'
import { BACKGROUND_SPACE_BACKGROUND } from '../../core/constants/resources'
import styles from './missions.module.scss'
import { ROCKET_NAVIGATION_EVENT } from '../../core/constants/game'

export function Missions() {
  const { gameInfo } = useContext(GeneralContext)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const galaxy = useRef<any>(null)
  const game = gameInfo?.user.extrauser.game

  function navigateToMission(mission: MissionType) {
    const missionData = missionsData[mission]
    const event = new CustomEvent(ROCKET_NAVIGATION_EVENT, {
      detail: {
        x: missionData.x,
        y: missionData.y,
      },
    })
    window.dispatchEvent(event)
  }

  function cleanupCanvas() {
    if (galaxy.current) {
      galaxy.current.destroy()
    }
  }

  useEffect(() => {
    if (!galaxy.current && canvasRef.current && game) {
      galaxy.current = new Galaxy(game, canvasRef.current)
    }
  }, [game])

  return (
    <div
      className={styles.missions}
      style={{ backgroundImage: `url(${BACKGROUND_SPACE_BACKGROUND})` }}
    >
      <GameHeader />
      <MissionContextProvider>
        <Mission cleanupCanvas={cleanupCanvas} />
        <MissionMenu navigateToMission={navigateToMission} />
      </MissionContextProvider>
      <Canvas ref={canvasRef} />
    </div>
  )
}
