import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  title: string
  message: string
  button: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    title: "Mission Failed",
    message: "You were attacked by a Matematrol and you must regain your strength. Try again",
    button: "Return to missions"
  },
  [languageCodes.ro_RO]: {
    title: "Misiune Eșuată",
    message: "Ai fost atacat de un Matematrol și trebuie să îți recapeți puterile. Încearcă din nou",
    button: "Înapoi la misiuni"
  },
}

export default localeData
