import { useContext, useState, useEffect, useMemo, useRef } from 'react'
import { AdminHeader } from '../../components/atoms/admin-header'
import { Title } from '../../components/atoms/title'
import { AdminButton } from '../../components/atoms/admin-button'
import { Loading } from '../../components/atoms/loading'
import { Progress } from '../../components/atoms/progress'
import { CSVLink } from 'react-csv'
import { Download16, Clean16 } from '@carbon/icons-react'
import { useVirtualRendering } from '../../core/hooks/useVirtualRendering'
import dayjs from 'dayjs'
import { GeneralContext } from '../../core/context/generalContext'
import { IHistoryItem, IHistoryItemResult } from '../../core/@types/exercise'
import { ErrorBox } from '../../components/atoms/error-box'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { deleteHistory } from '../../core/api/graphql/mutations/exercise'
import { getHistories } from '../../core/api/graphql/queries/exercise'
import { generateErrors } from '../../core/utils/generateErrors'
import { Dropdown, IOption } from '../../components/atoms/dropdown'

import locales, { ILocaleProps } from './lib/locales'
import styles from './reports.module.scss'

/**
 * @function Reports
 */
export function Reports(): JSX.Element {
  const [progressValue, setProgressValue] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const [deleteHistoryRecord] = useMutation(deleteHistory)
  const [historyList, setHistoryList] = useState<IHistoryItemResult[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isDeletingUserHistory, setIsDeletingUserHistory] =
    useState<boolean>(false)
  const [fetchHistories, { data, error }] = useLazyQuery(getHistories)
  const errorsMessages = useMemo(() => {
    return generateErrors(error) || []
  }, [error])
  const { containerHeight, getTopPosition, onScroll, renderedList } =
    useVirtualRendering({
      list: historyList,
      rowHeight: 200,
      rowsBuffered: 2,
      totalRows: historyList.length,
      viewportHeight: 600,
    })
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]
  const headers = [
    { label: locale?.userId, key: 'userId' },
    { label: locale?.user, key: 'userName' },
    { label: locale?.exerciseId, key: 'exerciseId' },
    { label: locale?.exercise, key: 'exerciseTitle' },
    { label: locale?.passed, key: 'isCorrect' },
    { label: locale?.value, key: 'pickedValue' },
    { label: locale?.time, key: 'timeSpent' },
    { label: locale?.attempts, key: 'attempts' },
    { label: locale?.created, key: 'createdAt' },
  ]
  const now = dayjs().format('MM/DD/YYYY HH:mm:ss')
  const dropdownItems = useMemo<IOption[]>(() => {
    if (historyList.length > 0) {
      const userMap = historyList.reduce((map, item: IHistoryItemResult) => {
        return {
          ...map,
          [item.userId]: item.userName,
        }
      }, {})
      return Object.entries(userMap).map(([key, value]) => ({
        value: key,
        title: value,
      })) as IOption[]
    }
    return []
  }, [historyList])
  const [selectedUser, setSelectedUser] = useState<string>('')

  useEffect(() => {
    setIsLoading(true)
    fetchHistories({
      variables: { start: 0 },
    })
  }, [fetchHistories])

  useEffect(() => {
    if (data && data.histories && data.histories.length === 0) {
      setIsLoading(false)
    }
    if (data && data.histories && data.histories.length > 0) {
      const getValue = (value: string) => {
        if (!value) {
          return locale?.notAvailable
        }
        const newValue = value.trim()
        return newValue ? newValue : locale?.notAvailable
      }

      const getCorrect = (isCorrect: boolean) => {
        return isCorrect ? locale?.correctYes : locale?.correctNo
      }

      const getTimeSpent = (time: number) => {
        if (!time) {
          return locale?.notAvailable
        }
        const minutes = Math.floor(time / 60)
        const seconds = time - minutes * 60
        const labelMinute = minutes > 1 ? locale?.minutes : locale?.minute
        const labelSecond = seconds > 1 ? locale?.seconds : locale?.second

        if (minutes > 0 && seconds > 0) {
          return `${minutes} ${labelMinute}, ${seconds} ${labelSecond}`
        }
        if (minutes > 0) {
          return `${minutes} ${labelMinute}`
        }
        if (seconds > 0) {
          return `${seconds} ${labelSecond}`
        }
        return locale?.notAvailable
      }

      const getDate = (date: string) => {
        if (!date) {
          return locale?.notAvailable
        }
        return dayjs(date).format('MM/DD/YYYY HH:mm:ss')
      }
      setHistoryList((oldHistoriesList) => {
        const newHistoriesList = [
          ...oldHistoriesList,
          ...data.histories.map((item: IHistoryItem) => {
            const exercise = item.exercise || {
              title: '',
              id: '',
            }
            const user = item.extrauser || {
              id: '',
              firstName: '',
              lastName: '',
            }
            const historyData = {
              historyId: item._id,
              userId: getValue(user.id),
              userName: `${getValue(user.firstName)} ${getValue(
                user.lastName
              )}`,
              exerciseId: getValue(exercise.id),
              exerciseTitle: getValue(exercise.title),
              isCorrect: getCorrect(item.isCorrect),
              pickedValue: getValue(item.pickedValue),
              timeSpent: getTimeSpent(item.timeSpent),
              attempts: item.attempts || 0,
              createdAt: getDate(item.createdAt),
            }
            return historyData
          }),
        ]
        fetchHistories({
          variables: {
            start: newHistoriesList.length,
          },
        })
        return newHistoriesList
      })
    }
  }, [data, fetchHistories, locale])

  const cleanAllHistories = async () => {
    if (historyList.length > 0) {
      const size = historyList.length
      setIsDeleting(true)
      for (let history of historyList) {
        const progress = historyList.indexOf(history) + 1
        setProgressValue((progress * 100) / size)
        await deleteHistoryRecord({
          variables: {
            id: history.historyId,
          },
        })
      }
      setIsDeleting(false)
      setHistoryList([])
    }
  }

  const cleanUserHistories = async () => {
    if (selectedUser && historyList.length > 0) {
      const filterIds = historyList.reduce(
        (list: string[], item: IHistoryItemResult) => {
          if (item.userId === selectedUser) {
            return [...list, item.historyId]
          }
          return list
        },
        []
      )
      const newHistoryList = historyList.reduce(
        (list: IHistoryItemResult[], item: IHistoryItemResult) => {
          if (!filterIds.includes(item.historyId)) {
            return [...list, item]
          }
          return list
        },
        []
      )
      setIsDeletingUserHistory(true)
      const size = filterIds.length
      for (let id of filterIds) {
        const progress = filterIds.indexOf(id) + 1
        setProgressValue((progress * 100) / size)
        await deleteHistoryRecord({
          variables: {
            id,
          },
        })
      }
      setIsDeletingUserHistory(false)
      setHistoryList(newHistoryList)
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0)
      }
    }
  }

  const renderCard = (history: IHistoryItemResult, index: number) => {
    return (
      <div
        className={styles.item}
        key={history.historyId}
        style={{ top: getTopPosition(index) }}
      >
        <div className="row">
          <div
            className={`${styles.itemLabel} col-5 col-md-3 col-lg-2 col-xl-1`}
          >
            {locale?.user}
          </div>
          <div
            className={`${styles.itemValue} col-7 col-md-9 col-lg-10 col-xl-11`}
          >
            {history.userName}
          </div>
        </div>
        <div className="row">
          <div
            className={`${styles.itemLabel} col-5 col-md-3 col-lg-2 col-xl-1`}
          >
            {locale?.exercise}
          </div>
          <div
            className={`${styles.itemValue} col-7 col-md-9 col-lg-10 col-xl-11`}
          >
            {history.exerciseTitle}
          </div>
        </div>
        <div className="row">
          <div
            className={`${styles.itemLabel} col-5 col-md-3 col-lg-2 col-xl-1`}
          >
            {locale?.passed}
          </div>
          <div
            className={`${styles.itemValue} col-7 col-md-9 col-lg-10 col-xl-11`}
          >
            {history.isCorrect}
          </div>
        </div>
        <div className="row">
          <div
            className={`${styles.itemLabel} col-5 col-md-3 col-lg-2 col-xl-1`}
          >
            {locale?.value}
          </div>
          <div
            className={`${styles.itemValue} col-7 col-md-9 col-lg-10 col-xl-11`}
          >
            {history.pickedValue}
          </div>
        </div>
        <div className="row">
          <div
            className={`${styles.itemLabel} col-5 col-md-3 col-lg-2 col-xl-1`}
          >
            {locale?.time}
          </div>
          <div
            className={`${styles.itemValue} col-7 col-md-9 col-lg-10 col-xl-11`}
          >
            {history.timeSpent}
          </div>
        </div>
        <div className="row">
          <div
            className={`${styles.itemLabel} col-5 col-md-3 col-lg-2 col-xl-1`}
          >
            {locale?.attempts}
          </div>
          <div
            className={`${styles.itemValue} col-7 col-md-9 col-lg-10 col-xl-11`}
          >
            {history.attempts}
          </div>
        </div>
        <div className="row">
          <div
            className={`${styles.itemLabel} col-5 col-md-3 col-lg-2 col-xl-1`}
          >
            {locale?.created}
          </div>
          <div
            className={`${styles.itemValue} col-7 col-md-9 col-lg-10 col-xl-11`}
          >
            {history.createdAt}
          </div>
        </div>
      </div>
    )
  }

  const handleChangeUser = (value: string) => {
    setSelectedUser(value)
  }

  const renderContent = () => {
    if (errorsMessages && errorsMessages.length > 0) {
      return (
        <>
          {errorsMessages?.map((messageCode: string, index: number) => (
            <ErrorBox key={`error-${index}`}>{messageCode}</ErrorBox>
          ))}
        </>
      )
    }
    if (isDeletingUserHistory) {
      return (
        <>
          <Loading size={24} message={locale?.deletingUserHistory} />
          <div className="mt-3">
            <Progress width={progressValue} />
          </div>
        </>
      )
    }
    if (isDeleting) {
      return (
        <>
          <Loading size={24} message={locale?.deleting} />
          <div className="mt-3">
            <Progress width={progressValue} />
          </div>
        </>
      )
    }
    if (isLoading) {
      return <Loading size={24} message={locale?.loading} />
    }
    if (historyList.length > 0) {
      return (
        <>
          <div className="mb-3 d-flex flex-columns align-items-center">
            <CSVLink
              className={styles.csv}
              filename={`matematrolii-reports-${now}.csv`}
              data={historyList}
              headers={headers}
            >
              <AdminButton type="button">
                <Download16 />
                <span>{locale.exportButton}</span>
              </AdminButton>
            </CSVLink>
            <AdminButton
              className={styles.cleanButton}
              type="button"
              onClick={cleanAllHistories}
            >
              <Clean16 />
              <span>{locale.clearButton}</span>
            </AdminButton>
          </div>
          <div
            style={{ width: '100%' }}
            className="mb-3 d-flex flex-columns align-items-center justify-content-center"
          >
            <span>{locale.selectUserToClean}</span>
            <Dropdown
              items={dropdownItems}
              value={selectedUser}
              onChange={handleChangeUser}
              className={styles.userDropdown}
              placeholder={locale.selectUser}
            />
            <AdminButton
              className={styles.cleanUserButton}
              type="button"
              onClick={cleanUserHistories}
            >
              <Clean16 />
            </AdminButton>
          </div>
          <div
            ref={containerRef}
            onScroll={onScroll}
            className={`${styles.list} mb-3`}
          >
            <div className={styles.wrapper} style={{ height: containerHeight }}>
              {renderedList.map((history, index: number) =>
                renderCard(history as IHistoryItemResult, index)
              )}
            </div>
          </div>
        </>
      )
    }
    return <Title title={locale.noHistories} headingLevel={4} />
  }

  return (
    <div className={`${styles.reports}`}>
      <AdminHeader />
      <div className="container">
        <div className="row py-3">
          <div className="col px-3 d-flex flex-column align-items-center">
            <div className="mb-3">
              <Title
                title={`${locale.exportTitle}${
                  historyList.length > 0
                    ? `: ${historyList.length} ${locale.records}`
                    : ''
                }`}
                headingLevel={3}
              />
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  )
}
