import { languageCodes } from '../../../../core/@types/general'

export interface ILocaleProps {
  en_US_Title: string
  ro_RO_Title: string
  signOut: string
  exercises: string
  reports: string
  media: string
  configurations: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    en_US_Title: 'English',
    ro_RO_Title: 'Romanian',
    signOut: 'Sign Out',
    exercises: 'Exercises',
    reports: 'Reports',
    media: 'Media',
    configurations: 'Configurations'
  },
  [languageCodes.ro_RO]: {
    en_US_Title: 'Engleză',
    ro_RO_Title: 'Română',
    signOut: 'Deconectare',
    exercises: 'Exerciții',
    reports: 'Rapoarte',
    media: 'Media',
    configurations: 'Configurații'
  },
}

export default localeData
