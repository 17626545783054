import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  title: string
  thePlanet: string
  message: string
  button: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    title: "Mission Complete",
    thePlanet: "The planet",
    message: "is saved! Continue your journey to save the other planets in need!",
    button: "Return to missions"
  },
  [languageCodes.ro_RO]: {
    title: "Misiune Încheiată",
    thePlanet: "Planeta",
    message: "este salvată! Continuă aventura de a salva restul planetelor!",
    button: "Înapoi la misiuni"
  },
}

export default localeData
