import { languageCodes } from '../../../../../core/@types/general'

export interface ILocaleProps {
  startMission: string
  missionCompleted: string
  mission: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    startMission: 'Start mission',
    missionCompleted: 'Mission completed',
    mission: 'Mission',
  },
  [languageCodes.ro_RO]: {
    startMission: 'Începe misiunea',
    missionCompleted: 'Misiune încheiată',
    mission: 'Misiune',
  },
}

export default localeData
