import { languageCodes } from '../../../../core/@types/general'

export interface ILocaleProps {
  errorRenderExercise: string
  helperOneTitle: string
  helperTwoTitle: string
  helperProblemTitle: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    errorRenderExercise: 'There is no exercise available',
    helperOneTitle: 'Helper 1',
    helperTwoTitle: 'Helper 2',
    helperProblemTitle: 'Problem',
  },
  [languageCodes.ro_RO]: {
    errorRenderExercise: 'Nu exista un exercițiu disponibil',
    helperOneTitle: 'Ajutor 1',
    helperTwoTitle: 'Ajutor 2',
    helperProblemTitle: 'Problema',
  },
}

export default localeData
