import React, { useState, useContext, useRef } from 'react'
import { IGame } from '../@types/game'
import { addHistory } from '../api/graphql/mutations/exercise'
import { useMutation } from '@apollo/react-hooks'
import { IHistoryData } from '../@types/exercise'
import { GeneralContext } from './generalContext'
interface IContext {
  missionHistory: IHistoryData[]
  gamePacks: string[]
  missionStarted: boolean
  setMissionStarted(state: boolean): void
  onMissionFail(): void
  onMissionSuccess(): void
  updateHistory(data: IHistoryData): void
  updateGamePacks(packs: string[]): void
}

interface IProps {
  children: React.ReactNode
}

const initialContext = {
  missionHistory: [],
  gamePacks: [],
  missionStarted: false,
  setMissionStarted: () => {},
  onMissionFail: () => {},
  onMissionSuccess: () => {},
  updateHistory: () => {},
  updateGamePacks: () => {},
}

export const MissionContext = React.createContext<IContext>(initialContext)

export function MissionContextProvider({ children }: IProps) {
  const { gameInfo, currentMission, updateGameInfo } =
  useContext(GeneralContext)
  const [addHistoryItem] = useMutation(addHistory)
  
  const game = gameInfo?.user?.extrauser?.game
  const [missionHistory, setMissionHistory] = useState<IHistoryData[]>([])
  const [missionStarted, setMissionStarted] = useState<boolean>(false)
  const gamePacks = useRef<string[]>(game?.packs ? [...game?.packs] : [])

  function onMissionFail() {
    setMissionHistory([])
    setMissionStarted(false)
  }

  async function onMissionSuccess() {
    if (game) {
      try {
        await updateGameInfo({
          ...game,
          currentMission,
          missions: [...game.missions, currentMission],
          packs: gamePacks.current
        } as IGame)
      } catch (err) {
        console.error('There was an error while updating game missions', err)
      }
    }
  }

  async function updateHistory(historyItem: IHistoryData) {
    setMissionHistory([...missionHistory, historyItem])
    try {
      await addHistoryItem({
        variables: historyItem,
      })
    } catch (err) {
      console.error('There was an error while saving history', err)
    }
  }

  function handleMissionStarted(state: boolean) {
    setMissionHistory([])
    setMissionStarted(state)
  }

  function updateGamePacks(packs: string[]) {
    gamePacks.current = packs;
  }

  return (
    <MissionContext.Provider
      value={{
        missionHistory,
        missionStarted,
        gamePacks: gamePacks.current,
        setMissionStarted: handleMissionStarted,
        onMissionFail,
        onMissionSuccess,
        updateHistory,
        updateGamePacks
      }}
    >
      {children}
    </MissionContext.Provider>
  )
}
