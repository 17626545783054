import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  errorPackages: string
  loadingPacks: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    errorPackages: 'There was an error when fetching the exercise packages',
    loadingPacks: 'Loading zone packs...',
  },
  [languageCodes.ro_RO]: {
    errorPackages: 'A apărut o eroare în timpul primirii pachetelor de exerciții',
    loadingPacks: 'Încărcare pachete zonă...',
  },
}

export default localeData
