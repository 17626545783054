import React from 'react'
import { Accordion } from '../../../../components/atoms/accordion'
import { Zones as ZonesEnum, ZoneType } from '../../../../core/@types/game'
import { EnableDropdown } from '../enableDropdown'
import { useFormContext } from 'react-hook-form'
import { Packs } from '../packs'

interface ZonesProps {
  name: string
}

export const Zones: React.FC<ZonesProps> = ({ name }) => {
  const { watch } = useFormContext()
  const mission = watch(name)

  const renderHeader = (zoneName: ZoneType) => {
    return (
      <div className="d-flex align-items-center">
        <h4 style={{ marginRight: 16 }}>{zoneName}</h4>
        <EnableDropdown name={`${name}.${zoneName}.show`} />
      </div>
    )
  }

  const renderBody = (zoneName: ZoneType) => {
    return <Packs name={`${name}.${zoneName}.packs`} />
  }

  const renderAccordion = (zoneName: ZoneType) => {
    const disabled = !mission[zoneName].show
    return (
      <Accordion
        disabled={disabled}
        header={renderHeader(zoneName)}
        body={renderBody(zoneName)}
      />
    )
  }

  return (
    <>
      {renderAccordion(ZonesEnum.ZONE1)}
      {renderAccordion(ZonesEnum.ZONE2)}
      {renderAccordion(ZonesEnum.ZONE3)}
      {renderAccordion(ZonesEnum.ZONE4)}
      {renderAccordion(ZonesEnum.ZONE5)}
      {renderAccordion(ZonesEnum.ZONE6)}
      {renderAccordion(ZonesEnum.ZONE7)}
    </>
  )
}
