import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  missingPacks: string
  nextZone: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    missingPacks: 'The packs are missing! Try the next zone',
    nextZone: 'Next Zone',
  },
  [languageCodes.ro_RO]: {
    missingPacks: 'Pachetele lipsesc! Încearcă la zona următoare',
    nextZone: 'Următoarea Zonă',
  },
}

export default localeData
