import { useContext, useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { OrderFilters, OrderFilterType } from '../../core/@types/general'
import { IExerciseItem } from '../../core/@types/exercise'
import { getExercises } from '../../core/api/graphql/queries/exercise'
import {
  MissionType,
  ZoneType,
  PackType,
  ExerciseType,
} from '../../core/@types/game'
import { ErrorBox } from '../../components/atoms/error-box'
import { AdminButton } from '../../components/atoms/admin-button'
import { AdminHeader } from '../../components/atoms/admin-header'
import { Title } from '../../components/atoms/title'
import {
  EXERCISES_VIEW,
  EXERCISES_EDIT,
} from '../../core/constants/routes'
import { GeneralContext } from '../../core/context/generalContext'
import locales, { ILocaleProps } from './lib/locales'
import { View16, Edit16 } from '@carbon/icons-react'
import { getFilters } from './filters'
import { useLazyQuery } from '@apollo/react-hooks'
import { generateErrors } from '../../core/utils/generateErrors'
import { Dropdown } from '../../components/atoms/dropdown'
import styles from './exercises.module.scss'
import { FETCH_POLICY } from '../../core/constants/general'

export function Exercises(): JSX.Element {
  const [fetchExercises, { data, loading, error }] = useLazyQuery(
    getExercises,
    { fetchPolicy: FETCH_POLICY }
  )
  const errorsMessages = useMemo(() => {
    return generateErrors(error) || []
  }, [error])
  const { languageCode } = useContext(GeneralContext)
  const [filter, setFilter] = useState<{
    sort: OrderFilterType
    mission: MissionType | ''
    zone: ZoneType | ''
    pack: PackType | ''
    exercise: ExerciseType | ''
  }>({
    sort: OrderFilters.CREATED,
    mission: '',
    zone: '',
    pack: '',
    exercise: '',
  })
  const [exerciseList, setExerciseList] = useState<IExerciseItem[]>([])
  const locale: ILocaleProps = locales[languageCode]
  const {
    zoneFilters,
    missionFilters,
    packFilters,
    exerciseFilters,
    sortFilters,
  } = getFilters(locale)

  function loadMore() {
    const filterOption = [
      filter.exercise,
      filter.pack,
      filter.zone,
      filter.mission,
    ]
      .filter((item) => item !== '')
      .join('_')
    fetchExercises({
      variables: {
        start: exerciseList.length,
        sort: filter.sort,
        filter: filterOption,
      },
    })
  }

  useEffect(() => {
    fetchExercises({
      variables: { start: 0, sort: OrderFilters.CREATED, filter: '' },
    })
  }, [fetchExercises])

  useEffect(() => {
    if (data && data.exercises && data.exercises.length > 0) {
      setExerciseList(data.exercises)
    }
  }, [data])

  function filterExercises(value: string, filterName: string) {
    setFilter((oldFilter) => {
      const newFilter = { ...oldFilter, [filterName]: value }
      const filterOption = [
        newFilter.exercise,
        newFilter.pack,
        newFilter.zone,
        newFilter.mission,
      ]
        .filter((item) => item !== '')
        .join('_')
      setExerciseList([])
      fetchExercises({
        variables: {
          start: 0,
          sort: newFilter.sort,
          filter: filterOption,
        },
      })
      return newFilter
    })
  }

  function clearFilters() {
    setExerciseList([])
    setFilter({
      sort: OrderFilters.CREATED,
      mission: '',
      zone: '',
      pack: '',
      exercise: '',
    })
    fetchExercises({
      variables: {
        start: 0,
        sort: OrderFilters.CREATED,
        filter: '',
      },
    })
  }

  return (
    <div className={`${styles.exercises}`}>
      <AdminHeader />
      <div className="container py-3">
        <div className="row">
          <div className="col-3">
            <div className="mb-3 d-flex justify-content-center">
              <Title
                className={styles.title}
                title={locale?.titleFilters}
                headingLevel={3}
              />
            </div>
            <div className={`${styles.label} mb-1`}>Date:</div>
            <Dropdown
              className="mb-3"
              items={sortFilters}
              value={filter.sort}
              onChange={(value) => filterExercises(value, 'sort')}
              placeholder={locale?.placeholderOrder}
            />
            <div className={`${styles.label} mb-1`}>Mission:</div>
            <Dropdown
              className="mb-3"
              items={missionFilters}
              value={filter.mission}
              onChange={(value) => filterExercises(value, 'mission')}
              placeholder={locale?.placeholderMission}
            />
            <div className={`${styles.label} mb-1`}>Zone:</div>
            <Dropdown
              className="mb-3"
              items={zoneFilters}
              value={filter.zone}
              onChange={(value) => filterExercises(value, 'zone')}
              placeholder={locale?.placeholderZone}
            />
            <div className={`${styles.label} mb-1`}>Pack:</div>
            <Dropdown
              className="mb-3"
              items={packFilters}
              value={filter.pack}
              onChange={(value) => filterExercises(value, 'pack')}
              placeholder={locale?.placeholderPack}
            />
            <div className={`${styles.label} mb-1`}>Exercise:</div>
            <Dropdown
              className="mb-3"
              items={exerciseFilters}
              value={filter.exercise}
              onChange={(value) => filterExercises(value, 'exercise')}
              placeholder={locale?.placeholderExercise}
            />
            <AdminButton type="button" onClick={clearFilters}>
              Clear Filters
            </AdminButton>
          </div>
          <div className="col-9 d-flex align-items-center justify-content-start flex-column">
            <div className="mb-3">
              <Title title={locale?.titleExercises} headingLevel={3} />
            </div>
            {errorsMessages?.map((messageCode: string, index: number) => (
              <ErrorBox key={`error-${index}`}>{messageCode}</ErrorBox>
            ))}
            <div className={`${styles.list} px-3 mb-3`}>
              <div className={`${styles.item} row`}>
                <div className="col">{locale?.title}</div>
                <div className="col">{locale?.actions}</div>
              </div>
              {exerciseList.map((exercise: IExerciseItem) => (
                <div
                  key={exercise.id}
                  id={exercise.id}
                  className={`${styles.item} row`}
                >
                  <div className="col">{exercise.title}</div>
                  <div className="col d-flex">
                    <Link
                      className={`${styles.actions} d-flex align-items-center mr-5`}
                      to={`${EXERCISES_VIEW}/${exercise.id}`}
                    >
                      <View16 />
                      <span>{locale?.view}</span>
                    </Link>
                    <Link
                      className={`${styles.actions} d-flex align-items-center`}
                      to={`${EXERCISES_EDIT}/${exercise.id}`}
                    >
                      <Edit16 />
                      <span>{locale?.edit}</span>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <AdminButton
                type="button"
                onClick={loadMore}
                isLoading={loading}
                disabled={false}
              >
                {locale?.loadButton}
              </AdminButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
