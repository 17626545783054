import { MissionType } from '../@types/game'

export function countMissionOccurences(
  missions: MissionType[] | undefined,
  mission: MissionType | null
) {
  if (mission && missions && missions.length > 0) {
    const missionsString = missions.join('')
    return missionsString.split(mission).length - 1
  }
  return 0
}
