import React from 'react'
import cx from 'classnames'
import { Close20, Checkmark20, Pending20 } from '@carbon/icons-react'
import { ExerciseType, Exercises } from '../../../core/@types/game'
import styles from './exercise-progress.module.scss'

interface IProps {
  hasFeedback: boolean
  exercises: ExerciseType[]
  progress:
    | {
        [key in Exercises]: boolean | null
      }
    | null
  currentExercise: ExerciseType | null
}

export function ExerciseProgress({
  hasFeedback,
  exercises,
  progress,
  currentExercise,
}: IProps): JSX.Element | null {
  return progress && currentExercise ? (
    <div className="px-3">
      <ul className={`${styles.progress} d-flex p-3`}>
        {exercises.map((exerciseKey: ExerciseType, index: number) => {
          const passed = progress[exerciseKey] === true
          const failed = progress[exerciseKey] === false
          const isValid = hasFeedback && passed
          const isInvalid = hasFeedback && failed
          const isActive = exerciseKey === currentExercise
          const isVisited = progress[exerciseKey] !== null
          const showChecked = isVisited && isValid
          const showUnchecked = isVisited && isInvalid
          const showPending = !isActive && !showChecked && !showUnchecked
          return (
            <li
              className={cx(styles.progressItem, {
                [styles.active]: isActive,
                [styles.valid]: isValid,
                [styles.invalid]: isInvalid,
                [styles.visited]: isVisited,
              })}
              key={`${exerciseKey}-${index}`}
            >
              <span className="d-flex justify-content-center align-items-center">
                {showChecked && <Checkmark20 />}
                {showUnchecked && <Close20 />}
                {showPending && <Pending20 />}
              </span>
            </li>
          )
        })}
      </ul>
    </div>
  ) : null
}
