import { MouseEvent, useState, useContext } from 'react'
import { GeneralContext } from '../../../../core/context/generalContext'
import { Button } from '../../../../components/atoms/button'
import locales from './lib/locales'
import styles from './fail-modal.module.scss'

interface IProps {
  visible: boolean
  onClick?(ev: MouseEvent<HTMLButtonElement>): void
}

export function FailModal({ visible, onClick }: IProps) {
  const { languageCode } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const [disabled, setDisabled] = useState<boolean>(false)

  function handleClick(ev: MouseEvent<HTMLButtonElement>): void {
    setDisabled(true)
    if (onClick) {
      onClick(ev)
    }
  }

  return visible ? (
    <div className={`${styles.modal} p-3`}>
      <div className={`${styles.title} d-flex justify-content-center mb-3`}>
        {locale.title}
      </div>
      <div className="mb-3">
        {locale.message}
      </div>
      <Button
        disabled={disabled}
        className={styles.failButton}
        type="button"
        onClick={handleClick}
        title={locale.button}
      />
    </div>
  ) : null
}
