import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PROFILE, SETUP, EXERCISES, NOT_FOUND } from '../constants/routes'
import { USER_INFO, JWT_TOKEN, GAME_INFO } from '../constants/general'
import { Roles } from '../@types/general'
import { removeStorage } from '../storage'
import { CustomRouteProps } from './AuthenticatedRoute'

export function UnauthenticatedRoute({
  children,
  isUnauthenticated,
  role,
  game,
  ...rest
}: CustomRouteProps) {
  let redirectRoute = NOT_FOUND

  if (isUnauthenticated) {
    removeStorage(USER_INFO)
    removeStorage(JWT_TOKEN)
    removeStorage(GAME_INFO)
    
  } else {
    if (role === Roles.AUTHENTICATED) {
      if (game) {
        redirectRoute = PROFILE
      } else {
        redirectRoute = SETUP
      }
    }
    if (role === Roles.ADMIN) {
      redirectRoute = EXERCISES
    }
  }

  return (
    <Route {...rest}>
      {isUnauthenticated ? children : <Redirect to={redirectRoute} />}
    </Route>
  )
}
