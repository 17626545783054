import { languageCodes } from '../../../core/@types/general'

export interface ILocaleProps {
  heroName: string
  collected: string
  equipedItems: string
  missions: string
  group: string
  description: string
  school: string
  city: string
  buyButton: string
  ranksTab: string
  badgesTab: string
  heroesTab: string
  rocketsTab: string
  buyError: string
  selectError: string
  loading: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    heroName: 'Hero Name',
    collected: 'Collected',
    equipedItems: 'Equiped Items',
    missions: 'Missions',
    group: 'Group',
    description: 'Description',
    school: 'School',
    city: 'City',
    buyButton: 'Buy',
    ranksTab: 'Ranks',
    badgesTab: 'Badges',
    heroesTab: 'Heroes',
    rocketsTab: 'Rockets',
    buyError: 'There was an error while buying new item',
    selectError: 'There was an error while selecting items',
    loading: 'Updating game data',
  },
  [languageCodes.ro_RO]: {
    heroName: 'Nume Erou',
    collected: 'Colecționate',
    equipedItems: 'Echipament',
    missions: 'Misiuni',
    group: 'Grupa',
    description: 'Descriere',
    school: 'Șscoala',
    city: 'Orașul',
    buyButton: 'Cumpără',
    ranksTab: 'Ranguri',
    badgesTab: 'Insigne',
    heroesTab: 'Eroi',
    rocketsTab: 'Rachete',
    buyError: 'A apărut o eroare în timpul cumpărării de produse',
    selectError: 'A apărut o eroare în timpul selectării de produse',
    loading: 'Modificare date joc',
  },
}

export default localeData
