import { Missions, IGameData } from '../@types/game'
import {
  RANK_ADENDUS,
  RANK_DIMINUS,
  RANK_DIVISIA,
  RANK_FRACTA,
  RANK_HYBRIDIA,
  RANK_MULTIPLIS,
  RANK_QUESTIA,
  RANK_TERRA,
} from '../constants/resources'

export const ranksData: { [key in Missions]: IGameData } = {
  [Missions.TERRA]: {
    id: Missions.TERRA,
    name: 'Terra Rank',
    image: RANK_TERRA,
    description: '',
  },
  [Missions.ADENDUS]: {
    id: Missions.ADENDUS,
    name: 'Adendus Rank',
    image: RANK_ADENDUS,
    description: '',
  },
  [Missions.DIMINUS]: {
    id: Missions.DIMINUS,
    name: 'Diminus Rank',
    image: RANK_DIMINUS,
    description: '',
  },
  [Missions.MULTIPLIS]: {
    id: Missions.MULTIPLIS,
    name: 'Multiplis Rank',
    image: RANK_MULTIPLIS,
    description: '',
  },
  [Missions.FRACTA]: {
    id: Missions.FRACTA,
    name: 'Fracta Rank',
    image: RANK_FRACTA,
    description: '',
  },
  [Missions.DIVISIA]: {
    id: Missions.DIVISIA,
    name: 'Divisia Rank',
    image: RANK_DIVISIA,
    description: '',
  },
  [Missions.HYBRIDIA]: {
    id: Missions.HYBRIDIA,
    name: 'Hybridia Rank',
    image: RANK_HYBRIDIA,
    description: '',
  },
  [Missions.QUESTIA]: {
    id: Missions.QUESTIA,
    name: 'Questia Rank',
    image: RANK_QUESTIA,
    description: '',
  },
}
