import React from 'react'
import { HeadingLevelType } from '../../../core/@types/general'
import cx from 'classnames'
import styles from './title.module.scss'

interface IProps {
  id?: string
  title: string | JSX.Element
  headingLevel: HeadingLevelType
  className?: string
  style?: { [key: string]: string }
}

export function Title(props: IProps) {
  const { headingLevel, title, id, className, style } = props
  const headingMap: { [key: number]: string } = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  }

  return React.createElement(
    headingMap[headingLevel] || 'h1',
    {
      id,
      className: cx(styles.title, className, {
        [styles.expressive1]: headingLevel === 1,
        [styles.expressive2]: headingLevel === 2,
        [styles.expressive3]: headingLevel === 3,
        [styles.expressive4]: headingLevel === 4,
        [styles.expressive5]: headingLevel === 5,
        [styles.expressive6]: headingLevel === 6,
      }),
      style,
    },
    title
  )
}
