import { useContext, useState } from 'react'
import cx from 'classnames'
import { GeneralContext } from '../../core/context/generalContext'
import { Button } from '../../components/atoms/button'
import { Title } from '../../components/atoms/title'
import { rocketsData } from '../../core/game-data/rockets'
import { heroData } from '../../core/game-data/heroes'
import { ranksData } from '../../core/game-data/ranks'
import { IGameData, IGame } from '../../core/@types/game'
import { GameHeader } from '../../components/atoms/game-header'
import { ErrorBox } from '../../components/atoms/error-box'
import { Loading } from '../../components/atoms/loading'
import { Image } from '../../components/atoms/image'
import { MISSIONS } from '../../core/constants/routes'
import { BACKGROUND_SPACE_BACKGROUND } from '../../core/constants/resources'
import {
  Rocket32,
  Trophy32,
  Pedestrian32,
  Model20,
  Model24,
  Earth24,
  CarbonIconType,
} from '@carbon/icons-react'
import locales from './lib/locales'
import styles from './profile.module.scss'

enum Tabs {
  HERO = 'HERO',
  ROCKET = 'ROCKET',
  RANK = 'RANK',
}

type TabType = Tabs.HERO | Tabs.RANK | Tabs.ROCKET

const panels: { [key in TabType]: any } = {
  [Tabs.RANK]: ranksData,
  [Tabs.HERO]: heroData,
  [Tabs.ROCKET]: rocketsData,
}

const tabs: { [key in TabType]: CarbonIconType } = {
  [Tabs.RANK]: Trophy32,
  [Tabs.HERO]: Pedestrian32,
  [Tabs.ROCKET]: Rocket32,
}

export function Profile() {
  const [tab, setTab] = useState<TabType>(Tabs.RANK)
  const [profileState, setProfileState] = useState<{
    error: string | null
    isLoading: boolean
  }>({
    error: null,
    isLoading: false,
  })
  const { gameInfo, languageCode, updateGameInfo } = useContext(GeneralContext)
  const locale = locales[languageCode]
  const game = gameInfo?.user?.extrauser?.game
  const group = gameInfo?.user?.extrauser?.group
  const activeElements = {
    [Tabs.RANK]: game?.missions,
    //[Tabs.BADGE]: game?.badges,
    [Tabs.HERO]: game?.heroes,
    [Tabs.ROCKET]: game?.rockets,
  }
  const tabNames = {
    [Tabs.RANK]: locale?.ranksTab,
    //[Tabs.BADGE]: locale?.badgesTab,
    [Tabs.HERO]: locale?.heroesTab,
    [Tabs.ROCKET]: locale?.rocketsTab,
  }

  async function buyItem(item: IGameData) {
    if (game && item.price) {
      const key = tab === Tabs.HERO ? 'heroes' : 'rockets'
      try {
        setProfileState({
          error: null,
          isLoading: true,
        })
        await updateGameInfo({
          ...game,
          gold: game.gold - item.price,
          [key]: [...game[key], item.id],
        } as IGame)
        setProfileState({
          error: null,
          isLoading: false,
        })
      } catch (err) {
        console.error(err)
        setProfileState({
          error: locale?.buyError,
          isLoading: false,
        })
      }
    }
  }

  async function selectItem(item: IGameData) {
    if (game) {
      try {
        setProfileState({
          error: null,
          isLoading: true,
        })
        const key = tab === Tabs.HERO ? 'hero' : 'rocket'
        await updateGameInfo({
          ...game,
          [key]: item.id,
        } as IGame)
        setProfileState({
          error: null,
          isLoading: false,
        })
      } catch (err) {
        console.error(err)
        setProfileState({
          error: locale?.selectError,
          isLoading: false,
        })
      }
    }
  }

  return (
    <div
      className={styles.profile}
      style={{ backgroundImage: `url(${BACKGROUND_SPACE_BACKGROUND})` }}
    >
      <GameHeader />
      <div className="container p-5">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-5 col-xl-4">
            <div className="mb-3 pb-3">
              <div className={`${styles.card} p-5`}>
                <Title
                  className={`${styles.label} mb-3`}
                  headingLevel={4}
                  title={
                    <>
                      {locale?.heroName}: <span>{game?.name}</span>
                    </>
                  }
                />
                <Title
                  headingLevel={4}
                  className={`${styles.gold} mb-3 d-flex align-items-center`}
                  title={
                    <>
                      <div>{locale?.collected}:</div> <Model24 />{' '}
                      <span>{game?.gold}</span>
                    </>
                  }
                />
                <div className="row align-items-center">
                  <div className="col-12">
                    <Title
                      className={`mb-3`}
                      headingLevel={4}
                      title={`${locale?.equipedItems}:`}
                    />
                  </div>
                  <div className="col-6">
                    <div className={`${styles.innerCard} pt-3`}>
                      {game?.rocket && (
                        <>
                          <Image
                            src={rocketsData[game.rocket].image}
                            alt="hero"
                          />
                          <div className={styles.cardLabel}>
                            {rocketsData[game.rocket].name}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={`${styles.innerCard} pt-3`}>
                      {game?.hero && (
                        <>
                          <Image src={heroData[game.hero].image} alt="hero" />
                          <div className={styles.cardLabel}>
                            {heroData[game.hero].name}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5 justify-content-center">
                  <Button
                    className={styles.missionButton}
                    url={MISSIONS}
                    type="button"
                    title={
                      <>
                        <Earth24 /> <span>{locale?.missions}</span>
                      </>
                    }
                  />
                </div>
              </div>
            </div>

            <div className={`${styles.card} p-5`}>
              <Title
                className={`${styles.label} mb-3`}
                headingLevel={4}
                title={
                  <>
                    {locale?.group}: <span>{group?.title}</span>
                  </>
                }
              />
              <Title headingLevel={4} title={`${locale?.description}:`} />
              <div className={`${styles.description} mb-3`}>
                {group?.description}
              </div>
              <Title
                headingLevel={4}
                className={`${styles.label} mb-3`}
                title={
                  <>
                    {locale?.school}: <span>{group?.school}</span>
                  </>
                }
              />
              <Title
                className={styles.label}
                headingLevel={4}
                title={
                  <>
                    {locale?.city}: <span>{group?.city}</span>
                  </>
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-7 col-xl-8">
            <div className={`${styles.tabWrapper} d-flex mb-3`}>
              {Object.keys(tabs).map((key: string) => {
                const Icon = tabs[key as TabType]
                return (
                  <div
                    key={key}
                    onClick={() => setTab(key as TabType)}
                    className={`${cx(styles.tab, {
                      [styles.tabSelected]: key === tab,
                    })} col-3 d-flex align-items-center justify-content-center py-3`}
                  >
                    <Icon /> <span>{tabNames[key as TabType]}</span>
                  </div>
                )
              })}
            </div>
            <div className="row pt-3">
              {profileState.error && (
                <div className="col-12 mb-3">
                  <ErrorBox>{profileState.error}</ErrorBox>
                </div>
              )}
              {profileState.isLoading && (
                <Loading
                  size={30}
                  className="mb-3"
                  message={`${locale?.loading}...`}
                />
              )}
              {Object.keys(panels[tab]).map((key: string) => {
                const elem: IGameData = panels[tab][key]
                const isClickable = tab === Tabs.HERO || tab === Tabs.ROCKET
                const isActive =
                  activeElements[tab]?.indexOf(elem.id as never) !== -1
                const isSelected =
                  activeElements[tab]?.indexOf(elem.id as never) !== -1 &&
                  (game?.hero === elem.id || game?.rocket === elem.id)
                return (
                  <div
                    key={key}
                    className="col-6 col-lg-4 col-xl-3 mb-3 pb-3 d-flex"
                  >
                    <div
                      className={`${cx(styles.tabCard, {
                        [styles.tabCardActive]: isActive,
                        [styles.tabCardSelected]: isSelected,
                        [styles.tabCardClickable]: isClickable,
                      })} p-3 d-flex flex-column align-items-center`}
                      onClick={isClickable ? () => selectItem(elem) : undefined}
                    >
                      {/* <div className={tab === Tabs.BADGE ? "p-3" : ""}> */}
                      <div>
                        <Image src={elem.image} alt={elem.id} />
                      </div>
                      <div className={`${styles.tabCardName} mt-3`}>
                        {elem.name}
                      </div>
                      {elem?.price && !isActive && (
                        <div
                          className={`${styles.tabCardPrice} mt-3 d-flex align-items-center justify-content-between`}
                        >
                          <div className="d-flex align-items-center">
                            <Model20 />
                            <span>{elem?.price}</span>
                          </div>
                          <button onClick={() => buyItem(elem)}>
                            {locale?.buyButton}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
