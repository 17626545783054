import { useContext, useEffect, useMemo } from 'react'
import { login } from '../../core/api/graphql/mutations/auth'
import { updateConfiguration } from '../../core/api/game'
import { getGameInfoQuery } from '../../core/api/graphql/queries/game'
import { useForm } from 'react-hook-form'
import { ILogin, ILoginResponse } from '../../core/@types/auth'
import { InputField } from '../../components/atoms/input-field'
import { GeneralContext } from '../../core/context/generalContext'
import { Login24, ViewNext24 } from '@carbon/icons-react'
import { LogoutHeader } from '../../components/atoms/logout-header'
import { HOME } from '../../core/constants/routes'
import { Button } from '../../components/atoms/button'
import { BACKGROUND_SPACE_BACKGROUND } from '../../core/constants/resources'
import { ErrorBox } from '../../components/atoms/error-box'
import locales, { ILocaleProps } from './lib/locales'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { setStorage } from '../../core/storage'
import styles from './login.module.scss'
import {
  FETCH_POLICY,
  GAME_INFO,
  JWT_TOKEN,
  USER_INFO,
} from '../../core/constants/general'
import { Roles } from '../../core/@types/general'
import { generateErrors } from '../../core/utils/generateErrors'
import { IGameInfoResponse } from '../../core/@types/game'
import { enableData } from '../../core/game-data/configuration'
import { merge } from 'lodash'

export function Login() {
  const [loginUser, { data, error, loading }] = useMutation(login)
  const [
    getGameInfo,
    { data: gameInfo, error: errorGameInfo, loading: loadingGameInfo },
  ] = useLazyQuery(getGameInfoQuery, { fetchPolicy: FETCH_POLICY })
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
  })
  const isDisabled = loading || loadingGameInfo
  const { languageCode, setApp } = useContext(GeneralContext)
  const errorsMessages = useMemo(() => {
    const loginErrors = generateErrors(error) || []
    const gameErrors = generateErrors(errorGameInfo) || []
    return [...loginErrors, ...gameErrors]
  }, [error, errorGameInfo])
  const locale: ILocaleProps = locales[languageCode]

  async function onSubmit({ email, password }: ILogin) {
    try {
      await loginUser({
        variables: {
          email,
          password,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!loading && data) {
      const dataResponse: ILoginResponse = data
      const user = dataResponse?.login?.user || null
      const jwt = dataResponse?.login?.jwt

      setStorage({ jwt }, JWT_TOKEN, true)
      setStorage(user, USER_INFO, true)

      if (user) {
        const role = user.role?.name
        if (role === Roles.ADMIN) {
          setApp({
            jwtToken: { jwt },
            userInfo: user,
            gameInfo: null,
          })
        }
        if (role === Roles.AUTHENTICATED) {
          try {
            getGameInfo({
              variables: { id: user.id },
            })
          } catch (err) {
            console.error(err)
          }
        }
      }
    }
  }, [data, getGameInfo, loading, setApp])

  useEffect(() => {
    async function updateConfigurationData(id: string, data: any) {
      try {
        await updateConfiguration(id, data)
      } catch (err) {
        console.error('Error', err)
      }
    }
    if (!loadingGameInfo && gameInfo && data) {
      const dataResponse: ILoginResponse = data
      const user = dataResponse?.login?.user || null
      const jwt = dataResponse?.login?.jwt
      const storedGameInfo = merge({}, gameInfo)
      const configuration = storedGameInfo.user?.extrauser?.group?.configuration
      if (configuration && !configuration.enabled_data) {
        configuration.enabled_data = enableData
        updateConfigurationData(configuration.id, enableData)
      }
      setStorage(storedGameInfo, GAME_INFO, true)
      setApp({
        jwtToken: { jwt },
        userInfo: user,
        gameInfo: storedGameInfo as IGameInfoResponse,
      })
    }
  }, [data, gameInfo, loadingGameInfo, setApp])

  return (
    <div
      className={`${styles.login}`}
      style={{ backgroundImage: `url(${BACKGROUND_SPACE_BACKGROUND})` }}
    >
      <LogoutHeader
        items={[
          {
            url: HOME,
            name: locale?.homeButton,
          },
        ]}
      />
      <div className={`${styles.wrapper} row align-items-center`}>
        <form
          autoComplete="off"
          className={`${styles.form} offset-4 col-4 d-flex flex-column`}
          onSubmit={handleSubmit(onSubmit)}
        >
          {errorsMessages?.map((messageCode: string, index: number) => (
            <ErrorBox key={`error-${index}`}>
              {(locale as any)[messageCode]}
            </ErrorBox>
          ))}
          <InputField
            className="mb-3"
            name="email"
            type="email"
            autocomplete="off"
            ref={register({
              required: {
                value: true,
                message: locale?.requiredEmail,
              },
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: locale?.emailFormat,
              },
            })}
            placeholder={locale.emailPlaceholder}
            error={errors?.email?.message}
            hasValidation
            label={locale.emailLabel}
            disabled={isDisabled}
          />
          <InputField
            className="mb-3"
            name="password"
            type="password"
            autocomplete="off"
            ref={register({
              required: {
                value: true,
                message: locale?.requiredPassword,
              },
              minLength: {
                value: 8,
                message: locale?.passwordLength,
              },
              pattern: {
                value: /[a-zA-Z0-9]/,
                message: locale?.passwordFormat,
              },
            })}
            placeholder={locale?.passwordPlaceholder}
            error={errors?.password?.message}
            hasValidation
            label={locale.passwordLabel}
            disabled={isDisabled}
          />
          <Button
            type="submit"
            isLoading={isDisabled}
            title={
              <>
                <Login24 />
                <span>{locale?.loginButton}</span>
              </>
            }
            loadingTitle={
              <>
                <ViewNext24 />
                <span>{locale?.loadingButton}</span>
              </>
            }
          />
        </form>
      </div>
    </div>
  )
}
