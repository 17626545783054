import { gql } from '@apollo/client'

export const addHistory = gql`
  mutation addHistory(
    $attempts: Int!
    $isCorrect: Boolean!
    $pickedValue: String!
    $timeSpent: Int!
    $exercise: ID!
    $extrauser: ID!
  ) {
    createHistory(
      input: {
        data: {
          attempts: $attempts
          isCorrect: $isCorrect
          pickedValue: $pickedValue
          timeSpent: $timeSpent
          exercise: $exercise
          extrauser: $extrauser
        }
      }
    ) {
      history {
        _id
      }
    }
  }
`

export const deleteHistory = gql`
  mutation deleteHistory(
    $id: ID!
  ) {
    deleteHistory(
      input: {
        where: { id: $id }
      }
    ) {
      history {
        _id
      }
    }
  }
`

export const updateExercise = gql`
  mutation updateExercise($id: ID!, $title: String!, $properties: JSON!) {
    updateExercise(
      input: {
        where: { id: $id }
        data: { title: $title, properties: $properties }
      }
    ) {
      exercise {
        _id
      }
    }
  }
`
