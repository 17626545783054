import cx from 'classnames'
import styles from './skeleton.module.scss'

interface IProps {
  height: number
  width?: number
  className?: string
  message?: string
}

export function Skeleton({ height, width, className, message }: IProps) {
  return (
    <div style={{ height, width }} className={cx(styles.skeleton, className)}>
      {message && (
        <div className={`${styles.skeletonMessage} p-3`}>{message}</div>
      )}
    </div>
  )
}
