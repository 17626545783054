import { useContext, useEffect, useState, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import { ErrorBox } from '../../components/atoms/error-box'
import { Empty } from '../../components/atoms/empty'
import { Title } from '../../components/atoms/title'
import { GeneralContext } from '../../core/context/generalContext'
import { AdminHeader } from '../../components/atoms/admin-header'
import { EXERCISES, REPORTS, EXERCISES_EDIT } from '../../core/constants/routes'
import { Notebook32, Edit16 } from '@carbon/icons-react'
import { IExercise } from '../../core/@types/exercise'
import { AdminExercise } from '../../components/organisms/admin-exercise'
import { getExercise } from '../../core/api/graphql/queries/exercise'
import { useQuery } from '@apollo/react-hooks'
import locales, { ILocaleProps } from './lib/locales'
import { generateErrors } from '../../core/utils/generateErrors'
import styles from './exercise-view.module.scss'
import { FETCH_POLICY } from '../../core/constants/general'

export function ExerciseView() {
  // router param for a specific exercise id
  const params = useParams<{ id: string }>()
  const {
    data: exerciseData,
    loading: exerciseLoading,
    error: exerciseError,
  } = useQuery(getExercise, {
    variables: {
      id: params.id,
    },
    fetchPolicy: FETCH_POLICY,
  })
  const errorsMessages = useMemo(() => {
    return generateErrors(exerciseError) || []
  }, [exerciseError])
  // exercise data
  const [exercise, setExercise] = useState<IExercise | undefined>(undefined)
  const [labels, setLabels] = useState<string[]>([])
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]

  useEffect(() => {
    if (exerciseData) {
      const data = exerciseData?.exercise
      setExercise(data)
      if (data?.name) {
        setLabels(data.name.split('_'))
      }
    }
  }, [exerciseData])

  function renderExercise() {
    return (
      <div className="col-12">
        <div className="col-12 mb-3 d-flex justify-content-center">
          <Title
            className="mr-3"
            headingLevel={3}
            title={exercise?.title || ''}
          />
        </div>
        <div className="col-12 d-flex justify-content-center">
          {labels.map((label: string) => (
            <div className={styles.label}>{label}</div>
          ))}
        </div>
        <AdminExercise exercise={exercise} />
      </div>
    )
  }

  function renderView() {
    return (
      <div className="container p-3 py-4">
        <div className="row mb-3">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <Title
              className="mr-3"
              headingLevel={3}
              title={locale?.exerciseTitle}
            />
            <Link
              className={`${styles.redirectButton} d-flex align-items-center`}
              to={`${EXERCISES_EDIT}/${params?.id}`}
            >
              <Edit16 />
              <span>{locale?.editButton}</span>
            </Link>
          </div>
        </div>
        <div className="row mb-3">
          {errorsMessages?.map((messageCode: string, index: number) => (
            <ErrorBox key={`error-${index}`}>{messageCode}</ErrorBox>
          ))}
          {exercise && renderExercise()}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.exerciseView}>
      <AdminHeader />
      {exerciseLoading ? (
        <div className="py-5">
          <Empty>
            <Notebook32 />
            <span>{locale?.loadingExercise}</span>
          </Empty>
        </div>
      ) : !exercise ? (
        <div className="py-5">
          <Empty>
            <Notebook32 />
            <span>{locale?.errorRenderExercise}</span>
          </Empty>
        </div>
      ) : (
        renderView()
      )}
    </div>
  )
}
