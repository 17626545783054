import { Zones, Packs, Missions, Exercises } from '../../core/@types/game'
import { OrderFilters } from '../../core/@types/general'
import { ILocaleProps } from './lib/locales'

export function getFilters(locale: ILocaleProps) {
  const zoneFilters = [
    {
      value: Zones.ZONE1,
      title: `${locale?.zone} 1`,
    },
    {
      value: Zones.ZONE2,
      title: `${locale?.zone} 2`,
    },
    {
      value: Zones.ZONE3,
      title: `${locale?.zone} 3`,
    },
    {
      value: Zones.ZONE4,
      title: `${locale?.zone} 4`,
    },
    {
      value: Zones.ZONE5,
      title: `${locale?.zone} 5`,
    },
    {
      value: Zones.ZONE6,
      title: `${locale?.zone} 6`,
    },
    {
      value: Zones.ZONE7,
      title: `${locale?.zone} 7`,
    },
  ]

  const missionFilters = [
    {
      value: Missions.ADENDUS,
      title: 'Adendus',
    },
    {
      value: Missions.DIMINUS,
      title: 'Diminus',
    },
    {
      value: Missions.MULTIPLIS,
      title: 'Multiplis',
    },
    {
      value: Missions.FRACTA,
      title: 'Fracta',
    },
    {
      value: Missions.DIVISIA,
      title: 'Divisia',
    },
    {
      value: Missions.QUESTIA,
      title: 'Questia',
    },
    {
      value: Missions.HYBRIDIA,
      title: 'Hybridia',
    },
  ]

  const packFilters = [
    {
      value: Packs.PACK1,
      title: `${locale?.pack} 1`,
    },
    {
      value: Packs.PACK2,
      title: `${locale?.pack} 2`,
    },
    {
      value: Packs.PACK3,
      title: `${locale?.pack} 3`,
    },
    {
      value: Packs.PACK4,
      title: `${locale?.pack} 4`,
    },
    {
      value: Packs.PACK5,
      title: `${locale?.pack} 5`,
    },
    {
      value: Packs.PACK6,
      title: `${locale?.pack} 6`,
    },
    {
      value: Packs.PACK7,
      title: `${locale?.pack} 7`,
    },
  ]

  const exerciseFilters = [
    {
      value: Exercises.EXERCISE1,
      title: `${locale?.exercise} 1`,
    },
    {
      value: Exercises.EXERCISE2,
      title: `${locale?.exercise} 2`,
    },
    {
      value: Exercises.EXERCISE3,
      title: `${locale?.exercise} 3`,
    },
    {
      value: Exercises.EXERCISE4,
      title: `${locale?.exercise} 4`,
    },
    {
      value: Exercises.EXERCISE5,
      title: `${locale?.exercise} 5`,
    },
    {
      value: Exercises.EXERCISE6,
      title: `${locale?.exercise} 6`,
    },
  ]

  const sortFilters = [
    {
      value: OrderFilters.CREATED,
      title: locale?.orderCreated,
    },
    {
      value: OrderFilters.UPDATED,
      title: locale?.orderUpdated,
    },
  ]

  return {
    sortFilters,
    zoneFilters,
    packFilters,
    missionFilters,
    exerciseFilters,
  }
}
