import {
  MissionType,
  ZoneType,
  PackType,
  ExerciseType,
  Missions,
  Zones,
  Packs,
  Exercises,
  IConfigurationEnableData,
} from '../@types/game'

const missions = [
  Missions.ADENDUS,
  Missions.DIMINUS,
  Missions.MULTIPLIS,
  Missions.FRACTA,
  Missions.DIVISIA,
  Missions.QUESTIA,
  Missions.HYBRIDIA,
  Missions.TERRA,
]
const zones = [
  Zones.ZONE1,
  Zones.ZONE2,
  Zones.ZONE3,
  Zones.ZONE4,
  Zones.ZONE5,
  Zones.ZONE6,
  Zones.ZONE7,
]
const packs = [
  Packs.PACK1,
  Packs.PACK2,
  Packs.PACK3,
  Packs.PACK4,
  Packs.PACK5,
  Packs.PACK6,
  Packs.PACK7,
]
const exercises = [
  Exercises.EXERCISE1,
  Exercises.EXERCISE2,
  Exercises.EXERCISE3,
  Exercises.EXERCISE4,
  Exercises.EXERCISE5,
  Exercises.EXERCISE6,
]

type ListType = MissionType | ZoneType | PackType | ExerciseType

function generateMap(
  list: ListType[],
  childrenLabel?: string,
  childrenMap?: { [key: string]: any }
): any {
  return list.reduce((map, item: ListType) => {
    const children =
      childrenLabel && childrenMap ? { [childrenLabel]: childrenMap } : {}
    return {
      ...map,
      [item]: {
        show: true,
        ...children,
      },
    }
  }, {})
}

const enableData: IConfigurationEnableData = {
  showGame: false,
  visibility: generateMap(
    missions,
    'zones',
    generateMap(
      zones,
      'packs',
      generateMap(packs, 'exercises', generateMap(exercises))
    )
  ),
}

export { enableData }
