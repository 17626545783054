export interface IMouseClickElement {
  x: number
  y: number
  width: number
  height: number
  isLeftButton: boolean
  isRightButton: boolean
}

export enum Keys {
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
}
