import { languageCodes } from '../../../../core/@types/general'

export interface ILocaleProps {
  errorRenderHelper: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    errorRenderHelper: 'There is no helper available',
  },
  [languageCodes.ro_RO]: {
    errorRenderHelper: 'Acest ajutor nu este disponibil',
  },
}

export default localeData
