import React, { useContext } from 'react'
import { Accordion } from '../../../../components/atoms/accordion'
import { AdminButton } from '../../../../components/atoms/admin-button'
import { useFormContext } from 'react-hook-form'
import { Missions } from '../missions'
import { GeneralContext } from '../../../../core/context/generalContext'
import locales, { ILocaleProps } from './lib/locales'
import { updateConfiguration } from '../../../../core/api/graphql/mutations/configuration'
import { useMutation } from '@apollo/react-hooks'
import { EnableDropdown } from '../enableDropdown'
import styles from './configuration.module.scss'

interface ConfigurationProps {
  name: string
}

export const Configuration: React.FC<ConfigurationProps> = ({ name }) => {
  const { languageCode } = useContext(GeneralContext)
  const locale: ILocaleProps = locales[languageCode]
  const { watch } = useFormContext()
  const { title, name: configName, _id: id, enabled_data: data } = watch(name)
  const [handleUpdateConfiguration, { loading }] =
    useMutation(updateConfiguration)

  const handleSave = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    try {
      await handleUpdateConfiguration({
        variables: {
          id,
          data,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const renderHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div>
          <h3>{title}</h3>
          <p>{configName}</p>
        </div>
        <div>
          <AdminButton
            type="button"
            className={styles.saveButton}
            onClick={handleSave}
            isLoading={loading}
          >
            {locale.saveButton}
          </AdminButton>
        </div>
      </div>
    )
  }

  const renderBody = () => {
    return (
      <>
        <div className="d-flex align-items-center mb-3">
          <h4 style={{ marginRight: 16 }}>{locale.showGame}</h4>
          <EnableDropdown name={`${name}.enabled_data.showGame`} />
        </div>
        <Missions name={`${name}.enabled_data.visibility`} />
      </>
    )
  }

  return <Accordion header={renderHeader()} body={renderBody()} />
}
