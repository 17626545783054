import * as THREE from 'three'
import { IOptionPlanet } from '../../../../../core/@types/game'

export class PlanetMarker {
  options: IOptionPlanet | null
  mesh: THREE.Mesh | null
  scene: THREE.Scene | null
  isDisabled: boolean

  constructor(scene: THREE.Scene | null, options: IOptionPlanet | null) {
    this.options = options
    this.scene = scene
    this.isDisabled = true
    this.mesh = null
  }

  unload() {
    if (this.mesh && this.scene) {
      this.scene.remove(this.mesh)
      this.mesh.geometry.dispose()
      ;(this.mesh.material as THREE.Material).dispose()
      this.mesh = null
      this.scene = null
    }
  }

  load(): PlanetMarker {
    const sphereMaterial = new THREE.MeshLambertMaterial({
      color: 0xff8833,
    })
    const centerpiece = new THREE.TorusGeometry(40, 0.4, 2, Math.PI * 2)
    this.mesh = new THREE.Mesh(centerpiece, sphereMaterial)
    const scale = this.options?.scale || 1
    this.mesh.scale.set(scale, scale, scale)
    const x = this.options?.x || 0
    const y = this.options?.y || 0
    this.mesh.position.set(x, y, 0)

    if (this.scene && this.mesh) {
      this.scene.add(this.mesh)
    }
    return this
  }

  update() {
    if (this.mesh && this.options) {
      this.mesh.rotation.z -= this.options.speed * 2
      //this.mesh.rotation.y -= this.options.speed * 3;
    }
  }
}
