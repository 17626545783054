import { languageCodes } from '../../../../../../../core/@types/general'

export interface ILocaleProps {
  welcome: string
  underAttack: string
  navigate: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    welcome: 'Welcome to Matematrolii!',
    underAttack:
      'The world is under attack of Matematrolii specie. You are here to save and protect our galaxy from Matematroli. The only weapon we have against them is mathematics and we count on you with this.',
    navigate:
      'Navigate by selecting a Planet Mission and start your adventure.',
  },
  [languageCodes.ro_RO]: {
    welcome: 'Bine ai venit la Matematrolii!',
    underAttack:
      'Lumea este atacată de specia Matematrolii. Ești aici pentru a salva si proteja galaxia de Matematroli. Singura arma împotriva lor este matematica și ne bazăm pe tine.',
    navigate:
      'Navighează selectând o planetă Misiune și incepe aventura.',
  },
}

export default localeData
