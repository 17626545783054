import { languageCodes } from '../../../../core/@types/general'

export interface ILocaleProps {
  logoutButton: string
  userLabel: string
}

interface IProps {
  [key: string]: ILocaleProps
}

const localeData: IProps = {
  [languageCodes.en_US]: {
    logoutButton: 'Logout',
    userLabel: 'User Name',
  },
  [languageCodes.ro_RO]: {
    logoutButton: 'Deconectare',
    userLabel: 'Nume Utilizator',
  },
}

export default localeData
